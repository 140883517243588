import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import { Footer } from "../../pages/Footer";
import { useLocation } from "react-router-dom";
import useCookie, { deleteCookie } from "../../hooks/cookie";
import { getRequest } from "../../helpers";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/").slice(1);
  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();
  const [active, setActive] = useState("");

  const [profileDetails, setProfileDetails] = useState();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    {
      token &&
        getRequest({ url: "profile", token: authToken })
          .then((res) => {
            if (res.data.status) {
              setProfileDetails(res.data.data);
            }
          })
          .catch((AxiosError) => {
            console.log(AxiosError);
          });
    }
    setActive(path?.[1]); // SETTING ACTIVE TAB
  }, []);
  const sidemenu = [
    { label: "my profile", value: "my-profile" },
    { label: "my orders", value: "my-orders" },
    { label: "my address", value: "my-address" },
    { label: "change password", value: "change-password" },
  ];

  return (
    <>
      <Header />

      {/* breadcrums  */}
      <div className="text-sm breadcrumbs mx-5 mt-2 text-md md:mx-5 md:mt-0 md:text-xl  font-medium text-gray-700  capitalize">
        <ul>
          <li onClick={() => navigate("/")} role="button">
            <svg
              class="w-3 h-3 me-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
          </li>
          <svg
            class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <li className="hover:text-blue-600 md:ms-2 text-sm">Account</li>
          <li className="hover:text-blue-600 md:ms-2 text-sm">
            {path?.[1] === "my-profile"
              ? "My Profile"
              : path?.[1] === "my-orders"
              ? "My Orders"
              : path?.[1] === "change-password"
              ? "Change Password"
              : "My Address"}
          </li>
        </ul>
      </div>
      {/* grid  */}
      <div className="grid grid-cols-1 md:grid-cols-4">
        <div className=" w-full flex justify-start md:mb-5">
          {/* sidebar  */}
          <div className="hidden md:block md:max-w-[20vw] card shrink-0 ms-5 shadow-2xl ">
            <div className="bg-[#042100] rounded-sm font-bold text-white">
              <p className="py-4 px-8"> Hi, {profileDetails?.name} </p>
            </div>
            {sidemenu.map((res, index) => {
              return (
                <>
                  <Link to={res?.value}>
                    <button
                      className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 ${
                        active == res?.value
                          ? "bg-gray-200 border-gray-300"
                          : "bg-white"
                      }`}
                      onClick={() => setActive(res?.value)}
                    >
                      <p className="py-4 px-8 capitalize">{res?.label}</p>
                    </button>
                  </Link>
                </>
              );
            })}
            {/* <Link to="my-profile">
              <button
                className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 ${
                  active === path?.[1] ? "bg-gray-400" : ""
                }`}
                onClick={() => setActive("my-profile")}
              >
                <p className="py-4 px-8">My Profile</p>
              </button>
            </Link> */}
            {/* <Link to="my-orders">
              <button
                id="my-orders"
                className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 ${
                  active === path?.[1] ? "bg-gray-400" : ""
                }`}
                onClick={() => setActive("my-orders")}
              >
                <p className="py-4 px-8"> My Orders</p>
              </button>
            </Link>
            <Link to="my-address">
              <button
                id="my-address"
                className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 ${
                  active === path?.[1] ? "bg-gray-400" : ""
                }`}
                onClick={() => setActive("my-address")}
              >
                <p className="py-4 px-8"> My Address</p>
              </button>
            </Link>
            <Link to="change-password">
              <button
                id="change-password"
                className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 ${
                  active === path?.[1] ? "bg-gray-400" : ""
                }`}
                onClick={() => setActive("change-password")}
              >
                <p className="py-4 px-8"> Change Password </p>
              </button>
            </Link> */}
            <button
              onClick={() => {
                deleteCookie();
                navigate("/");
              }}
              className={`w-full text-start border border-gray cursor-pointer hover:bg-gray-100 `}
            >
              {/* {console.log(active, "kkk")} */}
              <p className="py-4 px-8"> Log Out </p>
            </button>
          </div>
        </div>
        {/* main section  */}
        <div className=" col-span-3 px-2 md:px-5 md:me-3 pb-5">
          <div className="w-full  mr-4 rounded-sm flex justify-evenly md:px-5">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
