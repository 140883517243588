import React from 'react'
import Header from '../../pages/Header'
import { Footer } from '../../pages/Footer'

const FAQ = () => {
  return (
    <>
      <Header />
      <div className='bg-gray-100 '>
        <p className='text-center text-4xl pt-32 pb-8 font-bold underline underline-offset-8 decoration-lime-500'>Frequently Asked Questions</p>
        <div className='flex items-center justify-center'>
          <div className='w-9/12 my-10'>
            <div class="grid gap-6 lg:grid-cols-3">
              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">Why do I see different prices for the same product?</p>
                <p class="text-neutral-500 dark:text-neutral-300">
                  You could see different prices for the same product, as it could be listed by many Sellers.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">Is it necessary to have an account to shop on Gamlewala?</p>
                <p class="text-neutral-500 dark:text-neutral-300">
                  Yes, it's necessary to log into your Gamlewala account to shop. Shopping as a logged-in user is fast & convenient and also provides extra security.You'll have access to a personalised shopping experience including recommendations and quicker check-out.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                  Can I use an item that has been given to me as a gift from a state sponsored or an NGO-funded freebie distribution programme to get discounts through exchange offers?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                  No, as per company policy, such items are not eligible for discounts under exchange offers.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                  Can I order a product that is 'Out of Stock' or 'Temporarily Unavailable'?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                  The products listed as 'Out of Stock' or 'Temporarily Unavailable' are not available for sale.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">What does the badge Gamlewala Assured mean?</p>
                <p class="text-neutral-500 dark:text-neutral-300">
                  The Gamlewala Assured is a seal of quality and speed. Products with this seal meet our extensive quality & packaging guidelines along with having faster delivery timelines.
                  With stringent seller & product selection combined with fast & free delivery, 'Gamlewala Assured' guarantees you a superior and hassle-free shopping experience every time. Free shipping for orders above ₹500.

                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Do I have to pay extra for items with the Gamlewala Assured ?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                No, you can shop for items with the Gamlewala Assured badge at no extra charge.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Why do I see a shipping charge for an item with the Gamlewala Assured ?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Sellers may charge a nominal fee for shipping, even for products with the Gamlewala Assured seal, if the order is less than ₹ 500. Shipping is free for items with the Gamlewala Assured seal if the order value is more than ₹ 500.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Is there a filter available through which I can only see items with a Gamlewala Assured seal?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Yes, there is a filter available soon through which you can shop only for items with the Gamlewala Assured seal.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                How are items packaged?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Products sold by Sellers with Gamlewala Assured seal are shipped in packages with waterproof plastic wrap. Fragile items like electronics are safely secured with bubble wrap. Other Sellers also follow standard packing procedure. Sellers are rated on packaging quality and affects overall seller rating.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Are there any hidden charges (Octroi or Sales Tax) when I shop on Gamlewala?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                There are NO hidden charges when you shop on Gamlewala. The price you see on the product page is final and it's exactly what you pay. There can be additional delivery charges based on the seller's policy.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Can I club my orders from different sellers to be delivered together?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Currently, there is no option to club orders from different sellers to be delivered together as sellers could be located in different locations and the delivery timelines would vary based on their partnered courier service providers. To ensure your items reach you at the earliest, each seller ships their products as per their individual timelines.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Why do I see a 'delivery charge'?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                It usually costs sellers more to ship some items. So, sometimes they choose to add a delivery charge. The delivery charge is waived off by some sellers if you shop with them for a certain amount. 
                </p>
              </div>
              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                I missed the delivery of my order today. What should I do?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                The courier service delivering your order usually tries to deliver on the next business day in case you miss a delivery. You can check your SMS for more details on when the courier service will try to deliver again.
                </p>
              </div>
              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Will the delivery be tried again if I'm not able to collect my order the first time?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Couriers make sure that the delivery is re-attempted the next working day if you can't collect your order the first time.
                </p>
              </div>
              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                The delivery of my order is delayed. What should I do?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                On the rare occasion that your order is delayed, please check your email & messages for updates. A new delivery timeframe will be shared with you and you can also track its status by visiting My Orders.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                What should I do if my order is approved but hasn't been shipped yet?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Sellers usually ship orders 2-3 business days before the delivery date so that they reach you on time. In case your order hasn't been shipped within this time please contact their Customer Support so that they can look into it.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                Can I take the shipment after opening and checking the contents inside?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                As per company policy, a shipment can't be opened before delivery, but you can accept the shipment and get in touch with Gamlewala later in case you have any concerns.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                How do I know my order has been confirmed?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                An e-mail & SMS will be sent once you've successfully placed your order. You will also be informed as soon as the seller ships the item(s) to you along with the tracking number(s) for your shipment(s). You can track your orders from the 'My Orders' section on your Gamlewala account.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                How quickly can I get my order delivered?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                Orders will be delivered by the date you see on the product page for your location.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                My order has reached the nearest delivery hub, but why isn't it out for delivery yet?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                The courier service delivering your order are responsible for making sure that your order reaches you within the delivery date. Rest assured, you'll get an SMS once your shipment is out for delivery.
                </p>
              </div>

              <div class="mb-6 md:mb-8 lg:mb-12">
                <p class="mb-4 font-bold">
                My order status shows 'Out for Delivery', but I haven't got it yet. When will I get it?
                </p>
                <p class="text-neutral-500 dark:text-neutral-300">
                The courier service will contact you for delivery of your order. Please check your SMS for more details.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default FAQ
