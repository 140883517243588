import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import fullLogo from "../assets/images/full-img.png";
import Google from "../assets/images/socials/google.png";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { noTokenPostRequest, postRequest } from "../helpers";
import useCookie from "../hooks/cookie";
import { Input } from "antd";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

const Signin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [setCookie, getCookie] = useCookie();

  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const login = useGoogleLogin({
    onSuccess: setUser,
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    const userSignUp = async (accessToken) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        );
        const token = res?.data?.data?.token;
        console.log(accessToken);
        const signupResponse = await postRequest({
          url: "auth/google-sign-up",
          cred: { token: accessToken },
        });
        if (signupResponse.data.status) {
          // console.log(signupResponse.data, "signup res");
          setCookie("gamlawala-auth", signupResponse?.data?.data?.token, 1);
          postRequest({
            url: "wishlist/add-multiple",
            cred: {
              product: JSON.parse(localStorage.getItem("wishlist")) || [],
            },
            token: signupResponse?.data?.data?.token,
          })
            .then((res) => {
              // Handle the response as needed
              console.log(res.data);
              // Clear the localStorage wishlist after a successful request
              localStorage.removeItem("wishlist");
            })
            .catch((err) => {
              // Handle the error as needed
              console.error(err);
            });

          navigate("/");
        } else {
          console.log("else block", signupResponse.data.msg);
        }
      } catch (err) {
        if (err?.response?.data?.msg === "Invalid login!") {
          userSignUp(user);
        } else {
          handleError(err);
        }
      }
    };
    const userSignIn = async (accessToken) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        );
        const token = res?.data?.data?.token;
        const signupResponse = await postRequest({
          url: "auth/google-sign-in",
          cred: { token: accessToken },
        });
        if (signupResponse.data.status) {
          setCookie("gamlawala-auth", signupResponse?.data?.data?.token, 1);
          postRequest({
            url: "wishlist/add-multiple",
            cred: {
              product: JSON.parse(localStorage.getItem("wishlist")) || [],
            },
            token: signupResponse?.data?.data?.token,
          });
          navigate("/");
        } else {
          console.log("else block", signupResponse.data.msg);
        }
      } catch (err) {
        if (err?.response?.data?.msg === "Invalid login!") {
          userSignUp(user.access_token);
        }
        handleError(err);
      }
    };
    if (user) {
      userSignIn(user.access_token);
    }
  }, [user, navigate]);

  const handleError = (err) => {
    const errorMessage = err?.response?.data?.msg || "An error occurred";
    // if (errorMessage === "Invalid login!") {
    //   navigate("/signup");
    //   setTimeout(() => {
    //     toast.error(errorMessage);
    //   }, 1000);
    // } else {
    // }
    console.log(err);
  };

  const handleLogin = (e) => {
    const { name, value } = e.target;
    setLoginCredentials({
      ...loginCredentials,
      [name]: value,
    });
  };

  const signin = (e) => {
    e.preventDefault();
    let credentials = { ...loginCredentials };
    noTokenPostRequest({ url: "auth/sign-in", cred: credentials })
      .then((res) => {
        if (res?.data?.status) {
          setCookie("gamlawala-auth", res?.data?.data?.token, 1);
          postRequest({
            url: "wishlist/add-multiple",
            cred: {
              product: JSON.parse(localStorage.getItem("wishlist")) || [],
            },
            token: res?.data?.data?.token,
          })
            .then((res) => {
              // Handle the response as needed
              console.log(res.data);

              // Clear the localStorage wishlist after a successful request
              localStorage.removeItem("wishlist");
            })
            .catch((err) => {
              // Handle the error as needed
              console.error(err);
            });
          toast.success(res.data.msg);
          setLoginCredentials({
            email: "",
            password: "",
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(res?.data?.data?.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div
        style={{
          backgroundImage: `url('./assets/pots--bg.jpg')`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="bg-white p-2 flex items-center justify-center w-full fixed z-10">
          <div className="flex cursor-pointer" onClick={() => navigate("/")}>
            {/* <img className="h-10 mx-2" src={Logo} alt="Logo" /> */}
            <img className="h-12" src={fullLogo} alt="Logo" />
          </div>
        </div>
        <div className="h-screen flex items-center justify-center">
          <div className=" bg-white w-11/12 sm:w-6/12 lg:w-5/12 xl:w-4/12 mt-12 flex items-center justify-center rounded-md place-self-center">
            <div className="w-4/5">
              <div className="my-5">
                <p className="text-2xl">Sign in</p>
                <p className="text-md">
                  Don't have an account?{" "}
                  <button
                    onClick={() => navigate("/signup")}
                    className="text-lime-400 font-medium"
                  >
                    Sign Up
                  </button>
                </p>
              </div>
              <form onSubmit={signin}>
                <label htmlFor="email" className="mt-2">
                  Email id
                </label>
                <input
                  type="email"
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2 bg-white rounded-md"
                  name="email"
                  placeholder="Email"
                  value={loginCredentials.email}
                  onChange={handleLogin}
                  required
                />
                <label htmlFor="email" className="mt-2">
                  Password
                </label>
                <Input.Password
                  placeholder="Password"
                  value={loginCredentials.password}
                  onChange={handleLogin}
                  name="password"
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2"
                  required
                />
                {/* <input
                                    id="password-field"
                                    className='w-full h-10 my-2 border border-inherit focus:outline-none p-2'
                                    type='password'
                                    name='password'
                                    placeholder='Password'
                                    value={loginCredentials.password}
                                    onChange={handleLogin}
                                    required
                                >
                                </input> */}
                <button className="h-10 my-2 w-full text-center bg-lime-200">
                  <span type="submit" className="font-medium">
                    SIGN IN
                  </span>
                </button>
              </form>
              <p
                className="my-1 text-sm cursor-pointer"
                onClick={() => navigate("/forgetpassword")}
              >
                Forgot password?
              </p>
              <div className="inline-flex items-center justify-center w-full">
                <hr className="w-full h-px my-6 bg-gray-200 border-0 dark:bg-gray-500" />
                <span className="absolute px-3 font-medium text-gray-500 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900 py-1 rounded">
                  or sign in
                </span>
              </div>
              <div
                className="flex items-center justify-center mb-4 cursor-pointer"
                role="button"
                onClick={() => login()}
              >
                <div className="border border-gray-500 rounded">
                  <div className="flex my-2 justify-center mx-4">
                    <img src={Google} alt="G" className="h-5 mr-2 mt-1" />
                    <p className="text-lg font-medium">Google</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
