import React, { useEffect, useState } from "react";
import giftBox from "../assets/images/giftBox.png";
import { getRequest, postRequest } from "../helpers";
import Cookies from "js-cookie";
import useCookie from "../hooks/cookie";

const CouponBannerComp = () => {
  const [couponData, setCouponeData] = useState("");
  const [setCookie, getCookie] = useCookie();

  const token = getCookie("gamlewala-auth");

  useEffect(() => {
    getRequest({ url: `coupon/lists/`, token: token })
      .then((res) => {
        if (res?.data?.status) {
          setCouponeData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="hidden h-40 my-2 mx-[12px] min-[1440px]:mx-0 min-[750px]:grid grid-cols-11 overflow-hidden relative">
      <div className="col-span-6 bg-[#FFF9F6]">
        <div className=" flex items-center gap-4">
          <img
            src={giftBox}
            alt="img"
            className="absolute w-56 min-[900px]:w-64 top-[-16%] right-[31%] min-[999px]:right-[32%] min-[1220px]:right-[36%] min-[1899px]:right-[38%] min-[2400px]:right-[39%]"
          />
          <div className=" pt-[1rem] ms-[1rem] min-[800px]:ms-[3rem] min-[900px]:ms-[6rem]">
            <h2 className="text-[2.2rem] min-[1220px]:text-[3.2rem] font-bold text-center ">
              Get Flat 15% Off
            </h2>
            <h2 className="text-lg min-[1220px]:text-2xl font-semibold text-center">
              on orders worth ₹1500 & above.
            </h2>
          </div>
        </div>
      </div>
      <div className="col-span-5 bg-[#DC3544]">
        <div className=" flex items-center text-white">
          <div className=" pt-[1.5rem] ms-[7.7rem] min-[900px]:ms-[10.7rem]  min-[1100px]:ms-[13.7rem] min-[1999px]:ms-[17.7rem] min-[2350px]:ms-[20.7rem]">
            <h2 className="text-2xl min-[1220px]:text-4xl text-center ">
              Use Code:
            </h2>
            <p className="text-[2.5rem] min-[1220px]:text-[3.5rem] font-bold text-center tracking-wide uppercase">
              {"gamla15"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponBannerComp;
