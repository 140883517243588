import React, { useContext, useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import moment from "moment";
import useCookie from "../../hooks/cookie";
import Header from "../../components/header";
import { Footer } from "../../pages/Footer";
import rose from "../../assets/images/socials/test-img.webp";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postRequest, getRequest } from "../../helpers";
import Marquee from "react-fast-marquee";
import toast, { Toaster } from "react-hot-toast";
import { CartContext } from "../../contexts/CartContext";
import { WishlistContext } from "../../contexts/WishListContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { IoMdHeart, IoMdHeartEmpty, IoMdStar } from "react-icons/io";
import {
  DatePicker,
  Select,
  TimePicker,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ReactImageMagnify from "react-image-magnify";
import { MdOutlineStar } from "react-icons/md";

export const ProductDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [showText, setShowText] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [setCookie, getCookie] = useCookie();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState([]);
  const [cakeInput, setCakeInput] = useState({
    nameOnCake: "",
    msgForCeleb: "",
  });
  const [localWishlist, setLocalWishList] = useState([]);
  const [cakeImage, setCakeImage] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState("Office");
  const format = "HH:mm";
  const [activeImage, setActiveImage] = useState("");
  const [hasVariant, setHasVariant] = useState(false); // State for checking if variant exists
  const [variantData, setVariantData] = useState(null); // State for storing variant data

  dayjs.extend(customParseFormat);
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const handleChange = (value) => {
    setDeliveryOption(value);
  };
  const handleCakeSize = (value) => {
    console.log(`selected ${value}`);
  };

  // Disable all previous dates and weekends (Saturday and Sunday)
  const disabledSatSun = (current) => {
    return (
      current &&
      (current < moment().startOf("day") ||
        current.day() === 0 ||
        current.day() === 6)
    );
  };
  const handleCakeInput = (e) => {
    const { name, value } = e.target;
    setCakeInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };

  useEffect(() => {
    let wishlist = localStorage.getItem("wishlist");
    wishlist = wishlist ? JSON.parse(wishlist) : [];
    setLocalWishList(wishlist);
  }, [updateStatus]);

  // ACCESSING CONTEXT API FOR CART
  const [wishListData, setWishListData] = useContext(WishlistContext);

  const [cartData, setCartData] = useContext(CartContext);

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getRequest({ url: `products/${params.id}`, token: authToken })
      .then((res) => {
        if (res.data.status) {
          const response = res.data.data;
          setData(response);
          setIsLoading(false);
          postRequest({
            url: `products/related`,
            cred: { id: response?._id },
            token: authToken,
          })
            .then((resp) => {
              if (resp.data.status) {
                setRelatedProducts(resp.data.data);
              }
            })
            .catch((AxiosError) => {
              console.log(AxiosError);
            });
          setActiveImage(response?.image?.[0]);
          if (response?.isVariant) {
            setVariantData(response?.cakeVariant?.[0]);
            setHasVariant(true);
            setActiveImage(response?.cakeVariant?.[0]?.image?.[0]);
          }
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [wishListData, updateStatus]);

  useEffect(() => {
    if (token) {
      getRequest({ url: "cart", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setCartData(res?.data?.count);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [updateStatus, wishListData]);

  const addToCart = async (id) => {
    if (!token) {
      navigate("/signin");
      return;
    }

    const credentials = {
      product: id.toString(),
      variantId: data?.isVariant ? variantData?._id : "",
      quantity: 1,
      deliveryDate,
      deliveryTime,
    };

    try {
      const response = await postRequest({
        url: "cart/add",
        cred: credentials,
        token: authToken,
      });

      if (response.data.status) {
        toast.success(response.data.msg, {
          duration: 2000,
        });
        setUpdateStatus((prevStatus) => !prevStatus);
      } else {
        toast.error("Failed to add item to cart", {
          duration: 2000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg || "An error occurred", {
        duration: 2000,
      });
      console.error("Add to cart error:", error);
    }
  };

  const buyNow = (variantData, slug) => {
    const variantId = data?.isVariant ? `&variantId=${variantData?._id}` : "";
    const productSlug = data?.isVariant ? slug : data?.slug;

    if (token) {
      navigate(
        `/checkout?slug=${productSlug}&type=product&date=${deliveryDate}&delivery=${deliveryOption}${variantId}`
      );
    } else {
      navigate("/signin");
    }
  };

  // -------------------------modal functions------------------------

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (values) => {
    setIsModalOpen(false);
    {
      postRequest({
        url: "/request-callback",
        cred: {
          name: values.name,
          mobile: values.mobile,
          queries: values.queries,
        },
      })
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.msg, {
              duration: 2000,
            });
          } else {
            toast.error(res.data.msg, {
              duration: 2000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="bg-gray-50 font-[roboto]">
        <Header />

        {/* breadcrums  */}
        <div className="text-sm breadcrumbs mx-5 mt-2 font-[500] font-[roboto] md:mx-5 md:mt-0  text-gray-700  capitalize ">
          <ul>
            <li onClick={() => navigate("/")} role="button">
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
            </li>
            <svg
              class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>

            <li
              className="hover:text-blue-600 md:ms-2 capitalize"
              role="button"
              onClick={() => {
                navigate(`/product/${data?.segment?.slug}`);
              }}
            >
              {data?.segment?.name?.toLowerCase()}
            </li>
            <li className="hover:text-blue-600 md:ms-2 capitalize">
              {data?.name?.toLowerCase()}
            </li>
          </ul>
        </div>
        {!isLoading ? (
          <div className="bg-white h-auto mb-3">
            <div className=" m-auto overflow-y-scroll scrollbar">
              <div className="grid grid-cols-1 m-auto md:grid-cols-12 xl:grid-cols-12  md:w-4/5 md:mb-10 lg:max-h-[71vh] mb-3">
                {/* carousel */}
                <div
                  className={`pb-5 md:w-[91%] 2xl:w-full lg:px-0  overflow-hidden md:float-end   ${
                    data?.image?.length > 1 ? "col-span-6" : "col-span-6"
                  }`}
                >
                  <div className="w-full md:mx-5 gap-4 ">
                    <div
                      className={` ${
                        data?.image?.length > 1 ? "flex" : ""
                      } items-start  md:float-end`}
                    >
                      {hasVariant
                        ? variantData?.image?.length > 1 && (
                            <div className="hidden md:block my-2 mx-5 justify-between me-4">
                              {variantData?.image.slice(0, 5)?.map((res) => {
                                return (
                                  <>
                                    <img
                                      src={res}
                                      className={`object-cover h-4 w-6 sm:h-12 sm:w-16  my-2 ${
                                        activeImage === res ? "border-2" : ""
                                      } border-black`}
                                      role="button"
                                      alt={data?.name}
                                      onClick={() => setActiveImage(res)}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          )
                        : data?.image?.length > 1 && (
                            <div className="hidden md:block my-2 mx-5 justify-between me-4">
                              {data?.image.slice(0, 5)?.map((res) => {
                                return (
                                  <>
                                    <img
                                      src={res}
                                      className={`object-cover h-4 w-6 sm:h-12 sm:w-16  my-2 ${
                                        activeImage === res ? "border-2" : ""
                                      } border-black`}
                                      role="button"
                                      alt={data?.name}
                                      onClick={() => setActiveImage(res)}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          )}
                      <div
                        className={`p-5 md:p-2 object-cover h-auto w-auto md:h-[428px] md:w-[428px] float-end `}
                      >
                        <img
                          src={
                            activeImage ||
                            "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                          }
                          alt={data?.name}
                          className={` w-full  ${
                            data?.image?.length > 1
                              ? "object-cover w-[40rem] h-[20rem]"
                              : "object-cover h-[428px] w-[428px] float-end"
                          } `}
                        />
                      </div>
                    </div>
                    {/* --------------------------------------------------swiper------------------------------ */}
                  </div>
                </div>
                {/* section */}
                <div
                  className={`w-auto md:mb-7 md:float-start  h-auto 2xl:px-[3rem] ${
                    data?.image?.length > 1 ? "col-span-6" : "col-span-6"
                  } `}
                >
                  {/* heading  */}
                  <h2 className=" ms-4 font-[roboto] flex items-center capitalize font-[400]">
                    {data?.name?.toLowerCase()}{" "}
                  </h2>
                  {/* rating & review  */}
                  <div className="mb-[5px] flex justify-start items-center">
                    <div className="badge bg-[#008539] text-white rounded border-0 font-semibold ms-4">
                      {data?.rating}&nbsp;
                      <IoMdStar className="text-white font-semibold my-1" />
                    </div>
                    <div className="m-1 ms-5 text-blue-400 text-xs">
                      {data?.isVariant
                        ? variantData?.reviews?.length
                        : data?.reviews?.length}{" "}
                      Reviews
                    </div>
                  </div>
                  {/* price & discount  */}
                  <div className=" mx-5">
                    <div className="flex">
                      {" "}
                      <div className="font-[300] text-xl me-1">₹</div>
                      <div className="text-3xl sm:text-4xl md:text-[48px] font-[300]">
                        {hasVariant ? variantData?.price : data?.price}
                        {/* {data?.price} */}
                      </div>
                      <div
                        className="tooltip"
                        data-tip={`maximum retail price ₹${
                          hasVariant ? variantData?.mrp : data?.mrp
                        }`}
                      >
                        <button className="mx-1 mt-1">
                          <AiOutlineInfoCircle />
                        </button>
                      </div>
                    </div>

                    <span className="ms-5 text-xs font-semibold">
                      Inclusive of all taxes
                    </span>
                    <span
                      className={`ms-5 text-xs font-semibold ${
                        hasVariant
                          ? variantData?.stock
                          : data?.stock < 1
                          ? "text-red-500"
                          : " "
                      }`}
                    >
                      {hasVariant
                        ? variantData?.stock
                        : data?.stock > 0
                        ? `Stock ${
                            hasVariant ? variantData?.stock : data?.stock
                          } `
                        : "Out of Stock"}
                    </span>
                  </div>
                  {/* add to cart location Date and buy button   */}

                  <div className="m-3">
                    <div className="grid grid-cols-1 sm:grid-cols-4  gap-2">
                      {data?.segment?.slug === "garden_galleria" && (
                        <div className=" col-span-2">
                          <div className="label py-1">
                            {data?.segment?.slug === ""}
                            <span className="label-text truncate">
                              Select Delivery Option
                            </span>
                          </div>

                          <Select
                            defaultValue="Select Option"
                            size="large"
                            style={{
                              height: 47,
                            }}
                            className="w-full"
                            onChange={handleChange}
                            options={[
                              {
                                value: "Home",
                                label: "Home",
                              },
                              {
                                value: "Office",
                                label: "Office",
                              },
                              {
                                value: "Occasion",
                                label: "Occasion",
                              },
                            ]}
                          />
                          {address.length > 0 && (
                            <ul className="h-60 divide-y ps-2 divide-x">
                              {address.map((res, index) => (
                                <li
                                  key={index}
                                  className="p-2  hover:text-white hover:bg-blue-400"
                                  role="button"
                                  onClick={() => {
                                    setDeliveryAddress(res?.office); // Set the entire address object as the delivery address
                                    setAddress([]); // Clear the address list
                                  }}
                                >
                                  <span>{res?.pincode},</span>
                                  <span>{res?.office},</span>
                                  <span>{res?.district}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                      {data?.segment?.slug === "cakes" && (
                        <>
                          <div className="col-span-2">
                            <div className="label py-1">
                              <span className="label-text truncate">
                                Name On Cake
                              </span>
                            </div>
                            <input
                              type="text"
                              placeholder="Type here"
                              value={cakeInput.nameOnCake}
                              onChange={handleCakeInput}
                              name="nameOnCake"
                              className="input input-bordered w-full  bg-white"
                            />
                          </div>
                          <div className="col-span-2">
                            <div className="label py-1">
                              <span className="label-text truncate">
                                Message for Celebration
                              </span>
                            </div>
                            <textarea
                              className="textarea textarea-bordered h-12 w-full  bg-white resize-none"
                              value={cakeInput.msgForCeleb}
                              name="msgForCeleb"
                              onChange={handleCakeInput}
                              placeholder="Message"
                            ></textarea>
                          </div>
                          {/* -------------------------------------- */}
                          <div className="col-span-4">
                            <div className="flex justify-start items-baseline gap-4">
                              {data?.cakeVariant?.map((variant) => {
                                return (
                                  <>
                                    <div className="">
                                      <div
                                        className="border-2 border-[#042100] w-20 h-14 object-cover overflow-hidden mb-1"
                                        role="button"
                                        // onClick={}
                                      >
                                        <img
                                          src={variant?.image?.[0]}
                                          alt=""
                                          className=""
                                          onClick={() => {
                                            setVariantData(variant);
                                            setActiveImage(variant?.image?.[0]);
                                          }}
                                        />
                                      </div>
                                      <p className="text-xs">
                                        ₹{variant?.price}
                                      </p>
                                      <p className="text-xs">
                                        {variant?.weight} gm
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          {/* <div className="col-span-2">
                            <div className="label py-1">
                              <span className="label-text truncate">
                                Select Weight for the Cake
                              </span>
                            </div>
                            <Select
                              defaultValue="0.5 Kg"
                              className="w-full h-12 "
                              onChange={handleCakeSize}
                              options={[
                                {
                                  value: "0.5",
                                  label: "0.5 Kg",
                                },
                                {
                                  value: "1",
                                  label: "1 Kg",
                                },
                                {
                                  value: "1.5",
                                  label: "1.5 Kg",
                                },
                                {
                                  value: "2",
                                  label: "2 Kg",
                                },
                              ]}
                            />
                          </div>{" "} */}
                          <div className="col-span-4">
                            <div className="label py-1">
                              <span className="label-text truncate">
                                Custom Cake Request
                              </span>
                            </div>
                            <Button
                              type="default"
                              size="large"
                              onClick={showModal}
                              className="h-12 w-full"
                            >
                              Request A Call
                            </Button>
                          </div>
                        </>
                      )}
                      <div
                        className={`${
                          data?.segment?.slug === "garden_galleria" ||
                          data?.segment?.slug === "cakes"
                            ? "col-span-2"
                            : "col-span-4"
                        } `}
                      >
                        <div className="label py-1">
                          <span className="label-text truncate">
                            Select Delivery Date
                          </span>
                        </div>
                        <DatePicker
                          format="YYYY-MM-DD"
                          disabledDate={
                            deliveryOption === "Home"
                              ? disabledSatSun
                              : disabledDate
                          }
                          className="input input-bordered w-full focus:outline-none bg-white"
                          onChange={(date, dateString) => {
                            setDeliveryDate(dateString);
                          }}
                        />
                        {data?.segment?.slug === "garden_galleria" && (
                          <div className="text-xs font-bold">
                            NOTE: If you choose Sunday for delivery, it will be
                            free
                          </div>
                        )}
                      </div>
                      <div
                        className={`${
                          data?.segment?.slug === "cakes"
                            ? "col-span-2"
                            : "hidden"
                        } `}
                      >
                        <div className="label py-1">
                          <span className="label-text truncate">
                            Select Time
                          </span>
                        </div>
                        <TimePicker
                          defaultValue={dayjs("12:08 pm", format)}
                          format={format}
                          className="input input-bordered w-full focus:outline-none bg-white"
                          onChange={(time, timeString) => {
                            setDeliveryTime(timeString);
                          }}
                          use12Hours
                        />
                      </div>
                      {data?.availableToCart && (
                        <div
                          className="col-span-2"
                          onClick={() => {
                            if (deliveryDate) {
                              if (
                                (data?.isVariant
                                  ? variantData?.stock
                                  : data?.stock) > 0
                              ) {
                                addToCart(data?._id);
                              } else {
                                toast.error("Out of Stock", {
                                  duration: 2000,
                                });
                              }
                            } else {
                              toast.error("please Select Delivery Date First", {
                                duration: 2000,
                              });
                            }
                          }}
                        >
                          <button
                            className={`w-full btn bg-[#042100] text-white hover:bg-[#040D00] border-0 ${
                              (data?.isVariant
                                ? variantData?.stock
                                : data?.stock) < 1
                                ? "btn-disabled"
                                : "bg-[#042100] text-white hover:bg-[#040D00]"
                            }`}
                          >
                            Add To Cart
                          </button>
                        </div>
                      )}
                      <div
                        className={` ${
                          data?.availableToCart ? "col-span-2" : "col-span-4"
                        }`}
                      >
                        <button
                          className={`w-full btn bg-[#d8e7cc] hover:bg-[#BCCBB1] border-0 text-[#042100] ${
                            (data?.isVariant
                              ? variantData?.stock
                              : data?.stock) < 1
                              ? "btn-disabled"
                              : ""
                          }`}
                          onClick={async () => {
                            if (!deliveryDate) {
                              toast("Please select Delivery Date First");
                              return;
                            }
                            buyNow(
                              data?.isVariant ? variantData : data,
                              data?.slug
                            );
                          }}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* --------------------------------------Modal--------------------------------------------------- */}
                  <Modal
                    title="Call Back Request Form"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[]}
                  >
                    <Form
                      form={form}
                      name="basic"
                      labelCol={{
                        span: 4,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      style={{
                        maxWidth: 600,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Mobile No.!",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Query"
                        name="queries"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          ghost
                          className="mx-3"
                        >
                          Submit
                        </Button>
                        <Button
                          key="back"
                          onClick={() => {
                            handleCancel();
                          }}
                        >
                          {" "}
                          Cancel
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                  {/* --------------------------------------Modal--------------------------------------------------- */}

                  {/* description  */}
                  {data?.description && (
                    <div className="px-5">
                      <div className="text-xl pt-3 font-[500] font-[roboto]">
                        Description
                      </div>
                      <hr className="my-4" />
                      <div
                        className={`text-md pe-3 font-[300] ${
                          !showText ? "line-clamp-3" : ""
                        }`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.description,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* reviews  */}
              {data?.reviews?.length > 0 && (
                <div className="my-5 bg-[#F9FAFB]">
                  <div className="text-xl px-5 py-3 font-[500] font-[roboto] text-center">
                    Reviews
                  </div>
                  {/* <Marquee autoFill pauseOnHover> */}

                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop={true}
                    // navigation={true}
                    pagination={true}
                    modules={[Navigation, Pagination]}
                    breakpoints={{
                      1380: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                      1080: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      520: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                    }}
                    className="mySwiper"
                  >
                    {data?.reviews?.map((res, index) => {
                      return (
                        <>
                          <SwiperSlide key={index} className="p-2">
                            <div
                              className="card w-60 md:w-80 bg-white shadow-lg m-5 min-h-80"
                              key={index}
                            >
                              <div className="card-body mx-8">
                                <div className="m-auto">
                                  <div className="flex justify-center items-center">
                                    {[...Array(res?.rate)].map((_, index) => (
                                      <div
                                        key={index}
                                        className="flex justify-center items-center"
                                      >
                                        <MdOutlineStar
                                          size={25}
                                          className="text-[#FFAB1A]"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <h2 className="text-center text-2xl font-bold">
                                  {res?.title || res?.comment.slice(0, 11)}
                                </h2>
                                <p className="text-gray-600 text-center line-clamp-3 my-3 font-[400]">
                                  {res?.comment}
                                </p>
                                <div className="text-gray-600 text-center font-[400]">
                                  -{res?.userId?.name}
                                </div>
                                {/* <div className="card-actions justify-end">
                              <div className="p-2 bg-lime-500 rounded-full text-white">
                                {res?.rate}
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                  {/* </Marquee> */}
                </div>
              )}
              {/* Recent/related/suggested products  */}
            </div>
            {/* ------------------------------------------------related products-------------------------------- */}
            <div className=" py-3 bg-[#F9FAFB]">
              <div className="text-xl px-5  font-[500] font-[roboto] ">
                Related Products
              </div>
              <div className="flex py-5 px-5 overflow-x-scroll gap-5 scrollbar">
                {relatedProducts?.map((res, index) => {
                  return (
                    <>
                      <a href={`/products/product/${res.slug}`}>
                        <div
                          className="card bg-white shadow-xl rounded-sm w-48"
                          key={index + 1}
                        >
                          <figure className="">
                            <img
                              src={
                                (res?.isVariant
                                  ? res?.cakeVariant?.[0]?.image?.[0]
                                  : res?.image?.[0]) ||
                                `https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png`
                              }
                              alt={res?.name}
                              className="h-40 w-48 object-cover zoom-effect"
                              role="button"
                            />
                          </figure>

                          <div className="card-body px-5 py-3 text-start">
                            <h2 className="card-title text-xs font-[500] font-[roboto] truncate capitalize">
                              {res?.name.toLowerCase()}
                            </h2>
                            <button className="btn bg-[#D8E7CC] hover:bg-[#BCCBB1] border-0 text-[#042100]">
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </a>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div class=" max-w-5xl w-full mx-auto p-5">
            <div class="animate-pulse flex space-x-4">
              <div class="rounded-sm bg-slate-200 h-80 w-80"></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-2 w-32 bg-slate-200 rounded mt-5"></div>
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4 w-32">
                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div>
                  <div class="h-2 bg-slate-200 rounded w-32"></div>
                </div>
                <div class="grid grid-cols-2 gap-4 mt-[7rem] max-w-[30vw]">
                  <div class="h-2 bg-slate-200 rounded me-[5rem]"></div>
                  <div class="h-2 bg-slate-200 rounded me-[5rem]"></div>
                  <div class="h-14 bg-slate-200 rounded-lg"></div>
                  <div class="h-14 bg-slate-200 rounded-lg"></div>
                  <div class="h-14 bg-slate-200 rounded-lg"></div>
                  <div class="h-14 bg-slate-200 rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className={`m-5 max-w-[30%]`}>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Wristwatch by Ted Baker London",
                isFluidWidth: true,
                src: data?.image?.url?.[0],
                height: "120",
                width: "120",
              },
              largeImage: {
                src: data?.image?.url?.[0],
                width: 1200,
                height: 1800,
              },
            }}
          />
        </div> */}

        <Footer />
      </div>
    </>
  );
};
