import { useEffect, useRef } from "react";
import {
  noTokenGetRequest,
  postRequest,
  postRequestWithHeaders,
} from "../../helpers";
import useCookie from "../../hooks/cookie";
import { useNavigate, useHistory } from "react-router-dom";
// const reL = window.location.reload();

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({ orderId, currency, amount, setUpdateStatus }) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  let rzp1;
  const navigate = useNavigate();

  navigate({
    pathname: window.location.pathname,
    search: "",
  });

  const [setCookie, getCookie] = useCookie();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // All information is loaded in options which we will discuss later.
    rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;

      console.log(response, "failed response");
      console.log(paymentId.current, "failed response2");

      postRequestWithHeaders({
        url: "payment/verify",
        cred: {
          order_id: response?.error?.metadata?.order_id,
          payment_id: response?.error?.metadata?.payment_id,
        },
        token: authToken,
        signature: "transaction_failed",
      })
        .then((res) => {
          // console.log(res, "loaction");
          if (!res?.status) {
            // console.log(res, "loaction + Res", res?.data?.status);
            window.location.reload();
            rzp1.close();
            // navigate();
          }
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            window.location.reload();
            rzp1.close();
          }, 3000);
        });
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  const handlePayment = async (status, orderDetails = {}) => {};

  const options = {
    amount,
    currency,
    name: "GamleWala ",

    order_id: orderId,

    confirm_close: true,
    handler: (response) => {
      console.log("succeeded", response);
      rzp1.close();
      navigate("/account/my-orders");
      postRequestWithHeaders({
        url: "payment/verify",
        cred: {
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
        },
        token: authToken,
        signature: response?.razorpay_signature,
      })
        .then((res) => {
          // console.log(res, "loaction");
          if (res?.status) {
            // console.log(res, "loaction + Res", res?.data?.status);
            window.location.reload();
            rzp1.close();
            // navigate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //   setUpdateStatus((prev) => !prev);
    },
    modal: {
      confirm_close: true,

      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        if (reason === undefined) {
          console.log("cancelled");
          handlePayment("Cancelled");
        } else if (reason === "timeout") {
          console.log("timedout");
          handlePayment("timedout");
        } else {
          console.log("failed");
          handlePayment("failed", {
            paymentReason,
            field,
            step,
            code,
          });
        }
      },
    },
    retry: {
      enabled: false,
    },
    timeout: 900,
    theme: {
      color: "",
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
