import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import useCookie from "../../hooks/cookie";
import {
  getRequest,
  noTokenGetRequest,
  noTokenPostRequest,
  request,
} from "../../helpers";

export const AddAddress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [setCookie, getCookie] = useCookie();
  const authToken = getCookie("gamlewala-auth");

  const [addressDetails, setAddressDetails] = useState({
    name: "",
    type: "",
    mobile: "",
    alternateMobile: "",
    street: "",
    city: "",
    state: "",
    country: "INDIA",
    postalCode: "",
    landmark: "",
    isDefault: "true",
  });

  const [errors, setErrors] = useState({
    mobile: "",
    alternateMobile: "",
  });

  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    noTokenPostRequest({ url: "location/state" })
      .then((res) => {
        if (res.data.status) {
          setState(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, []);

  useEffect(() => {
    if (addressDetails.state) {
      const credentials = { state: addressDetails.state };
      noTokenPostRequest({ url: "location/city", cred: credentials })
        .then((res) => {
          if (res.data.status) {
            setCity(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [addressDetails.state]);

  const cityList = city.map((elem) => ({
    label: elem.name,
    value: elem._id,
  }));

  const stateList = state.map((elem) => ({
    label: elem.name,
    value: elem._id,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile" || name === "alternateMobile") {
      const numericValue = value.replace(/\D/g, "");
      if (value === numericValue) {
        setAddressDetails((prev) => ({
          ...prev,
          [name]: numericValue.slice(0, 10), // Limit to 10 digits
        }));
      }
    }

    // if (name === "mobile" || name === "alternateMobile") {
    //   // Ensure that only digits are allowed and limit to 10 digits
    //   const digitOnlyValue = value.replace(/\D/g, ""); // Remove non-digit characters

    //   if (digitOnlyValue?.length != 0 && digitOnlyValue.length < 10) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: "Phone number must be exactly 10 digits.",
    //     }));
    //   }

    // Update address details
    setAddressDetails({
      ...addressDetails,
      [name]: value,
    });

    // Validate phone numbers
    if (name === "mobile" || name === "alternateMobile") {
      if (value?.length !== 10 && value !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (addressDetails.mobile.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Phone number must be exactly 10 digits.",
      }));
      isValid = false;
    }

    if (addressDetails.alternateMobile.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        alternateMobile: "Alternate phone number must be exactly 10 digits.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const credentials = { ...addressDetails, default: "true" };
    request({
      url: "address/add",
      method: "post",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          setTimeout(() => {
            navigate("/account/my-address");
          }, 2000);
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="w-[inherit] py-6">
        <h2 className="text-xl font-semibold leading-7 text-gray-900 mb-4 mx-2">
          Add Address
        </h2>
        <div className="card shrink-0 w-full max-w-2xl p-5 shadow-2xl bg-white font-[roboto]">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5">
            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Name</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={addressDetails?.name}
                  id="name"
                  onChange={handleChange}
                  className="input input-bordered bg-white"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Address Type</span>
                </label>
                <input
                  type="text"
                  name="type"
                  value={addressDetails?.type}
                  onChange={handleChange}
                  id="type"
                  className="input input-bordered bg-white"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Phone Number</span>
                </label>
                <input
                  type="number"
                  name="mobile"
                  value={addressDetails?.mobile}
                  id="mobile"
                  maxLength={10}
                  pattern="\d{10}"
                  autoComplete="mobile-number"
                  onChange={handleChange}
                  className="input input-bordered bg-white"
                />
                {errors?.mobile && (
                  <p className="text-red-500">{errors?.mobile}</p>
                )}
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">
                    Alternate Phone Number
                  </span>
                </label>
                <input
                  type="number"
                  name="alternateMobile"
                  value={addressDetails?.alternateMobile}
                  id="alternatemobile"
                  autoComplete="mobile-number"
                  onChange={handleChange}
                  maxLength={10}
                  pattern="\d{10}"
                  className="input input-bordered bg-white"
                />
                {errors.alternateMobile && (
                  <p className="text-red-500">{errors?.alternateMobile}</p>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Street</span>
                </label>
                <input
                  type="text"
                  name="street"
                  value={addressDetails?.street}
                  id="street"
                  onChange={handleChange}
                  className="input input-bordered bg-white"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Landmark</span>
                </label>
                <input
                  type="text"
                  name="landmark"
                  value={addressDetails?.landmark}
                  id="landmark"
                  onChange={handleChange}
                  className="input input-bordered bg-white"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Pincode</span>
                </label>
                <input
                  type="text"
                  name="postalCode"
                  value={addressDetails?.postalCode}
                  id="postalCode"
                  onChange={handleChange}
                  className="input input-bordered bg-white"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label className="label">
                <span className="label-text text-gray-700">State</span>
              </label>
              <select
                name="state"
                onChange={handleChange}
                id="state"
                className="bg-white block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
              >
                <option>Select State</option>
                {stateList?.map((state, index) => (
                  <option key={index} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="sm:col-span-2">
              <label className="label">
                <span className="label-text text-gray-700">City</span>
              </label>
              <select
                name="city"
                onChange={handleChange}
                id="city"
                className=" bg-white block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
              >
                <option>Select City</option>
                {cityList?.map((city, index) => (
                  <option key={index} value={city.value}>
                    {city.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="sm:col-span-2">
              <label className="label">
                <span className="label-text text-gray-700">Country</span>
              </label>
              <div className="">
                <input
                  type="text"
                  name="country"
                  value={addressDetails?.country?.toUpperCase()}
                  id="country"
                  className="bg-white block w-full rounded-md border-0 p-[0.6rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                  readOnly
                />
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="btn bg-[#D8E7CC] sm:col-span-6 hover:bg-[#BCCBB1] border-none text-[#042100]"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
