import React from 'react'
import Header from '../../pages/Header'
import { Footer } from '../../pages/Footer'

const ShippingPolicy = () => {
    return (
        <>
            <Header />
            <div className='bg-gray-100 '>
                <p className='text-center text-4xl pt-32 pb-8 font-bold underline underline-offset-8 decoration-lime-500'>Shipping Policy</p>
                <div className='flex items-center justify-center'>
                    <div className='w-10/12 mb-10'>
                        <div className='mt-7'>
                            <p className='text-lg font-semibold'>What are the delivery timeline (maximum and minimum days) for the products listed with GamleWala?</p>
                            <div className='mt-2'>Gamlewala is ensuring delivery minimum 2 Days to maximum 7 Days.</div>
                        </div>

                        <div className='mt-7'>
                            <p className='text-lg font-semibold'>What are the delivery charges?</p>
                            <div className='mt-2'>Delivery charge varies with each Seller.</div>
                            <div className='mt-2'>Sellers incur relatively higher shipping costs on low value items. In such cases, charging a nominal delivery charge helps them offset logistics costs. Please check your order summary to understand the delivery charges for individual products.</div>
                            <div className='mt-2'>For Products listed with us a Rs 80 charge for delivery per item is applied if the order value is less than Rs 500. While, orders of Rs 499 or above are delivered free.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>Why does the delivery date not correspond to the delivery timeline of X-Y business days?</p>
                            <div className='mt-2'>It is possible that the Seller or our courier partners have a holiday between the day your placed your order and the date of delivery, which is based on the timelines shown on the product page. In this case, we add a day to the estimated date. Some courier partners and Sellers do not work on Sundays and this is factored in to the delivery dates.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>What is the estimated delivery time?</p>
                            <div className='mt-2'>Sellers generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays.</div>
                            <div className='mt-2'>Estimated delivery time depends on the following factors:</div>

                            <ul className='mt-5'>
                                <li className='mt-1'>  •	  The Seller offering the product.</li>
                                <li className='mt-1'>  •	  Product's availability with the Seller.</li>
                                <li className='mt-1'>  •	  The destination to which you want the order shipped to and location of the Seller.</li>
                            </ul>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>Are there any hidden costs on items sold by Sellers on GamleWala?</p>
                            <div className='mt-2'>There are NO hidden charges when you make a purchase on GamleWala. </div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>Why does the estimated delivery time vary for each seller?</p>
                            <div className='mt-2'>You have probably noticed varying estimated delivery times for sellers of the product you are interested in. Delivery times are influenced by product availability, geographic location of the Seller, your shipping destination and the courier partner's time-to-deliver in your location.</div>
                            <div className='mt-2'>Please enter your default pin code on the product page (you don't have to enter it every single time) to know more accurate delivery times on the product page itself.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>Seller does not/cannot ship to my area. Why?</p>
                            <div className='mt-2'>Please enter your pincode on the product page (you don't have to enter it every single time) to know whether the product can be delivered to your location.</div>
                            <div className='mt-2'>If you haven't provided your pincode until the checkout stage, the pincode in your shipping address will be used to check for serviceability.</div>
                            <div className='mt-2'>Whether your location can be serviced or not depends on</div>
                            <ul className='mt-5'>
                                <li className='mt-1'>  •	  Whether the Seller ships to your location.</li>
                                <li className='mt-1'>  •	  Legal restrictions, if any, in shipping particular products to your location.</li>
                                <li className='mt-1'>  •	  The availability of reliable courier partners in your location.</li>
                            </ul>
                            <div className='mt-5'>At times Sellers prefer not to ship to certain locations. This is entirely at their discretion.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>Returns are easy. Contact Us to initiate a return. You will receive a call explaining the process, once you have initiated a return.</p>
                            <div className='mt-2'>Returns are easy. Contact Us to initiate a return. You will receive a call explaining the process, once you have initiated a return.</div>
                            <div className='mt-2'>What do the different tags like "In Stock", "Available" mean?</div>
                            <div className='mt-2'>'In Stock'</div>
                            <div className='mt-2'>For items listed as "In Stock", Sellers will mention the delivery time based on your location pincode (usually 2-3 business days, 4-5 business days or 4-6 business days in areas where standard courier service is available). For other areas, orders will be sent by Registered Post through the Indian Postal Service which may take 1-2 weeks depending on the location.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>'Available'</p>
                            <div className='mt-2'>The Seller might not have the item in stock but can procure it when an order is placed for the item. The delivery time will depend on the estimated procurement time and the estimated shipping time to your location.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>'Out of Stock'</p>
                            <div className='mt-2'>Currently, the item is not available for sale. Use the 'Notify Me' feature to know once it is available for purchase</div>                           
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>'Back In Stock Soon'</p>
                            <div className='mt-2'>The item is popular and is sold out. You can however 'book' an order for the product and it will be shipped according to the timelines mentioned by the Seller.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>'Temporarily Unavailable'</p>
                            <div className='mt-2'>The product is currently out of stock and is not available for purchase. The product could to be in stock soon. Use the 'Notify Me' feature to know when it is available for purchase.</div>
                        </div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'>'Permanently Discontinued'</p>
                            <div className='mt-2'>This product is no longer available because it is obsolete and/or its production has been discontinued.</div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy
