import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { noTokenGetRequest } from "../../helpers";

const Banner = () => {
  const [bannerList, setBannerList] = useState();

  useEffect(() => {
    noTokenGetRequest({ url: "banner" })
      .then((res) => {
        if (res.data.status) {
          setBannerList(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, []);
  return (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        loop={true}
        navigation={window.innerWidth > 640}
        pagination={{ clickable: true }}
        // slidesPerView={4}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {bannerList?.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              src={banner?.image?.url?.[0]}
              className="w-full"
              alt={`Banner ${index}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Banner;
