import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import useCookie from "../../hooks/cookie";
import { deleteRequest, getRequest } from "../../helpers";
import { useNavigate } from "react-router-dom";

export const AddressList = () => {
  const navigate = useNavigate();
  const [setCookie, getCookie] = useCookie();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  const [addressDetails, setAddressDetails] = useState([]);
  const [deleteAddressStatus, setDeleteAddressStatus] = useState(false);

  useEffect(() => {
    token &&
      getRequest({ url: "address", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setAddressDetails(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
  }, [deleteAddressStatus]);

  const deleteAddress = (id) => {
    deleteRequest({ url: `address/delete/${id}`, token: authToken })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          setDeleteAddressStatus(!deleteAddressStatus);
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="w-[inherit] py-6 font-[roboto]">
        <div className="text-right mb-3">
          <button
            onClick={() => navigate("add-address")}
            className=" bg-[#D8E7CC] hover:bg-[#BCCBB1] btn border-none  shadow-xl text-[#042100] font-bold "
          >
            Add New Address
          </button>
        </div>
        <h2 className="text-xl font-semibold leading-7 text-gray-900 mb-4 mx-2  underline decoration-[#BCCBB1]">
          Address List
        </h2>
        {addressDetails.length ? (
          addressDetails?.map((addressDetails, index) => {
            return (
              <>
                {/* <div className="card shrink-0 w-full max-w-2xl p-5 shadow-2xl bg-white font-[roboto] m-auto">
                  <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5 ">
                    <div className="sm:col-span-3">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Name</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={addressDetails?.name}
                          id="name"
                          placeholder="name"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Address Type</span>
                        </label>
                        <input
                          type="text"
                          name="type"
                          value={addressDetails?.type}
                          id="type"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> Phone Number</span>
                        </label>
                        <input
                          type="number"
                          name="mobile"
                          value={addressDetails?.mobile}
                          id="mobile"
                          autoComplete="mobile-number"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            {" "}
                            ALternate Phone Number
                          </span>
                        </label>
                        <input
                          type="number"
                          name="alternateMobile"
                          value={addressDetails?.alternateMobile}
                          id="alternatemobile"
                          autoComplete="mobile-number"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> Street</span>
                        </label>
                        <input
                          type="text"
                          name="street"
                          value={addressDetails?.street}
                          id="street"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> Landmark</span>
                        </label>
                        <input
                          type="text"
                          name="landmark"
                          value={addressDetails?.landmark}
                          id="landmark"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> Pincode</span>
                        </label>
                        <input
                          type="number"
                          name="postalCode"
                          value={addressDetails?.postalCode}
                          id="postalCode"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> State</span>
                        </label>
                        <input
                          type="text"
                          name="state"
                          value={addressDetails?.state?.name}
                          id="state"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> City</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={addressDetails?.city?.name}
                          id="city"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text"> Country</span>
                        </label>
                        <input
                          type="text"
                          name="country"
                          value={addressDetails?.country.toUpperCase()}
                          id="country"
                          className="input input-bordered bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => navigate(`${addressDetails._id}`)}
                      className="btn bg-[#D8E7CC] hover:bg-[#BCCBB1] sm:col-span-3 border-none hover:shadow-xl"
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => deleteAddress(addressDetails._id)}
                      className="btn btn-error sm:col-span-3 border-none hover:shadow-xl"
                    >
                      Delete
                    </button>
                  </div>
                </div> */}
                <div className="bg-gray-50 my-5 p-5 rounded-lg ">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-2 items-center mb-3">
                      <p className="font-semibold">{addressDetails?.name}</p>
                      <p className=" px-2 py-1 rounded-lg shadow-sm bg-white font-semibold">
                        {addressDetails?.type}
                      </p>
                    </div>
                    <div className="">
                      <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0}>
                          <div className="flex-none">
                            <button className="btn btn-square btn-ghost">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className="inline-block h-5 w-5 stroke-current"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <ul
                          tabIndex={0}
                          className="dropdown-content menu bg-white rounded-box z-[1] w-52 p-2 shadow"
                        >
                          <li onClick={() => navigate(`${addressDetails._id}`)}>
                            <a> Edit</a>
                          </li>
                          <li onClick={() => deleteAddress(addressDetails._id)}>
                            <a>Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center font-[400] text-gray-700">
                    <p>{addressDetails?.street},&nbsp; </p>
                    <p>{addressDetails?.landmark},&nbsp; </p>

                    <p>{addressDetails?.city?.name},&nbsp; </p>
                    <p>{addressDetails?.state?.name}&nbsp; -&nbsp; </p>
                    <p className="text-black">{addressDetails?.postalCode}</p>
                  </div>
                  <p className="font-semibold my-2">{addressDetails?.mobile}</p>
                </div>
              </>
            );
          })
        ) : (
          <>
            <div className="text-center text-xl font-semibold">
              No addresses available
            </div>
          </>
        )}
      </div>
    </>
  );
};
