import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import CartContextWrapper from "./contexts/CartContext";
import WishlistContextWrapper from "./contexts/WishListContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider
    // /*clientId="695214652902-2piin454c41st2mkbo4grr8kj6tdluc3.apps.googleusercontent.com"*/ clientId="981897660602-dilsd1vlsm05a79elsihfrgl9f39gub3.apps.googleusercontent.com"
    // clientId={`${process.env.GOOGLE_CLIENT_KEY}`}
    clientId="695214652902-2piin454c41st2mkbo4grr8kj6tdluc3.apps.googleusercontent.com"
  >
    <React.StrictMode>
      <BrowserRouter>
        <CartContextWrapper>
          <WishlistContextWrapper>
            <App />
          </WishlistContextWrapper>
        </CartContextWrapper>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
