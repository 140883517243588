import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import Google from "../assets/images/socials/google.png";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { noTokenGetRequest } from "../helpers";
import { useParams } from "react-router-dom";
import axios from "axios";

const Emailverification = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [message, setMessege] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_WEB_BASE_URL}:5555/api/common/verify-email/${params.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          setMessege(res.data.msg);
          // setTimeout(() => {
          //     navigate('/signin')
          // }, 2000)
        } else {
          setMessege(res?.data?.data?.msg);
        }
      })
      .catch((AxiosError) => {
        setMessege(AxiosError.response.data.msg);
      });
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div
        style={{
          backgroundImage: `url('./assets/pots--bg.jpg')`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="bg-white p-2 flex items-center justify-center w-full fixed z-10">
          <div className="flex cursor-pointer">
            <img className="h-10 mx-2" src={Logo} alt="Logo" />
            <img className="h-10" src={Logod} alt="Logo" />
          </div>
        </div>
        <div className="h-screen flex items-center justify-center">
          <div className=" bg-white w-full sm:w-6/12 lg:w-5/12 xl:w-4/12 mt-12 flex items-center justify-center rounded-md place-self-center">
            <div className="w-full">
              <p className="text-center mb-4 text-4xl text-red-600">
                {message}
              </p>
              <div className="flex items-center justify-center">
                <button
                  className="h-10 my-2 w-1/2 text-center bg-lime-200 items-center"
                  onClick={() => navigate("/")}
                >
                  <span type="submit" className="font-medium">
                    Go to Home Page
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emailverification;
