import React, { useState } from "react";
import useCookie from "../../hooks/cookie";
import toast, { Toaster } from "react-hot-toast";
import { request } from "../../helpers";

export const ChangePassword = () => {
  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();

  const [passwordDetails, setPasswordDetails] = useState({
    old: "",
    new: "",
  });

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const EditPassword = () => {
    const credentials = { ...passwordDetails };

    request({
      url: "profile/change-password",
      method: "put",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (res.data.status) {
          setPasswordDetails({
            old: "",
            new: "",
          });
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <div className="w-[inherit] py-6">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl font-bold text-[#042100]">
              Change Password
            </h1>
            <p className="py-6 line-clamp-3 ">
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi.
            </p>
          </div>
          <div className="card shrink-0 w-full max-w-xl p-5 shadow-2xl bg-white font-[roboto]">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Old Password</span>
                </label>
                <input
                  type="password"
                  name="old"
                  id="name"
                  value={passwordDetails.old}
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                  required
                />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">New Password</span>
                </label>
                <input
                  type="password"
                  name="new"
                  id="newpassword"
                  value={passwordDetails.new}
                  onChange={handleChange}
                  autoComplete="new-password"
                  className="input input-bordered bg-white focus:outline-none"
                  required
                />
              </div>
              <div className="form-control mt-6 sm:col-span-2">
                <button
                  className="btn bg-[#D8E7CC] text-[#042100] hover:bg-[#BCCBB1] border-0 hover:shadow-xl"
                  onClick={() => EditPassword()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
