import React from 'react'
import Header from '../../pages/Header'
import { Footer } from '../../pages/Footer'

const ReturnPolicy = () => {
    return (
        <>
            <Header />
            <div className='bg-gray-100 '>
                <p className='text-center text-4xl pt-32 pb-8 font-bold underline underline-offset-8 decoration-lime-500'>Return Policy</p>
                <div className='flex items-center justify-center'>
                    <div className='w-10/12 mb-10'>
                        <div className='mt-5'>Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below</div>

                        <div className='mt-5'>The return policy is in three parts; Do read all sections carefully to understand the conditions and cases under which returns will be accepted.</div>

                        <div className='mt-5'>
                            <p className='text-lg font-semibold'> Part 1 – Category, Return and Actions possible
                            </p>
                            <div className='mt-5'>
                                <table>
                                    <tr className='m-5'>
                                        <th>Category</th>
                                        <th>Returns , Actions Possible and Conditions (if any)</th>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Apparel & Fashion : Kid's (Capri, Shorts & Tops), Women's (Ethnic Wear, Shirt, Formals, Jeans, Clothing Accessory, Fabric, Blouse, Jean, Skirt, Trousers), Banarasi Sarees </td>
                                        <td className='p-5'>14 days,
                                            Refund, replacement or exchange
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Gamle & Compost </td>
                                        <td className='p-5'>7 days,
                                            Replacement or exchange, these include perishable items and delivery would be attempt once, there would be no re-attempt in case you miss your order delivery date scheduled.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Plant & Pottery </td>
                                        <td className='p-5'>7 days,
                                            Replacement or exchange, these include perishable items and delivery would be attempt once, there would be no re-attempt in case you miss your order delivery date scheduled.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Home: Home Improvement Tools, Household Items, Home décor, Furnishing</td>
                                        <td className='p-5'>10 days,
                                            Refund or replacement
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>D2 Electronics </td>
                                        <td className='p-5'>10 days,
                                            Replacement only
                                            In order to help you resolve issues with your product, we may troubleshoot your product through online tools, over the phone
                                            If a defect is determined within the Returns Window, a replacement of the same model will be provided at no additional cost. If no defect is confirmed or the issue is not diagnosed within 10 days of delivery, you will be directed to a brand service centre to resolve any subsequent issues.
                                            In any case, only one replacement shall be provided.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>D2 Mobile - Apple & Samsung.</td>
                                        <td className='p-5'>10 days,
                                            Replacement only
                                            For all functionality related issues, do contact the brand authorized service centre directly.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Furniture & Kitchen Fixtures</td>
                                        <td className='p-5'>10 days,
                                            Replacement only
                                            For products requiring installation, returns shall be eligible only when such products are installed by the brand's authorized personnel.
                                            In order to help you resolve issues with your product, we may troubleshoot  your product through online tools, over the phone
                                            If a defect is determined within the Returns Window, a replacement of the same model will be provided at no additional cost. If no defect is confirmed or the issue is not diagnosed within 10 days of delivery or Installation wherever applicable, you will be directed to a brand service centre to resolve any subsequent issues.
                                            In any case, only one replacement shall be provided.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Cakes and Bouquets - (Cakes , Chocolates , Flowers &  Bakery products )</td>
                                        <td className='p-5'>No Return Category </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Devotional and Personalised Items  </td>
                                        <td className='p-5'>7 days,
                                            Return , Refund only
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>Try & Buy</td>
                                        <td className='p-5'>10 days,
                                            Refund only
                                            This policy shall be applicable selectively (geographical coverage, product, customer and time periods).
                                            Try & Buy benefits shall be applicable only if the product was bought when the item was on Try & Buy. Else normal category policy shall apply on the order. In any case, only one replacement shall be provided.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-5'>No Returns categories</td>
                                        <td className='p-5'>Some products in the above categories are not returnable due to their nature or other reasons. For all products, the policy on the product page shall prevail.</td>
                                    </tr>
                                </table>
                            </div>
                            
                            <div className='mt-5'>
                                <p className='text-lg font-semibold'>Part 2 - Returns Pick-Up and Processing</p>
                                <div className='mt-5'>In case of returns where you would like item(s) to be picked up from a different address, the address can only be changed if pick-up service is available at the new address</div>
                                <div className='mt-5'>During pick-up, your product will be checked for the following conditions:</div>
                                <div className='mt-5'>
                                    <table>
                                        <tr>
                                            <th className='p-5'>Category</th>
                                            <th className='p-5'>Conditions</th>
                                        </tr>
                                        <tr>
                                            <td className='p-5'>Correct Product</td>
                                            <td className='p-5'>IMEI/ name/ image/ brand/ serial number/ article number/ bar code should match and MRP tag should be undetached and clearly visible.</td>
                                        </tr>
                                        <tr>
                                            <td className='p-5'>Complete Product</td>
                                            <td className='p-5'>All in-the-box accessories (like remote control, starter kits, instruction manuals, chargers, headphones, etc.), freebies and combos (if any) should be present.</td>
                                        </tr>
                                        <tr>
                                            <td className='p-5'>Unused Product</td>
                                            <td className='p-5'>The product should be unused, unwashed, unsoiled, without any stains and with non-tampered quality check seals/return tags/warranty seals (wherever applicable). Before returning a Mobile/ Tablet / Electronics, the device should be formatted and Screen Lock (Pin, Pattern or Fingerprint) must be disabled. iCloud lock must be disabled for Apple devices.</td>
                                        </tr>
                                        <tr>
                                            <td className='p-5'>Undamaged Product</td>
                                            <td className='p-5'>The product (including SIM trays/ charging port/ headphone port, back-panel etc.) should be undamaged and without any scratches, dents, tears or holes.</td>
                                        </tr>
                                        <tr>
                                            <td className='p-5'>Undamaged Packaging</td>
                                            <td className='p-5'>Product's original packaging/ box should be undamaged.</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div className='mt-5'>
                                The field executive will refuse to accept the returns if any of the above conditions are not met.
                            </div>

                            <div className='mt-5'>
                                For any products for which a refund is to be given, the refund will be processed once the returned product has been received by the seller.
                            </div>

                            <div className='mt-5'>
                                <p className='text-lg font-semibold'>Part 3 - General Rules for a successful Return</p>
                                <div className='mt-5'>1.	In certain cases where the seller is unable to process a replacement for any reason whatsoever, please raise a ticket with Gamlewala.com to investigate and ascertain a refund to process with seller.</div>
                                <div className='mt-5'>2.	For products where installation is provided by Gamlewala.com service partners, do not open the product packaging by yourself. Gamlewala.com authorised personnel/ partners shall help in unboxing and installation of the product.</div>
                                <div className='mt-5'>3.	For Furniture, any product related issues will be checked by authorised service personnel (free of cost) and attempted to be resolved by replacing the faulty/ defective part of the product. Full replacement will be provided only in cases where the service personnel opinion that replacing the faulty/defective part will not resolve the issue.</div>
                                <div className='mt-5'>Wrong Delivery - (Customer received delivery message, product not delivered):</div>
                                <div className='mt-5'>'In case the product was not delivered and you received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation for the seller to investigate.'</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ReturnPolicy
