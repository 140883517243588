// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import fullLogo from "../assets/images/full-img.png";
import { CiLocationOn } from "react-icons/ci";
import { PiShoppingCart } from "react-icons/pi";
import { VscAccount } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { BsSuitHeart } from "react-icons/bs";
import useCookie, { deleteCookie } from "../hooks/cookie";
import { getRequest } from "../helpers";
import toast, { Toaster } from "react-hot-toast";

const Header = () => {
  const navigate = useNavigate();

  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();

  const [profileDetails, setProfileDetails] = useState();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  useEffect(() => {
    {
      token &&
        getRequest({ url: "profile", token: authToken })
          .then((res) => {
            console.log("token hai kya ?", token);
            if (res.data.status) {
              setProfileDetails(res.data.data);
            }
          })
          .catch((AxiosError) => {
            console.log(AxiosError);
          });
    }
  }, []);

  const MyAccount = () => {
    if (token) {
      navigate("/account/my-profile");
    } else {
      navigate("/signin");
    }
  };

  const MyOrders = () => {
    if (token) {
      navigate("/account/my-orders");
    } else {
      navigate("/signin");
    }
  };

  const MyAddress = () => {
    if (token) {
      navigate("/account/my-address");
    } else {
      navigate("/signin");
    }
  };

  const ChangePassword = () => {
    if (token) {
      navigate("/account/change-password");
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="hidden">
      <Toaster position="top-right" reverseOrder={true} />
      <div className="z-10 fixed">
        <div className="flex w-full items-center justify-center bg-white">
          <div className="w-11/12  grid grid-cols-4 gap-4 h-10 xl:h-20 lg:h-16 lg:p-4 md:h-12 md:p-3 sm:h-14 sm:m-0 items-center">
            <div
              className="justify-items-center flex cursor-pointer"
              onClick={() => navigate("/")}
            >
              {/* <img className="w-12 p-2 lg:w-16 md:w-14 sm:w-14" src={Logo} alt='logo'></img> */}
              {/* <img
                className="w-8 h-6 xl:h-10 xl:w-16 lg:h-8 lg:w-10 md:h-6 md:w-8  sm:h-6 sm:w-6"
                src={Logo}
                alt="logo"
              ></img> */}
              <img
                className=" hidden xl:block xl:h-10 xl:w-80 lg:block lg:h-8 lg:w-100 md:block md:h-6 md:w-100 sm:block  sm:h-6 sm:w-100 sm:m-0"
                src={fullLogo}
                alt="logo"
              ></img>
            </div>
            <input
              className=" h-6 w-full p-2 placeholder:text-red-400 rounded-md outline-lime-500 bg-lime-200 focus:outline xl:h-10 xl:w-full xl:m-0 lg:h-8 lg:w-11/12 lg:m-0 md:h-6 md:w-11/12 md:m-0 sm:h-6 sm:w-30"
              name="search"
              placeholder="Search"
            />
            <div>
              <button className="hidden bg-lime-200 flex rounded-md xl:block xl:h-10 xl:w-8/12 lg:h-8 lg:block lg:w-8/12 lg:p-1 md:block md:h-6 md:w-10/12 md:p-0 sm:block">
                <div className="flex justify-space">
                  <CiLocationOn className="text-lg p-1 md:text-xl xl:text-xl" />
                  Select Location
                </div>
              </button>
            </div>
            <div className="m-2 space-x-6 flex justify-self-end sm:m-0 sm:space-x-10">
              <div className="relative">
                <div className="absolute w-4 h-4 text-xs text-center bg-lime-500 rounded-full -top-1 -right-2">
                  0
                </div>
                <BsSuitHeart className="text-xl cursor-pointer xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl" />
              </div>
              <div className="relative">
                <div className="absolute w-4 h-4 text-xs text-center bg-lime-500 rounded-full -top-1 -right-2">
                  0
                </div>
                <PiShoppingCart className="text-xl cursor-pointer xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl" />
              </div>
              <div className="dropdown dropdown-hover dropdown-end">
                <div tabIndex={0} role="button">
                  <VscAccount className=" pr-1 text-xl cursor-pointer xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl" />
                </div>
                {/* <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                    <li><a>My Account</a></li>
                                    <li><a>Logout</a></li>
                                </ul> */}
                <div
                  id="dropdownInformation"
                  class="dropdown-content z-[1] menu bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  {token && (
                    <div class="px-4 py-3 text-sm text-gray-900 dark:text-white truncate">
                      <div>{profileDetails?.name}</div>
                      <div className="font-medium truncate">
                        {profileDetails?.email.length >= 18
                          ? profileDetails?.email.slice(0, 18) + "..."
                          : profileDetails?.email}
                      </div>
                    </div>
                  )}
                  {!token && (
                    <div class="py-2">
                      <a
                        onClick={() => navigate("/signin")}
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer rounded-lg"
                      >
                        Sign In
                      </a>
                    </div>
                  )}
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownInformationButton"
                  >
                    <li>
                      <a
                        onClick={() => MyAccount()}
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        My Account
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => MyOrders()}
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        My Orders
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => MyAddress()}
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        My Address
                      </a>
                    </li>
                    {token && (
                      <li>
                        <a
                          onClick={() => ChangePassword()}
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Change Password
                        </a>
                      </li>
                    )}
                  </ul>
                  {token && (
                    <div class="py-2">
                      <a
                        onClick={() => {
                          deleteCookie();
                          window.location.reload();
                        }}
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer rounded-lg"
                      >
                        Log out
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="group relative cursor-pointer "> */}
              {/* <VscAccount className=' pr-1 text-xl cursor-pointer xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl' onClick={() => navigate('/signin')} /> */}
              {/* <div
                                    className="invisible absolute z-50 flex flex-col bg-white py-1 px-4 text-gray-800 shadow-xl group-hover:visible">

                                    <a className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">
                                        Account
                                    </a>
                                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="w-full bg-white flex items-center justify-center">
          <div className="hidden sm:block w-3/4">
            <ul className="mx-5 flex justify-evenly">
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button">
                  <div className="group relative cursor-pointer">
                    <li className="menu-hover flex px-4 py-0 lg:py-1 ">
                      <a>Garden Galleria</a>
                      <span className="self-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </li>
                  </div>
                </div>
                <div
                  id="dropdownInformation"
                  class="dropdown-content z-[1] menu bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownInformationButton"
                  >
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Gamle
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Plants
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Seeds and Medicine
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Compost
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Gamla Stand{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Garden Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button">
                  <div className="group relative cursor-pointer">
                    <li className="menu-hover flex px-4 py-0 lg:py-1 ">
                      <a>Eevent Galleria</a>
                      <span className="self-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </li>
                  </div>
                </div>
                <div
                  id="dropdownInformation"
                  class="dropdown-content z-[1] menu bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownInformationButton"
                  >
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Car Decoration
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Party Decoration
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button">
                  <div className="group relative cursor-pointer">
                    <li className="menu-hover flex px-4 py-0 lg:py-1 ">
                      <a>Pooja Galleria</a>
                      <span className="self-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </li>
                  </div>
                </div>
                <div
                  id="dropdownInformation"
                  class="dropdown-content z-[1] menu bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownInformationButton"
                  >
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Devotional Package
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Devotional Items
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} role="button">
                  <div className="group relative cursor-pointer">
                    <li className="menu-hover flex px-4 py-0 lg:py-1 ">
                      <a>Flowers and Cakes</a>
                      <span className="self-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </li>
                  </div>
                </div>
                <div
                  id="dropdownInformation"
                  class="dropdown-content z-[1] menu bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownInformationButton"
                  >
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Cakes
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Flower Hamper
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Flower by color
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Flower Type
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
