import React, { useState } from "react";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import Google from "../assets/images/socials/google.png";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { noTokenPostRequest, postRequest } from "../helpers";
import useCookie from "../hooks/cookie";
import { useParams } from "react-router-dom";

const Setnewpassword = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [setCookie, getCookie] = useCookie();

  const [loginCredentials, setLoginCredentials] = useState({
    password: "",
  });

  const handleLogin = (e) => {
    const { name, value } = e.target;
    setLoginCredentials({
      ...loginCredentials,
      [name]: value,
    });
  };

  const signin = (e) => {
    e.preventDefault();
    let credentials = { ...loginCredentials };
    const token = `${params.id}`;
    postRequest({ url: "auth/reset-password", cred: credentials, token: token })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res.data.msg);
          setLoginCredentials({
            email: "",
            password: "",
          });
          setTimeout(() => {
            navigate("/signin");
          }, 2000);
        } else {
          toast.error(res?.data?.data?.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div
        style={{
          backgroundImage: `url('./assets/pots--bg.jpg')`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="bg-white p-2 flex items-center justify-center w-full fixed z-10">
          <div className="flex cursor-pointer" onClick={() => navigate("/")}>
            <img className="h-10 mx-2" src={Logo} alt="Logo" />
            <img className="h-10" src={Logod} alt="Logo" />
          </div>
        </div>
        <div className="h-screen flex items-center justify-center">
          <div className=" bg-white w-11/12 sm:w-6/12 lg:w-5/12 xl:w-4/12 mt-12 flex items-center justify-center rounded-md place-self-center">
            <div className="w-4/5">
              <div className="my-5">
                <p className="text-2xl text-center">Set New Password</p>
                {/* <p className='text-md'>Don't have an account? <button onClick={() =>
                                    navigate('/signup')} className='text-lime-400 font-medium'>Sign Up</button></p> */}
              </div>
              <form onSubmit={signin}>
                <label htmlFor="email" className="mt-2">
                  New Pasword
                </label>
                <input
                  type="password"
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2"
                  name="password"
                  placeholder="Password"
                  value={loginCredentials.password}
                  onChange={handleLogin}
                  required
                />
                <button className="h-10 my-2 w-full text-center bg-lime-200">
                  <span type="submit" className="font-medium">
                    Continue
                  </span>
                </button>
              </form>
              {/* <p className='my-1 text-sm cursor-pointer'>Forgot password?</p> */}
              {/* <div className="inline-flex items-center justify-center w-full">
                                <hr className="w-full h-px my-6 bg-gray-200 border-0 dark:bg-gray-500" />
                                <span className="absolute px-3 font-medium text-gray-500 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or sign in</span>
                            </div> */}
              {/* <div className='flex items-center justify-center mb-4 cursor-pointer'>
                                <div className='border border-gray-500'>
                                    <div className='flex my-2 justify-center mx-4'>
                                        <img src={Google} alt="G" className='h-5 mr-2 mt-1' />
                                        <p className='text-lg font-medium'>Google</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setnewpassword;
