import { createContext, useState } from "react";

// CREATING CONTEXT API
export const WishlistContext = createContext(null);

const WishlistContextWrapper = (props) => {

    // DECLARING STATE FOR DATA TO BE MANAGED
    const [wishListdata, setWishListData] = useState(0);

    return (
        // PASSING DATA STATE GETTER & SETTER AS CONTEXT VALUE
        <WishlistContext.Provider value={[wishListdata, setWishListData]}>
            {/* WRAPPING UP CHILD COMPONENT INSIDE CONTEXT */}
            {props.children}
        </WishlistContext.Provider>
    );
};

export default WishlistContextWrapper;