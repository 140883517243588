import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import useCookie from "../../hooks/cookie";
import {
  getRequest,
  noTokenGetRequest,
  noTokenPostRequest,
  request,
} from "../../helpers";

export const EditAddress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [setCookie, getCookie] = useCookie();
  const authToken = getCookie("gamlewala-auth");

  const [addressDetails, setAddressDetails] = useState({});
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    getRequest({ url: `address/${params.id}`, token: authToken })
      .then((res) => {
        if (res.data.status) {
          setAddressDetails(res?.data?.data);
        } else {
          toast.error(res?.data?.msg, { duration: 2000 });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg, { duration: 2000 });
      });
  }, [params.id, authToken]);

  useEffect(() => {
    noTokenPostRequest({ url: "location/state" })
      .then((res) => {
        if (res.data.status) {
          setState(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, []);

  useEffect(() => {
    if (addressDetails?.state) {
      const credentials = { state: addressDetails.state };
      noTokenPostRequest({ url: "location/city", cred: credentials })
        .then((res) => {
          if (res.data.status) {
            setCity(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [addressDetails?.state]);

  const cityList = city.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  const stateList = state.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" || name === "alternateMobile") {
      // Ensure that only digits are allowed and limit to 10 digits
      const digitOnlyValue = value.replace(/\D/g, ""); // Remove non-digit characters
      setAddressDetails({
        ...addressDetails,
        [name]: digitOnlyValue.slice(0, 10), // Limit to 10 digits
      });
    }
    setAddressDetails({
      ...addressDetails,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (addressDetails?.mobile?.length !== 10) {
      toast.error("Please enter a valid phone number");
      isValid = false;
    }

    if (addressDetails?.alternateMobile?.length !== 10) {
      toast.error("Please enter a valid alternate phone number");
      isValid = false;
    }

    if (addressDetails?.postalCode?.length !== 6) {
      toast.error("Postal code must be exactly 6 digits.");
      isValid = false;
    }

    return isValid;
  };

  const EditAddress = () => {
    if (!validateForm()) {
      return;
    }

    const credentials = {
      ...addressDetails,
      addressId: params?.id,
      defalut: "false",
    };

    request({
      url: "address/update",
      method: "put",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg, { duration: 2000 });
          setTimeout(() => {
            navigate("/account/my-address");
          }, 2000);
        } else {
          toast.error(res?.data?.msg, { duration: 2000 });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg, { duration: 2000 });
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="w-[inherit] py-6">
        <h2 className="text-xl font-semibold leading-7 text-gray-900 mb-4 text-center m-auto">
          Update Address
        </h2>
        <div className="card shrink-0 w-full max-w-2xl p-5 shadow-2xl bg-white font-[roboto] m-auto">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5">
            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Name</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={addressDetails?.name || ""}
                  id="name"
                  placeholder="name"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Address Type</span>
                </label>
                <input
                  type="text"
                  name="type"
                  value={addressDetails?.type || ""}
                  id="type"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Phone Number</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  value={addressDetails?.mobile || ""}
                  maxLength={10}
                  id="mobile"
                  onChange={handleChange}
                  autoComplete="mobile-number"
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">
                    Alternate Phone Number
                  </span>
                </label>
                <input
                  type="text"
                  name="alternateMobile"
                  value={addressDetails?.alternateMobile || ""}
                  id="alternateMobile"
                  maxLength={10}
                  onChange={handleChange}
                  autoComplete="mobile-number"
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Street</span>
                </label>
                <input
                  type="text"
                  name="street"
                  value={addressDetails?.street || ""}
                  id="street"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Landmark</span>
                </label>
                <input
                  type="text"
                  name="landmark"
                  value={addressDetails?.landmark || ""}
                  id="landmark"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="form-control">
                <label className="label">
                  <span className="label-text text-gray-700">Pincode</span>
                </label>
                <input
                  type="text"
                  name="postalCode"
                  value={addressDetails?.postalCode || ""}
                  id="postalCode"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="state"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                State
              </label>
              <select
                name="state"
                onChange={handleChange}
                id="state"
                value={addressDetails?.state || ""}
                className="block w-full bg-white rounded-lg border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
              >
                <option value="" disabled>
                  Select State
                </option>
                {stateList.map((state, index) => (
                  <option key={index} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="city"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                City
              </label>
              <select
                name="city"
                onChange={handleChange}
                id="city"
                value={addressDetails?.city || ""}
                className="bg-white block w-full rounded-lg border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
              >
                <option value="" disabled>
                  Select City
                </option>
                {cityList.map((city, index) => (
                  <option key={index} value={city.value}>
                    {city.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="country"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                value={addressDetails?.country?.toUpperCase() || ""}
                id="country"
                className="bg-white block w-full rounded-lg border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                required
              />
            </div>

            <div className="sm:col-span-6 text-right">
              <button
                onClick={EditAddress}
                className="btn bg-[#D8E7CC] hover:bg-[#BCCBB1] sm:col-span-3 border-none text-[#042100]"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
