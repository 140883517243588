import React, { useEffect, useState } from "react";
import useCookie from "../../hooks/cookie";
import { getRequest, request } from "../../helpers";
import toast, { Toaster } from "react-hot-toast";

export const Myprofile = () => {
  const [setCookie, getCookie] = useCookie();
  const [profileDetails, setProfileDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    alternateMobile: "",
  });
  const [errors, setErrors] = useState({
    mobile: "",
    alternateMobile: "",
  });

  const authToken = getCookie("gamlawala-auth");
  const token = authToken !== undefined && authToken !== "undefined";

  useEffect(() => {
    if (token) {
      getRequest({ url: "profile", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setProfileDetails({
              name: res.data.data.name,
              email: res.data.data.email,
              mobile: res.data.data.mobile,
              alternateMobile: res.data.data.alternateMobile,
            });
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [token, authToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });

    // Validate phone numbers
    if (name === "mobile" || name === "alternateMobile") {
      if (value.length !== 10 && value !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (profileDetails.mobile.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Phone number must be exactly 10 digits.",
      }));
      isValid = false;
    }

    if (profileDetails.alternateMobile.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        alternateMobile: "Alternate phone number must be exactly 10 digits.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const EditProfile = () => {
    if (!validateForm()) {
      return;
    }

    const credentials = { ...profileDetails };

    request({
      url: "profile/update",
      method: "put",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="hero min-h-[60vh] bg-white">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl font-bold text-[#042100]">
              Personal Information
            </h1>
            <p className="py-6 line-clamp-3">
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi.
            </p>
          </div>
          <div className="card shrink-0 w-full max-w-xl p-5 shadow-2xl bg-white font-[roboto]">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="col-span-2">
                <h2 className="font-semibold text-2xl">
                  Edit Your Personal Information
                </h2>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={profileDetails?.name}
                  onChange={handleChange}
                  placeholder="name"
                  className="input input-bordered bg-white focus:outline-none"
                  required
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={profileDetails?.email}
                  id="email"
                  autoComplete="email"
                  placeholder="email"
                  className="input input-bordered bg-white focus:outline-none input-bg-disabled"
                  required
                  disabled
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Phone Number</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  value={profileDetails?.mobile}
                  id="mobile"
                  autoComplete="mobile-number"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                  required
                />
                {errors.mobile && (
                  <p className="text-red-500">{errors.mobile}</p>
                )}
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Alternate Phone Number</span>
                </label>
                <input
                  type="text"
                  name="alternateMobile"
                  value={profileDetails.alternateMobile}
                  id="alternatemobile"
                  autoComplete="mobile-number"
                  onChange={handleChange}
                  className="input input-bordered bg-white focus:outline-none"
                />
                {errors.alternateMobile && (
                  <p className="text-red-500">{errors.alternateMobile}</p>
                )}
              </div>
            </div>
            <div className="form-control mt-6">
              <button
                className="btn bg-[#D8E7CC] text-[#042100] hover:bg-[#BCCBB1] border-0 hover:shadow-xl"
                onClick={EditProfile}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
