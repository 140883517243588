import React, { useContext, useEffect, useRef, useState } from "react";
import "../FilterComponent/filterStyle.css";
import Header from "../header";
import { Filter } from "../FilterComponent/Filter";
import { ProductCard } from "./ProductCard";
import { Footer } from "../../pages/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import {
  noTokenCommonGetRequest,
  noTokenPostRequest,
  postRequest,
} from "../../helpers";
import { Collapse } from "antd";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import toast from "react-hot-toast";
import useCookie from "../../hooks/cookie";
import { WishlistContext } from "../../contexts/WishListContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, FreeMode } from "swiper/modules";
import CouponBannerComp from "../../pages/CouponBannerComp";

export const CategoryProduct = () => {
  const navigate = useNavigate();

  // ----------------------------------scroll to tp ----------------------------------
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  const [filterList, setFilterList] = useState();
  const [segment, setSegment] = useState([]);
  const [category, setCategory] = useState("");
  const [productList, setProductList] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const params = useParams();
  const [activeText, setActiveText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [setCookie, getCookie] = useCookie();
  const [wishListData, setWishListData] = useContext(WishlistContext);

  const [details, setDetails] = useState({
    segment: [],
    category: [params?.id],
    count: 1000,
    filter: [],
  });

  const Active = {
    height: "auto",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    padding: "0 1.5rem",
  };

  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;
  useEffect(() => {
    const credentials = { ...details };
    if (token) {
      postRequest({ url: "products", cred: credentials, token: authToken })
        .then((res) => {
          if (res.data.status) {
            setProductList(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    } else {
      noTokenPostRequest({ url: "products", cred: credentials })
        .then((res) => {
          if (res.data.status) {
            // Retrieve wishlist from local storage
            const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

            // Get the product list from the response
            let products = res.data.data;

            // Update products to set inWishlist field
            products = products.map((product) => {
              return {
                ...product,
                inWishlist: wishlist.includes(product._id),
              };
            });

            // Set the updated product list
            setProductList(products);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
    noTokenCommonGetRequest({ url: "common/filter/category" })
      .then((res) => {
        if (res.data.status) {
          setFilterList(res.data.data.slice(0, 1));
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [details, updateStatus, wishListData]);

  const onChange = (key) => {};

  //  -----------------------------------------------------------------------sorted------------------------

  const sort = [
    "Recommended",
    "New",
    "Price: Low To High",
    "Price: High To Low",
  ];

  const sortProducts = (products) => {
    if (activeText === "Price: Low To High") {
      return products.sort((a, b) => a.price - b.price);
    } else if (activeText === "New") {
      return products.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (activeText === "Recommended") {
      return products.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (activeText === "Price: High To Low") {
      return products.sort((a, b) => b.price - a.price);
    }
    return products;
  };

  const sortedProducts = sortProducts(
    productList?.filter((item) => item.category?.slug === params?.id) || []
  );

  //  -----------------------------------------------------------------------sorted------------------------

  const contentStyle = {
    position: "-webkit-sticky",
    position: "sticky",
    top: "10px",
  };

  const deleteToWishList = (id) => {
    if (authToken) {
      postRequest({
        url: "wishlist/remove",
        cred: { product: id },
        token: authToken,
      })
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setUpdateStatus(!updateStatus);
            setWishListData(res?.data?.count);
            toast.success(res?.data?.msg);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    } else {
      // console.log("haa bhai chalra hai ");
      // Retrieve existing wishlist from localStorage
      let wishlist = localStorage.getItem("wishlist");
      wishlist = wishlist ? JSON.parse(wishlist) : [];

      // Remove the product ID from the wishlist
      wishlist = wishlist.filter((productId) => productId !== id);

      // Save the updated wishlist back to localStorage
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
      setUpdateStatus(!updateStatus);
    }
  };

  const addToWishList = (id) => {
    if (authToken) {
      postRequest({
        url: "wishlist/add",
        cred: { product: id },
        token: authToken,
      })
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setUpdateStatus(!updateStatus);
            setWishListData(res?.data?.count);
            toast.success(res?.data?.msg);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    } else {
      // Retrieve existing wishlist from localStorage
      let wishlist = localStorage.getItem("wishlist");
      wishlist = wishlist ? JSON.parse(wishlist) : [];
      // Add new product ID to the wishlist
      if (!wishlist.includes(id)) {
        wishlist.push(id);
      }

      // Save the updated wishlist back to localStorage
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
      setUpdateStatus(!updateStatus);
    }
  };

  // console.log(params.id, segment, "segement");
  useEffect(() => {
    const filteredProductList = productList?.filter(
      (item) => item.category?.slug === params?.id
    );

    // console.log(filteredProductList);

    if (filteredProductList?.length > 0) {
      setSegment(filteredProductList[0]?.segment);
      setCategory(filteredProductList[0]?.category?.name);
    }
  }, [productList, params?.id]); // Add dependencies accordingly
  return (
    <>
      <div className="bg-gray-100">
        <Header />

        {/* Breadcrumbs  */}
        <div className="text-sm breadcrumbs mx-5 mt-2 font-[500] font-[roboto] md:mx-5 md:mt-0  text-gray-700  capitalize">
          <ul>
            <li onClick={() => navigate("/")} role="button">
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
            </li>
            <svg
              class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <li
              className="hover:text-blue-600 md:ms-2 "
              role="button"
              onClick={() => navigate(`/product/${segment?.slug}`)}
            >
              {segment?.name || "segment"}
            </li>
            <li className="hover:text-blue-600 md:ms-2 ">
              {category || "category"}
            </li>
          </ul>
        </div>
        <div className="flex font-[roboto]">
          <div className="filter-list sm:w-1/4 min-h-full float-start ">
            {/* <Filter  /> */}
            {/* ----------------------------filter-------------------------------------- */}
            <div style={contentStyle} className=" pb-3">
              <div className="font-[roboto]">
                <div className="bg-white min-h-[64vh] pb-10 mx-3 rounded shadow">
                  <div className="text-[1.1rem] font-[500] pt-4 pb-2 px-6 text-start ">
                    Filters
                  </div>
                  <hr className="mx-5 font-[400]" />
                  <div className="mx-5 w-5/4">
                    <Collapse defaultActiveKey={["0"]} onChange={onChange}>
                      {filterList &&
                        filterList?.map((filter, index) => (
                          <Collapse.Panel
                            key={index?.toString()}
                            header={filter?.name}
                          >
                            <ul>
                              {filter?.value?.map((item, itemIndex) => (
                                <li key={itemIndex.toString()}>
                                  <p className="flex p-3">
                                    <input
                                      type="checkbox"
                                      className="self-center"
                                      onChange={(e) => {
                                        if (e?.target?.checked) {
                                          // If checkbox is checked, add the value to the state
                                          setDetails((prevDetails) => ({
                                            ...prevDetails,
                                            filter: [
                                              ...prevDetails?.filter,
                                              item?._id.toString(),
                                            ],
                                          }));
                                        } else {
                                          // If checkbox is unchecked, remove the value from the state
                                          setDetails((prevDetails) => ({
                                            ...prevDetails,
                                            filter: prevDetails.filter.filter(
                                              (value) =>
                                                value !== item?._id.toString()
                                            ),
                                          }));
                                        }
                                      }}
                                    />
                                    <span className="ml-5">{item?.title}</span>
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </Collapse.Panel>
                        ))}
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------------------------ */}
          </div>
          {/* <div className="w-10 filter-icon">
            <div className="drawer mx-2 ">
              <input
                id="my-drawer-4"
                type="checkbox"
                className="drawer-toggle"
              />
             
              <div className="drawer-content">
                <label htmlFor="my-drawer-4" className="drawer-button m-1">
                  <FaFilter className="drop-shadow-xl" />
                </label>
              </div>
              <div className="drawer-side">
                <label
                  htmlFor="my-drawer-4"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>
                <ul className="menu p-4 w-64 sm:w-80 min-h-screen bg-stone-50 text-black mt-[9rem] md:mt-[4rem] min-[1024px]:mt-[8.5rem] filter-drawer">
                  <Collapse defaultActiveKey={["1"]} onChange={onChange}>
                    {filterList &&
                      filterList.map((filter, index) => (
                        <Collapse.Panel
                          key={index.toString()}
                          header={filter.name}
                        >
                          <ul>
                            {filter.value.map((item, itemIndex) => (
                              <li key={itemIndex.toString()}>
                                <p className="flex p-3">
                                  <input
                                    type="checkbox"
                                    className="self-center"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        
                                        setDetails((prevDetails) => ({
                                          ...prevDetails,
                                          filter: [
                                            ...prevDetails.filter,
                                            item?._id.toString(),
                                          ],
                                        }));
                                      } else {
                                        
                                        setDetails((prevDetails) => ({
                                          ...prevDetails,
                                          filter: prevDetails.filter.filter(
                                            (value) =>
                                              value !== item?._id.toString()
                                          ),
                                        }));
                                      }
                                    }}
                                  />
                                  <span className="ml-5">{item.title}</span>
                                </p>
                              </li>
                            ))}
                          </ul>
                        </Collapse.Panel>
                      ))}
                  </Collapse>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="w-full pb-3 mt-0 pe-3">
            {/* <ProductCard /> */}
            <div className=" bg-white py-1 mx-[12px] sm:mx-3 min-[1440px]:mx-0 font-[roboto] capitalize max-[639px]:h-[4rem] max-[890px]:h-[6rem] max-[1439px]:h-[10rem] mb-5 rounded">
              <div className="text-[1.75rem] font-[400] px-3">
                {" "}
                {category.toLowerCase()}
              </div>
              <hr className="my-1" />
              <div className="hidden sm:flex justify-start items-center gap-8 px-3">
                <div className="">Sort by:</div>
                {sort?.map((res) => {
                  return (
                    <>
                      <div
                        className={`text-sm  transition-all duration-[300]   hover:decoration-[#8AC93D] hover:text-[#8AC93D]  hover:decoration-2 hover:underline hover:underline-offset-8 hover:font-semibold ${
                          activeText === res
                            ? "decoration-[#8AC93D] text-[#8AC93D]  decoration-2 underline underline-offset-8 font-semibold"
                            : ""
                        } `}
                        role="button"
                        onClick={() => setActiveText(res)}
                      >
                        {res}
                      </div>
                    </>
                  );
                })}
              </div>
              {filterList &&
                filterList.map((filter, index) => (
                  <div
                    key={index.toString()}
                    className="max-[890px]:hidden max-[1439px]:flex justify-start items-center gap-8 mt-3 px-3 hidden"
                  >
                    <div className="text-semibold">Filter By:</div>
                    {filter.value.map((item, itemIndex) => (
                      <div
                        key={itemIndex.toString()}
                        className="border rounded-lg shadow-md"
                        role="button"
                      >
                        <p className="flex p-3">
                          <input
                            type="checkbox"
                            className="self-center"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setDetails((prevDetails) => ({
                                  ...prevDetails,
                                  filter: [
                                    ...prevDetails.filter,
                                    item?._id.toString(),
                                  ],
                                }));
                              } else {
                                setDetails((prevDetails) => ({
                                  ...prevDetails,
                                  filter: prevDetails.filter.filter(
                                    (value) => value !== item?._id.toString()
                                  ),
                                }));
                              }
                            }}
                          />
                          <span className="ml-5 max-[1000px]:text-xs">
                            {item?.title}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
            {/* -------------------------------------------------CouponBanner---------------------------------------------------- */}
            <CouponBannerComp />
            {/* -------------------------------------------------CouponBanner---------------------------------------------------- */}
            {/* <div className="bg-white p-5 m-5"></div> */}
            <div className="grid grid-cols-2 min-[470px]:grid-cols-3 lg:grid-cols-4 gap-4 mx-[12px] min-[1440px]:mx-0">
              {sortedProducts?.map((item, index) => {
                return (
                  <>
                    <div
                      key={index + 1}
                      className="w-auto bg-white hover:shadow-xl px-2 pt-2 pb-2 cardTop max-h-96 rounded"
                    >
                      <figure
                        className="overflow-hidden h-40 sm:h-60 m-auto flex justify-center"
                        onClick={() =>
                          navigate(`/products/product/${item?.slug}`)
                        }
                        role="button"
                      >
                        {/* <Swiper
                          spaceBetween={0}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          className="z-0"
                        >
                          {item?.image?.map((res) => {
                            return (
                              <SwiperSlide>
                                <img
                                  src={
                                    res ||
                                    `https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png`
                                  }
                                  alt={item?.name}
                                  className="object-cover sm:object-cover overflow-hidden h-60 sm:h-80 w-full"
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper> */}
                        <Swiper
                          spaceBetween={0}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          className="z-[0]"
                        >
                          {item?.isVariant
                            ? item?.cakeVariant?.[0]?.image?.map((res) => [
                                <SwiperSlide key={index}>
                                  <img
                                    src={
                                      res ||
                                      "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                                    }
                                    alt={item?.name}
                                    className="object-cover sm:object-cover overflow-hidden h-60 sm:h-80 w-full"
                                  />
                                </SwiperSlide>,
                              ])
                            : item?.image?.map((res) => [
                                <SwiperSlide key={index}>
                                  <img
                                    src={
                                      res ||
                                      "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                                    }
                                    alt={item?.name}
                                    className="object-cover sm:object-cover overflow-hidden h-60 sm:h-80 w-full"
                                  />
                                </SwiperSlide>,
                              ])}
                        </Swiper>
                      </figure>
                      <div className=" z-[1] mt-1 cardBottom relative">
                        <div className="px-3">
                          <div className="flex justify-between items-center">
                            <h1 className="text-sm font-[500] text-gray-700 capitalize truncate ">
                              {item?.name?.toLowerCase()}
                            </h1>

                            <span
                              className=" p-1 sm:text-xl md:text-2xl md:p-3 text-red-500"
                              role="button"
                              onClick={() =>
                                item?.inWishlist
                                  ? deleteToWishList(item?._id)
                                  : addToWishList(item?._id)
                              }
                            >
                              {item?.inWishlist ? (
                                <IoMdHeart className="text-red-500" />
                              ) : (
                                <IoMdHeartEmpty className="text-red-500" />
                              )}
                            </span>
                          </div>
                          <div className="sm:flex justify-between mb-3">
                            <div className="flex items-center my-1">
                              <p className="text-lg font-semibold text-nowrap">
                                ₹{" "}
                                {item?.isVariant
                                  ? item?.cakeVariant?.[0]?.price
                                  : item?.price}{" "}
                                &nbsp;
                              </p>
                              {item?.mrp >
                                (item?.isVariant
                                  ? item?.cakeVariant?.[0]?.price
                                  : item?.price) && (
                                <p className="text-xs text-red-500 line-through">
                                  ₹ {item?.mrp}
                                </p>
                              )}
                            </div>
                            <div className="badge badge-success text-white p-1">
                              <strong className="m-1">
                                {" "}
                                {item?.isVariant
                                  ? item?.cakeVariant?.[0]?.rating
                                  : item?.rating}{" "}
                              </strong>
                              <IoStar />
                            </div>
                          </div>

                          <div className="text-xs">
                            {item?.isVariant ? (
                              item?.cakeVariant?.[0]?.stock > 0 ? (
                                <p className="text-gray-500">
                                  Stock{" "}
                                  <strong className="text-black">
                                    {item?.cakeVariant[0]?.stock}
                                  </strong>{" "}
                                </p>
                              ) : (
                                <p className="text-red-500">Out of Stock</p>
                              )
                            ) : item?.stock > 0 ? (
                              <p className="text-gray-500">
                                Stock{" "}
                                <strong className="text-black">
                                  {item?.stock}
                                </strong>{" "}
                              </p>
                            ) : (
                              <p className="text-red-500">Out of Stock</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryProduct;
