import React, { useState } from 'react'
import Logo from "../assets/images/logo-gw.png"
import Logod from "../assets/images/logo-detail.png"
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { noTokenPostRequest } from '../helpers'

const Forgetpassword = () => {

    const navigate = useNavigate();

    const [loginCredentials, setLoginCredentials] = useState({
        email: "",
    })

    const handleLogin = (e) => {
        const { name, value } = e.target;
        setLoginCredentials({
            ...loginCredentials,
            [name]: value,
        })
    }

    const signin = (e) => {
        e.preventDefault();
        let credentials = { ...loginCredentials };
        noTokenPostRequest({ url: 'auth/forgot-password', cred: credentials })
            .then((res) => {
                if (res?.data?.status) {
                    toast.success(res.data.msg)
                    setLoginCredentials({
                        email: "",
                    })
                    // setTimeout(() => {
                    //     navigate('/')
                    // },2000) 
                } else {
                    toast.error(res?.data?.data?.msg)
                }
            })
            .catch((AxiosError) => {
                toast.error(AxiosError.response.data.msg)
            })

    }

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={true}
            />
            <div style={{ backgroundImage: `url('./assets/pots--bg.jpg')`, backgroundSize: "cover", height: "100vh" }}>
                <div className='bg-white p-2 flex items-center justify-center w-full fixed z-10'>
                    <div className='flex cursor-pointer' onClick={() => navigate('/')}>
                        <img className="h-10 mx-2" src={Logo} alt="Logo" />
                        <img className="h-10" src={Logod} alt="Logo" />
                    </div>
                </div>
                <div className='h-screen flex items-center justify-center'>
                    <div className=' bg-white w-11/12 sm:w-6/12 lg:w-5/12 xl:w-4/12 mt-12 flex items-center justify-center rounded-md place-self-center'>
                        <div className='w-4/5'>
                            <div className='my-5'>
                                <p className='text-2xl text-center'>Set New Password</p>
                                {/* <p className='text-md'>Don't have an account? <button onClick={() =>
                                    navigate('/signup')} className='text-lime-400 font-medium'>Sign Up</button></p> */}
                            </div>
                            <form onSubmit={signin}>
                                <label htmlFor="email" className='mt-2'>Your Email Id</label>
                                <input
                                    type="email"
                                    className='w-full h-10 my-2 border border-inherit focus:outline-none p-2'
                                    name="email"
                                    placeholder='Email'
                                    value={loginCredentials.email}
                                    onChange={handleLogin}
                                    required
                                />
                                <button className='h-10 mt-2 mb-6 w-full text-center bg-lime-200'>
                                    <span type="submit" className='font-medium'>Continue</span>
                                </button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgetpassword