import React, { useEffect, useState } from "react";
import { Footer } from "../../pages/Footer";
import Header from "../header";
import { useParams } from "react-router-dom";
import { getRequest } from "../../helpers";

const StaticPages = () => {
  const params = useParams();

  const [page, setPage] = useState();
  window.scrollTo(0, 0);
  useEffect(() => {
    {
      getRequest({ url: `static-page/${params.id}` })
        .then((res) => {
          if (res.data.status) {
            setPage(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [params.id]);

  return (
    <>
      <Header />

      <div className="font-[roboto]">
        <p className="text-center text-[#042100] text-4xl pt-4 font-bold underline underline-offset-8 decoration-[#BCCBB1]">
          {page?.title || "Page Title"}
        </p>
        <div className="flex items-center justify-center py-5 my-5">
          <div
            className="w-10/12"
            dangerouslySetInnerHTML={{ __html: page?.content }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StaticPages;
