import React, { useContext, useEffect, useState } from "react";
import useCookie from "../../hooks/cookie";
import { getRequest, postRequest, request } from "../../helpers";
import toast, { Toaster } from "react-hot-toast";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";

const MyOrders = () => {
  const [cartDataStatus, setCartDataStatus] = useState(false);
  const [data, setData] = useContext(CartContext);
  const [billingDetails, setBillingDetails] = useState();
  const [cartData, setCartData] = useState([]);
  const [profileDetails, setProfileDetails] = useState();

  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();
  const [billData, setBillData] = useState({
    type: "cart",
    paymentMethod: "cod",
    shipping: "standard",
  });
  const [orderList, setOrderList] = useState([]);

  const navigate = useNavigate();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  useEffect(() => {
    if (token) {
      getRequest({ url: "profile", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setProfileDetails(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
      getRequest({ url: "cart", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setData(res.data.count);
            setCartData(res?.data?.data);
            setLoading(false);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
    postRequest({ url: "order/list", token: authToken })
      .then((res) => {
        if (res.data.status) {
          setOrderList(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [cartDataStatus, data]);

  useEffect(() => {
    if (token) {
      const credentials = { ...billData };
      postRequest({ url: "billing/get", token: authToken, cred: credentials })
        .then((res) => {
          if (res.data.status) {
            setBillingDetails(res?.data?.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [cartDataStatus, billData]);

  const textCut = {
    textDecoration: "line-through",
  };
  const deleteProduct = (id, quantity) => {
    const credentials = { product: id, quantity: -quantity };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        toast(res.data.msg);
        setCartDataStatus(!cartDataStatus);
      }
    });
  };
  const incQuantity = (id) => {
    const credentials = { product: id, quantity: 1 };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        // toast(res.data.msg);
        setCartDataStatus(!cartDataStatus);
      }
    });
  };
  const decQuantity = (id) => {
    const credentials = { product: id, quantity: -1 };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        // toast(res.data.msg);
        setCartDataStatus(!cartDataStatus);
      }
    });
  };

  // ---------------------------------Address-----------------------------------------

  const params = useParams();
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      {/* <Header /> */}
      <div className=" font-[roboto]">
        {orderList.length ? (
          <>
            {loading ? (
              <span className="loading loading-ring loading-lg"></span>
            ) : (
              <div className="w-full m-auto">
                <div className="my-2 font-semibold">Order List</div>
                <div className=" sm:h-[50vh]  overflow-y-scroll scrollbar">
                  {orderList?.map((res) => {
                    return (
                      <>
                        <div className="rounded border-2 md:border-0 shadow-xl p-5 md:mx-5 my-2 w-full md:w-[60vw]">
                          <div className="md:flex justify-between items-center ">
                            <div className="flex justify-between items-center md:block">
                              <div className="text-xs font-semibold text-gray-600 truncate w-40">
                                Product Name
                              </div>
                              <span className="capitalize">
                                {res?.productDetails?.[0]?.name
                                  .toLowerCase()
                                  .slice(0, 12)}
                              </span>
                            </div>
                            <div className="flex justify-between items-center md:block capitalize">
                              <div className="text-xs font-semibold text-gray-600 text-center">
                                {res?.paymentMethod === "online"
                                  ? "Payment Status"
                                  : "Amount To Pay"}
                              </div>
                              {res?.paymentMethod === "online"
                                ? res?.paymentStatus.toLowerCase()
                                : res?.totalAmountToPay}

                              {/* paymentStatus */}
                            </div>
                            <div className="flex justify-between items-center md:block">
                              <div className="text-xs font-semibold text-gray-600 text-center">
                                Order Id
                              </div>
                              {res?.orderId}
                            </div>
                            {/* <div className="flex justify-between items-center md:block">
                              <div className="text-xs font-semibold text-gray-600 text-center">
                                Delivery
                              </div>
                              <span className={`capitalize`}>
                                {res?.status}
                              </span>
                            </div> */}
                            <div className="flex justify-between items-center md:block">
                              <div className="text-xs font-semibold text-gray-600 text-center">
                                Action
                              </div>
                              <span
                                className="capitalize btn btn-sm"
                                role="button"
                                onClick={() => {
                                  navigate(
                                    `/order/${res?.orderId.toLowerCase()}`
                                  );
                                }}
                              >
                                View
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className=" px-5 m-auto lg:w-4/5">
              <div className="bg-white p-5">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[10vh] w-[10vw] m-auto my-[2rem] lg:my-[3rem] text-gray-400"
                    fill="none"
                    viewBox="0 0 22 22"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <div className="text-xl lg:text-3xl text-center font-semibold">
                  Your Cart is Empty, filled with treasures of your choice
                </div>
                <div className="flex justify-evenly my-7">
                  <div
                    className="text-bold btn lg:btn-lg bg-[#D8E7CC] hover:bg-[#d0e2c2] border-0 text-[#042100]"
                    onClick={(e) => navigate("/")}
                    role="button"
                  >
                    Continue Shopping
                  </div>
                  {/* <div
                  className="text-bold text-blue-600 btn "
                  onClick={(e) => navigate("/")}
                  role="button"
                >
                  Go Back to Home Page
                </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default MyOrders;
