import axios from "axios";
import Cookies from "js-cookie";

// FETCHING TOKEN FROM COOKIE
export const token = Cookies.get("gamlawala-auth");

export const request = async (props) => {
  const response = await axios?.[props?.method](
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    props.cred,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};

export const getRequest = async (props) => {
  const response = await axios?.get(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};

export const postRequest = async (props) => {
  const response = await axios?.post(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    props.cred,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};
export const postRequestWithHeaders = async (props) => {
  const response = await axios?.post(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    props.cred,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
        "x-razorpay-signature": props?.signature, // for razorpay
      },
      withCredentials: true,
    }
  );
  return response;
};
export const putRequest = async (props) => {
  const response = await axios?.put(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    props.cred,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};

export const deleteRequest = async (props) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    {
      headers: {
        Authorization: `bearer ${props?.token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};

export const noTokenGetRequest = async (props) => {
  const response = await axios.get(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const noTokenCommonGetRequest = async (props) => {
  const response = await axios.get(
    `${process.env.REACT_APP_WEB_COMMON_API_BASE_URL}${props.url}`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const noTokenPostRequest = async (props) => {
  const response = await axios.post(
    `${process.env.REACT_APP_WEB_API_BASE_URL}${props.url}`,
    props.cred,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const noTokenCommonPostRequest = async (props) => {
  const response = await axios.post(
    `${process.env.REACT_APP_WEB_COMMON_API_BASE_URL}${props.url}`,
    props.cred,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const addToCart = async (props) => {
  const response = await axios.post(
    `${process.env.REACT_APP_WEB_API_BASE_URL + "web/cart"}`,
    props.cred,
    {
      headers: {
        Authorization: `${token}`,
      },
      withCredentials: true,
    }
  );
  return response;
};
