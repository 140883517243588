import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import fullLogo from "../assets/images/full-img.png";
import Google from "../assets/images/socials/google.png";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { noTokenPostRequest, postRequest } from "../helpers";
import { Input } from "antd";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useCookie from "../hooks/cookie";

const Signup = () => {
  const navigate = useNavigate();
  const [setCookie, getCookie] = useCookie();

  const [profile, setProfile] = useState([]);
  const [user, setUser] = useState([]);

  const login = useGoogleLogin({
    onSuccess: setUser,
    onError: (error) => console.log("Login Failed:", error),
  });
  // console.log(user, "setUser");

  useEffect(() => {
    const fetchUserInfo = async (accessToken) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        );
        const token = res?.data?.data?.token;
        // console.log("tokennnnnn ", token);
        // setCookie("gamlawala-auth", token, 1);
        const signupResponse = await postRequest({
          url: "auth/google-sign-up",
          cred: { token: accessToken },
        });
        if (signupResponse.data.status) {
          // console.log(signupResponse.data, "signup res");
          setCookie("gamlawala-auth", signupResponse?.data?.data?.token, 1);
          postRequest({
            url: "wishlist/add-multiple",
            cred: {
              product: JSON.parse(localStorage.getItem("wishlist")) || [],
            },
            token: signupResponse?.data?.data?.token,
          })
            .then((res) => {
              // Handle the response as needed
              console.log(res.data);

              // Clear the localStorage wishlist after a successful request
              localStorage.removeItem("wishlist");
            })
            .catch((err) => {
              // Handle the error as needed
              console.error(err);
            });
          navigate("/");
        } else {
          console.log("else block", signupResponse.data.msg);
        }
      } catch (err) {
        handleError(err);
      }
    };
    if (user) {
      fetchUserInfo(user.access_token);
    }
    // if (user) {
    //   axios
    //     .get(
    //       `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${user.access_token}`,
    //           Accept: "application/json",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       setCookie("gamlawala-auth", res?.data?.data?.token, 1);
    //       //   setProfile(res.data);
    //       postRequest({
    //         url: "auth/google-sign-up",
    //         cred: { token: user?.access_token },
    //       })
    //         .then((res) => {
    //           if (res.data.status) {
    //             navigate("/");
    //           } else {
    //             console.log("else block", res.data.msg);
    //           }
    //         })
    //         .catch((res) => {
    //           if (res?.response?.data?.msg === "User already exists!") {
    //             navigate("/signin");
    //             setTimeout(() => {
    //               toast.error(res?.response?.data?.msg);
    //             }, 1000);
    //           } else {
    //             toast.error(res?.response?.data?.msg);
    //           }
    //           console.log(res);
    //         });
    //     })
    //     .catch((err) => console.log(err));
    // }
  }, [user, navigate]);

  const handleError = (err) => {
    const errorMessage = err?.response?.data?.msg || "An error occurred";
    if (errorMessage === "User already exists!") {
      navigate("/signin");
      setTimeout(() => {
        toast.error(errorMessage);
      }, 1000);
    } else {
      console.log(err);
    }
  };

  const [signupCredentials, setSignupcredentials] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleSignup = (e) => {
    const { name, value } = e.target;
    setSignupcredentials({
      ...signupCredentials,
      [name]: value,
    });
  };

  const signup = (e) => {
    e.preventDefault();
    console.log(signupCredentials);

    const credentials = { ...signupCredentials };

    noTokenPostRequest({ url: "auth/sign-up", cred: credentials })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.msg);

          setSignupcredentials({
            name: "",
            email: "",
            mobile: "",
            password: "",
          });
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div
        style={{
          backgroundImage: `url('./assets/pots--bg.jpg')`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="bg-white p-2 flex items-center justify-center w-full fixed z-10">
          <div className="flex cursor-pointer" onClick={() => navigate("/")}>
            {/* <img className="h-10 mx-2" src={Logo} alt="Logo" /> */}
            <img className="h-12" src={fullLogo} alt="Logo" />
          </div>
        </div>
        <form onSubmit={signup}>
          <div className="flex items-center justify-center">
            <div className="bg-white w-10/12 sm:w-6/12 lg:w-5/12 xl:w-4/12 mt-[100px] mb-20 flex items-center justify-center rounded-md place-self-center">
              <div className="w-4/5">
                <div className="my-5">
                  <p className="text-2xl">Sign Up</p>
                  <p className="text-md">
                    Already have an account?{" "}
                    <button
                      onClick={() => navigate("/signin")}
                      className="text-lime-400 font-medium"
                    >
                      Sign In
                    </button>
                  </p>
                </div>
                <label htmlFor="name" className="mt-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={signupCredentials.name}
                  onChange={handleSignup}
                  required
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2 bg-white rounded-md"
                />
                <label htmlFor="email" className="mt-2">
                  Email id
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={signupCredentials.email}
                  onChange={handleSignup}
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2 bg-white rounded-md"
                  required
                />
                <label htmlFor="email" className="mt-2">
                  Phone Number
                </label>
                <input
                  type="number"
                  name="mobile"
                  placeholder="Phone Number"
                  value={signupCredentials.mobile}
                  onChange={handleSignup}
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2 bg-white rounded-md"
                  required
                />
                <label htmlFor="password" className="mt-2">
                  Password
                </label>
                <Input.Password
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={signupCredentials.password}
                  onChange={handleSignup}
                  className="w-full h-10 my-2 border border-inherit focus:outline-none p-2"
                  required
                />

                {/* <label htmlFor="confirmPassword" className='mt-2'>Confirm Password</label>
                                <input
                                    type='password'
                                    name='confirmPassword'
                                    placeholder='Confirm Password'
                                    value={signupCredentials.confirmPassword}
                                    onChange={handleSignup}
                                    className='w-full h-10 my-2 border border-inherit focus:outline-none p-2'
                                    required
                                /> */}
                <button className="h-10 my-2 w-full text-center bg-lime-200">
                  <span type="submit" className="font-medium">
                    SIGN UP
                  </span>
                </button>
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-full h-px my-6 bg-gray-200 border-0 dark:bg-gray-500" />
                  <span
                    type="submit"
                    className="absolute px-3 font-medium text-gray-500 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900 py-1 rounded"
                  >
                    or sign up
                  </span>
                </div>
                {/* <GoogleLogin /> */}
                <div
                  className="flex items-center justify-center mb-4 cursor-pointer"
                  role="button"
                  onClick={() => login()}
                >
                  <div className="border border-gray-500 rounded">
                    <div className="flex my-2 justify-center mx-4">
                      <img src={Google} alt="G" className="h-5 mr-2 mt-1" />
                      <p className="text-lg font-medium">Google</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Signup;
