import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, InputNumber } from "antd";
import Logo from "../assets/images/logo-gw.png";
import Logod from "../assets/images/logo-detail.png";
import Insta from "../assets/images/socials/instagram.png";
import Facebook from "../assets/images/socials/facebook.png";
import Twitter from "../assets/images/socials/twitter.png";
import Youtube from "../assets/images/socials/youtube.png";
import { GrLocation } from "react-icons/gr";
import { MdLockOpen, MdOutlineMailOutline } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { getRequest, postRequest } from "../helpers";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import toast from "react-hot-toast";
export const Footer = () => {
  const navigate = useNavigate();

  const [staticPagesList, setStaticPagesList] = useState([]);
  const [segment, setSegment] = useState([]);
  const [lineClamp, setLineClamp] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobile: "",
    queries: "",
  });
  const [form] = Form.useForm();

  // -------------------------modal functions------------------------
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    // form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (values) => {
    setIsModalOpen(false);
    {
      postRequest({
        url: "/request-callback",
        cred: {
          name: values.name,
          mobile: values.mobile,
          queries: values.queries,
        },
      })
        .then((res) => {
          if (res.data.status) {
            toast.success(res.data.msg);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log("static Pages ", staticPagesList);
  useEffect(() => {
    getRequest({ url: "static-page" })
      .then((res) => {
        if (res.data.status) {
          setStaticPagesList(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    getRequest({ url: "segment/with-category" })
      .then((res) => {
        if (res.data.status) {
          setSegment(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, []);

  const socilimg = [
    {
      img: Insta,
      link: "https://www.instagram.com/invites/contact/?igsh=y71k3qg55jfn&utm_content=l709fyk",
    },
    {
      img: Facebook,
      link: "https://www.facebook.com/share/e6c9aFjYAgooqRqK/?mibextid=qi2Omg",
    },
    { img: Twitter, link: "" },
    { img: Youtube, link: "" },
  ];
  const paymentImg = [
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Google_Pay_Logo.svg/2560px-Google_Pay_Logo.svg.png",
    "https://pngimg.com/d/mastercard_PNG16.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Cirrus_logo.svg/1280px-Cirrus_logo.svg.png",
    "https://purepng.com/public/uploads/large/purepng.com-visa-logologobrand-logoiconslogos-251519938794uqvcz.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/2560px-UnionPay_logo.svg.png",
  ];

  const footerLinks = [
    {
      category: "Privacy Policy",
      links: staticPagesList,
    },
    {
      category: "About Company",
      links: [
        { title: "About Us", slug: "about-us" },
        { title: "Our Team", slug: "our-team" },
        { title: "Careers", slug: "careers" },
        { title: "Testimonial", slug: "testimonial" },
        { title: "News Room", slug: "news-room" },
      ],
    },
    {
      category: "Business",
      links: [
        { title: "Decoration Services", slug: "decoration-services" },
        { title: "Corporate Service", slug: "corporate-service" },
        { title: "Affiliate Program", slug: "affiliate-program" },
        { title: "Retail Stores", slug: "retail-stores" },
        { title: "Franchise", slug: "franchise" },
      ],
    },
    {
      category: "Need Help",
      links: [
        { title: "Contact Us", slug: "contact-us" },
        { title: "FAQ's", slug: "faqs" },
      ],
    },
  ];
  const staticPage = (item) => {
    // if (item?._id) {
    navigate(`/legal-page/${item?.slug}`);
    // }
  };

  return (
    <>
      <div className="h-50 w-full border-2 border-red flex flex-col items-center justify-between bg-[#d8e7cc] text-black">
        <div className="flex flex-shrink-0 justify-between items-start w-full border bg-gray-200 shadow-inner p-3 gap-4 sm:p-8">
          <div className="flex-1 flex flex-col justify-center md:items-center items-baseline ">
            <div>
              <MdLockOpen size={30} />
            </div>
            <span className="text-xs font-[500] text-black">
              100% Safe & Secure Payments
            </span>
            <span className="text-gray-500 text-xs ">
              Pay using secure payment methods
            </span>
          </div>
          <div className="flex-1 flex flex-col justify-center md:items-center items-baseline">
            <div>
              <BiSolidPhoneCall size={30} />
            </div>

            <span className="text-xs font-[500] text-black">
              Dedicated Help Center
            </span>
            <span className="animate-pulse">
              <Button type="primary" ghost onClick={showModal}>
                Request A Call
              </Button>
            </span>
          </div>
          <div className="flex-1 flex flex-col justify-center md:items-center items-baseline">
            <div className="flex gap-2">
              <DiAndroid
                size={30}
                className="border rounded-full bg-lime-500 text-white p-1"
              />
              <FaApple
                size={30}
                className="border rounded-full bg-gray-500 text-white p-1"
              />
            </div>
            <span className="text-xs font-[500] text-black">
              Shop on the go
            </span>
            <span className="text-gray-500 text-xs">Download mobile app</span>
          </div>
        </div>
        {/* <div className="bg-gray-200 w-full">section</div> */}

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 w-full md:w-4/5 justify-center m-auto ">
          <div className="w-full py-3 px-5 grid cols-1 min-[320px]:grid-cols-2 min-[320px]:gap-3 sm:grid-cols-3 md:grid-cols-5 md:gap-4 m-auto sm:col-span-3">
            {staticPagesList?.map((res, index) => {
              return (
                <>
                  {staticPagesList.length - 1 === index && (
                    <ul className="text-xs space-y-1">
                      <li className="text-xs font-[500] text-black">
                        Services
                      </li>
                      {segment.map((res) => {
                        return (
                          <>
                            <li
                              className="capitalize"
                              role="button"
                              onClick={() => {
                                navigate(`/product/${res?.slug}`);
                              }}
                            >
                              {res?.name}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  )}
                  <ul className="text-xs space-y-1">
                    <li className="text-xs font-[500] text-black capitalize">
                      {res?.name?.toLowerCase()}
                    </li>
                    {res?.name.toLowerCase() === "need help?" && (
                      <li role="button" onClick={() => navigate("/contactUs")}>
                        Contact Us
                      </li>
                    )}
                    {res?.pages?.map((li) => {
                      return (
                        <li
                          onClick={() => staticPage(li)}
                          role="button"
                          className="capitalize"
                        >
                          {li?.title?.toLowerCase()}
                        </li>
                      );
                    })}
                  </ul>
                </>
              );
            })}
            {/* <ul className="text-xs space-y-1">
              <li className="text-xs font-[500] text-black">About Company</li>
              <li
                className=""
                role="button"
                onClick={() => navigate("/legal-page/aboutus")}
              >
                About Us
              </li>
              <li
                className=""
                role="button"
                onClick={() => navigate("/legal-page/servicesbusiness")}
              >
                Services
              </li>
            </ul> */}
            {/* <ul className="text-xs space-y-1">
              <li className="text-xs font-[500] text-black">Business</li>
              {segment.map((res) => {
                return (
                  <>
                    <li
                      className="capitalize"
                      role="button"
                      onClick={() => {
                        navigate(`/product/${res?.slug}`);
                      }}
                    >
                      {res?.name}
                    </li>
                  </>
                );
              })}
            </ul> */}

            {/* <ul className="text-xs space-y-1">
              <li className="text-xs font-[500] text-black">Need Help?</li>
              <li
                className=""
                role="button"
                onClick={() => navigate("/legal-page/faq")}
              >
                FAQ
              </li>
              <li
                className=""
                role="button"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </li>
            </ul> */}
          </div>
          <div className="m-auto px-3">
            <h2 className="text-sm font-[500]">Subscribe</h2>
            <div className="flex gap-0 overflow-hidden rounded my-1">
              <input
                className="bg-white p-2 w-auto md:w-[70%]"
                type="text"
                placeholder="Email"
              />
              <button className="p-2 bg-[#e87325] rounded-r text-white">
                submit
              </button>
            </div>
            <p className="text-sm tracking-tighter ">
              Get updates on promotions and offers coupons.
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="w-full m-auto bg-gray-200 py-2 overflow-hidden">
          <hr />
          <p
            className={`text-[10px] text-center   font-[500] ${
              !lineClamp ? "line-clamp-2" : ""
            }`}
          >
            Company Name: Gamlewala Private Limited | Regd. Office Address: Plot
            No. 75P, Sector-44, lucknow Uttar Pradesh, India Mobile No.:
            +919695832010 | support@gamlewala.in
            {/* | Grievance Resolution Officer Name: Mr. Rahul Mamgain */}
          </p>
          <p
            className="text-orange-500 text-end mx-5 mb-2 min-[548px]:hidden"
            role="button"
            onClick={() => setLineClamp(!lineClamp)}
          >
            {!lineClamp ? "Read More" : "Read Less"}
          </p>
        </div>
      </div>
      {/* -------------------------------------------------Modal------------------------------------------------------------- */}
      <Modal
        title="Call Back Request Form"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input your Mobile No.!",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Query"
            name="queries"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" ghost className="mx-3">
              Submit
            </Button>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
              }}
            >
              {" "}
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* -------------------------------------------------Modal------------------------------------------------------------- */}

      {/* ----------------------------------------------------------- bottom footer ------------------------------------------------------ */}
      <div className="bg-[#222]">
        <div className="grid grid-cols-1 text-xs sm:grid-cols-2 md:grid-cols-3 w-full gap-2 px-10 py-2 sm:items-center ms:justify-center flex-shrink-0 md:w-4/5 m-auto text-white">
          <div className="flex-1">© Gamlewala. All rights reserved.</div>
          <div className="flex flex-1 sm:justify-center text-white">
            <div className="flex items-center ">
              <span className="text-nowrap text-center ">Keep in touch </span>
              {/* --------------------------------------------------- social images mapping ----------------------------------------- */}
              <div className="flex items-center w-full">
                {socilimg?.map((res, index) => (
                  <a href={res?.link}>
                    <img
                      key={index} // Use a unique identifier if available
                      className="h-4 w-4 m-2 md:m-1 cursor-pointer"
                      src={res.img}
                      alt={res.img.toString()}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          {/* --------------------------------------------------- payment images mapping ----------------------------------------- */}
          <div className="flex md:justify-end items-center gap-2  overflow-hidden ">
            {paymentImg.map((res) => {
              return (
                <div className=" rounded-md">
                  <img
                    className="h-4 cursor-pointer object-cover border bg-white"
                    src={res}
                    alt="img"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
