import React, { useEffect, useState } from "react";
import Header from "../header";
import { Footer } from "../../pages/Footer";
import { useNavigate, useParams } from "react-router-dom";
import useCookie from "../../hooks/cookie";
import { getRequest, postRequest, putRequest } from "../../helpers";
import toast from "react-hot-toast";
import { Space, Table, Tag } from "antd";

const OrderDetails = () => {
  const [setCookie, getCookie] = useCookie();
  const [orderDetails, setOrderDetails] = useState([]);
  const [modal, setModal] = useState({
    status: false,
    id: "",
    orderId: "",
    variantId: "",
    uniqueId: "",
  });
  const [update, setUpdate] = useState(false);
  const authToken = getCookie("gamlawala-auth");
  const [statusData, setStatusData] = useState({
    status: false,
    id: "",
  });
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;
  const params = useParams();
  const navigate = useNavigate();

  if (!token) {
    navigate("/signin");
  }
  useEffect(() => {
    getRequest({
      url: `order/details/${params?.id.toUpperCase()}`,
      token: authToken,
    })
      .then((res) => {
        if (res.data.status) {
          setOrderDetails(res?.data?.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [update]);
  const cancelOrder = () => {
    const creds = {
      id: modal?.orderId,
      productId: modal?.id,
      variantId: modal?.variantId,
      uniqueId: modal?.uniqueId,
      reason: "",
    };
    setModal({ status: false });
    putRequest({
      url: `order/cancel`,
      cred: creds,
      token: authToken,
    })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.msg);
          setUpdate(!update);
        } else {
          toast.error("An error occurred. Please try again.");
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
          console.log(err.response.data.msg);
        } else {
          toast.error("An error occurred. Please try again.");
          console.log(err);
        }
      });
  };
  // -----------------------------------------------------Table----------------------------------------

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Unit Price",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Quantity",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Total Price",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a>Invite {record.name}</a>
          <a>Delete</a>
        </Space>
      ),
    },
  ];

  // --------------------------------------------Table Data --------------------------------------------

  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];

  return (
    <>
      <Header />
      <div className="bg-gray-50 pb-5 w-full font-[roboto]">
        {/* ---------------------------------------breadcrumbs-------------------------------------------- */}

        <div className="text-sm breadcrumbs mx-5 mt-2 font-[500] font-[roboto] md:mx-5 md:mt-0  text-gray-700  capitalize">
          <ul>
            <li onClick={() => navigate("/")} role="button">
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
            </li>
            <svg
              class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <li
              className="hover:text-blue-600 md:ms-2 capitalize"
              role="button"
              onClick={() => {
                navigate("/account/my-orders");
              }}
            >
              My Account
            </li>
            <li
              className="hover:text-blue-600 md:ms-2"
              role="button"
              onClick={() => {
                navigate("/account/my-orders");
              }}
            >
              My Orders
            </li>
            <li className="hover:text-blue-600 md:ms-2 ">Order Details</li>
          </ul>
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-4">
          <div className="col-span-3 px-5">
            <div className="bg-white rounded-lg shadow-xl p-5 my-5 w-full mx-3 overflow-x-scroll scrollbar">
              <div className="flex justify-between items-center max-[400px]:w-[20rem]">
                <div className="capitalize">
                  <span>User Name </span>
                  <div className="font-semibold">
                    {orderDetails?.userId?.name}
                  </div>
                </div>
                <div className="">
                  <span>Order Id </span>
                  <div className="font-semibold">{orderDetails?.orderId}</div>
                </div>
                <div className="capitalize">
                  <span>Payment Status </span>
                  <div className="font-semibold">
                    {orderDetails?.paymentStatus}
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-lg shadow-xl p-5  bg-white my-5 w-full mx-3 overflow-x-scroll  scrollbar">
              <div className="flex justify-between items-center">
                <div className="headeing text-lg font-semibold">
                  Product List
                </div>
              </div>
              {/* <div className="t-head hidden">
                <div className="grid grid-cols-6 justify-between items-center font-semibold text-sm mt-5 mb-3">
                  <div className="font-semibold">
                    <span>S No.</span>
                  </div>
                  <div className=" font-semibold me-2">
                    <span>Product Name</span>
                  </div>
                  <div className="font-semibold">
                    <span>Unit Price</span>
                  </div>
                  <div className="font-semibold">
                    <span>Quantity</span>
                  </div>
                  <div className="font-semibold">
                    <span>Price</span>
                  </div>
                  <div className="font-semibold">
                    <span>Action</span>
                  </div>
                </div>
                <hr />
                {orderDetails?.productDetails?.map((res, index) => {
                  return (
                    <>
                      <div className="grid grid-cols-6 justify-between items-center font-semibold text-sm mt-5 mb-3">
                        <div className="">
                          <span>{index + 1}</span>
                        </div>
                        <div className="me-2">
                          <span>{res?.name}</span>
                        </div>
                        <div className="">
                          <span>{res?.price}</span>
                        </div>
                        <div className="">
                          <span>{res?.quantity}</span>
                        </div>
                        <div className="">
                          <span>{res?.price * res?.quantity}</span>
                        </div>
                        <div className="overflow-hidden">
                          <div
                            className="btn m-2"
                            onClick={() =>
                              setStatusData({ status: true, id: index })
                            }
                          >
                            View
                          </div>
                          <button
                            className={` m-2 btn transition-all hover:shadow-xl text-nowrap ${
                              res?.status === "cancelled" ||
                              res?.status === "delivered"
                                ? "btn-disabled"
                                : ""
                            }`}
                            onClick={() => {
                              setModal({ status: true, id: res?._id });
                            }}
                          >
                            Cancel Order
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
              <div className="t-head max-[720px]:w-[50rem]">
                <div className="grid grid-cols-12 justify-between items-center font-semibold text-sm mt-5 mb-3 text-center">
                  <div className="font-semibold">
                    <span>S No.</span>
                  </div>
                  <div className=" font-semibold me-2 col-span-2">
                    <span>Product Name</span>
                  </div>
                  <div className="font-semibold col-span-2">
                    <span>Unit Price</span>
                  </div>
                  <div className="font-semibold col-span-2">
                    <span>Quantity</span>
                  </div>
                  <div className="font-semibold">
                    <span>Price</span>
                  </div>
                  <div className="font-semibold">
                    <span>Status</span>
                  </div>
                  <div className="font-semibold col-span-3">
                    <span>Action</span>
                  </div>
                </div>
                <hr />
                {orderDetails?.productDetails?.map((res, index) => {
                  return (
                    <>
                      <div className="grid grid-cols-12 justify-between items-center font-semibold text-sm mt-5 mb-3 text-center">
                        <div className="">
                          <span>{index + 1}</span>
                        </div>
                        <div className="me-2 col-span-2">
                          <span>{res?.name}</span>
                        </div>
                        <div className=" col-span-2">
                          <span>{res?.price}</span>
                        </div>
                        <div className=" col-span-2">
                          <span>{res?.quantity}</span>
                        </div>
                        <div className="">
                          <span>{res?.price * res?.quantity}</span>
                        </div>
                        <div className=" capitalize">
                          <span>{res?.status?.toLowerCase()}</span>
                        </div>
                        <div className="overflow-hidden col-span-3 flex">
                          <div
                            className="btn btn-sm m-2"
                            onClick={() =>
                              setStatusData({ status: true, id: index })
                            }
                          >
                            View
                          </div>
                          <button
                            className={` m-2 btn btn-sm transition-all hover:shadow-xl text-nowrap ${
                              res?.status === "cancelled" ||
                              res?.status === "delivered"
                                ? "btn-disabled"
                                : ""
                            }`}
                            onClick={() => {
                              setModal({
                                status: true,
                                id: orderDetails?.productDetails?.[0]
                                  ?.productId,
                                orderId: orderDetails?._id,
                                uniqueId:
                                  orderDetails?.productDetails?.[0]?.uniqueId,
                                variantId:
                                  orderDetails?.productDetails?.[0]?.variantId,
                              });
                            }}
                          >
                            Cancel Order
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <Table columns={columns} dataSource={data} /> */}
            </div>
          </div>
          {/* {console.log(orderDetails?.productDetails, "orderDetails")} */}
          {/* ------------------------------------------Status--------------------------------------------------- */}

          <div className={`${statusData.status ? "block" : "hidden"}`}>
            <div className="text-lg font-semibold text-center my-5">
              Status of Product ({statusData?.id + 1})
            </div>
            <ul className=" timeline timeline-vertical transition-all mt-5">
              <li>
                <div className="timeline-start timeline-box bg-white  ">
                  Order Confirm
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`w-5 h-5 ${
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "cancelled" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "pending"
                        ? "text-lime-500"
                        : "text-gray-200"
                    } `}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {console.log(
                  "status",
                  orderDetails?.productDetails?.[statusData.id]?.status !==
                    "dispatched",
                  orderDetails?.productDetails?.[statusData.id]?.status
                )}
                <hr
                  className={`w-5 h-5 ${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending"
                      ? "bg-lime-400"
                      : ""
                  } `}
                />
              </li>
              <li>
                <hr
                  className={`w-5 h-5 ${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "confirmed"
                      ? "bg-lime-400"
                      : ""
                  } `}
                />
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`w-5 h-5 ${
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "cancelled" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "pending" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "confirmed"
                        ? "text-lime-400"
                        : "text-gray-200"
                    } `}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box bg-white">
                  Order Processed
                </div>
                <hr
                  className={`${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "confirmed"
                      ? "bg-lime-400"
                      : "bg-gray-200"
                  } `}
                />
              </li>
              <li>
                <hr
                  className={`${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "confirmed" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "processed"
                      ? "bg-lime-400"
                      : "bg-gray-200"
                  } `}
                />
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`w-5 h-5 ${
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "cancelled" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "pending" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "confirmed" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "processed"
                        ? "text-lime-400"
                        : "text-gray-200"
                    } `}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-start timeline-box bg-white  ">
                  Order Dispatched
                </div>
                <hr
                  className={`${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "confirmed" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "processed"
                      ? "bg-lime-400"
                      : "bg-gray-200"
                  } `}
                />
              </li>
              <li>
                <hr
                  className={`${
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "cancelled" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "pending" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "confirmed" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "processed" &&
                    orderDetails?.productDetails?.[statusData.id]?.status !==
                      "dispatched"
                      ? "bg-lime-400"
                      : "bg-gray-200"
                  } `}
                />
                <div className="timeline-end timeline-box bg-white">
                  Delivered
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`w-5 h-5 ${
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "cancelled" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "pending" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "confirmed" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "processed" &&
                      orderDetails?.productDetails?.[statusData.id]?.status !==
                        "dispatched"
                        ? "text-lime-400"
                        : "text-gray-200"
                    } `}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
          {/* --------------------------------------------------------------------------------------------------- */}
        </div>
        <dialog
          id="my_modal_1"
          className={`modal ${modal.status ? "modal-open" : ""}`}
        >
          <div className="modal-box bg-white">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                onClick={() => {
                  setModal({ status: false });
                }}
              >
                ✕
              </button>
            </form>
            <h3 className="font-bold text-lg">Warning!</h3>
            <p className="py-4">Are you sure, you want to cancel this order?</p>
            <div className="modal-action">
              <form method="dialog">
                <button
                  className="btn bg-red-600 text-white hover:bg-red-700 me-3"
                  onClick={cancelOrder}
                >
                  Confirm
                </button>
                <button
                  className="btn bg-[#D8E7CC] text-[#021400]"
                  onClick={() => setModal({ status: false })}
                >
                  Close
                </button>
              </form>
            </div>
          </div>
        </dialog>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetails;
