import React from "react";
import Banner from "./Banner";
import { Products } from "./Products";
import { Footer } from "../../pages/Footer";
import Header from "../../components/header/index";

const index = () => {
  return (
    <>
      <div className="bg-gray-100">
        <Header />
        <Banner />
        <Products />
      </div>
      <Footer />
    </>
  );
};

export default index;
