import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/index";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Terms from "./components/Legals/Terms";
import FAQ from "./components/Legals/FAQ";
import ReturnPolicy from "./components/Legals/ReturnPolicy";
import ShippingPolicy from "./components/Legals/ShippingPolicy";
import { AboutUs } from "./components/AboutUs/AboutUs";
import { Account } from "./components/Account/Account";
import Setnewpassword from "./utils/Setnewpassword";
import Forgetpassword from "./pages/Forgetpassword";
import Emailverification from "./utils/Emailverification";
import { ChangePassword } from "./components/Account/ChangePassword";

import MyOrders from "./components/Account/MyOrders";
import { Myprofile } from "./components/Account/Myprofile";
import { AddressList } from "./components/Account/AddressList";
import { EditAddress } from "./components/Account/EditAddress";
import { AddAddress } from "./components/Account/AddAddress";
import { ProductDetails } from "./components/Product/ProductDetails";
import CheckOutIndex from "./components/checkOut/CheckOutIndex";
import StaticPages from "./components/Legals/StaticPages";
import PartnerPage from "./pages/PartnerPage";
import AllProduct from "./components/Product/AllProduct";
import SegmentProduct from "./components/Product/SegmentProduct";
import CategoryProduct from "./components/Product/CategoryProduct";
import OrderDetails from "./components/Account/OrderDetails";
import PremiumProducts from "./components/Product/PremiumProducts";
import Error from "./pages/Error";
import ContactUs from "./pages/ContactUs";
import Event_occasionProd from "./components/Product/Event_occasionProd";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />

        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/legal-page/:id" element={<StaticPages />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/becomePartner" element={<PartnerPage />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="order/:id" element={<OrderDetails />} />
        <Route path="/account" element={<Account />}>
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="my-orders" element={<MyOrders />} />
          <Route path="my-profile" element={<Myprofile />} />
          <Route path="my-address" element={<AddressList />} />
          <Route path="my-address/:id" element={<EditAddress />} />
          <Route path="my-address/add-address" element={<AddAddress />} />
        </Route>
        <Route path="setnewpassword/:id" element={<Setnewpassword />} />
        <Route path="/forgetpassword" element={<Forgetpassword />} />
        <Route path="/verify-email/:id" element={<Emailverification />} />
        <Route path="/products" />
        <Route path="/products/product/:id" element={<ProductDetails />} />
        {/* <Route path="/allproduct" element={<AllProduct />} /> */}
        <Route path="/product/:id" element={<SegmentProduct />} />
        <Route path="/event/:id" element={<Event_occasionProd />} />
        <Route path="/occasion/:id" element={<Event_occasionProd />} />
        <Route path="/premium/:id" element={<PremiumProducts />} />
        <Route path="/segment/:id" element={<CategoryProduct />} />
        <Route path="/checkout" element={<CheckOutIndex />} />
      </Routes>
    </>
  );
}

export default App;
