import React, { useRef, useEffect, useState } from "react";
import { noTokenGetRequest, noTokenPostRequest } from "../../helpers";
import useCookie from "../../hooks/cookie";
import { CiLocationOn } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import "../Product/productStyle.css";
import HeroBanner1 from "../../assets/banners/heroBanner1.png";
import HeroBanner2 from "../../assets/banners/heroBanner2.png";
import ci1 from "../../assets/images/socials/ci1.webp";
import ci2 from "../../assets/images/socials/ci2.webp";
import ci3 from "../../assets/images/socials/ci3.webp";
import ci4 from "../../assets/images/socials/ci4.webp";
import ci5 from "../../assets/images/socials/ci5.webp";
import partyPoper from "../../assets/images/partyPop.gif";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";
import image from "../../assets/img1.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Banner from "./Banner";
import { Select } from "antd";

export const Products = () => {
  const navigate = useNavigate();

  const [details, setDetails] = useState({
    segment: [],
    category: [],
    count: 1000,
    filter: [],
  });

  const [findGift, setFindGift] = useState("");
  const [segment, setSegment] = useState([]);
  const [premiumList, setPremiumList] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const contentStyle = {
    height: "auto",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    padding: "0 1.5rem",
  };

  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  const [productsList, setProductsList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [premiumGifts, setPremiumGifts] = useState([]);
  const [occasion, setOccassion] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [videos, setVideos] = useState([]);
  // const [productList, setProductList] = useState([]);

  useEffect(() => {
    // fetching products
    const credentials = { ...details };
    noTokenGetRequest({ url: "premium-events" })
      .then((res) => {
        if (res.data.status) {
          setPremiumList(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenPostRequest({ url: "products", cred: credentials })
      .then((res) => {
        if (res.data.status) {
          setProductsList(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenGetRequest({ url: "events" })
      .then((res) => {
        if (res.data.status) {
          setEventsList(res.data.data.slice(-8));
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenGetRequest({ url: "occasion" })
      .then((res) => {
        if (res.data.status) {
          setOccassion(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenGetRequest({ url: "banner" })
      .then((res) => {
        if (res.data.status) {
          setBannerList(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenGetRequest({ url: "video-section" })
      .then((res) => {
        if (res.data.status) {
          setVideos(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    noTokenGetRequest({
      url: "segment",
      cred: {
        page: 1, // optional (default value 1)
        count: 10,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setSegment(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
    // noTokenPostRequest({ url: "premium-events" }
    //   .then((res) => {
    //     if (res.data.status) {
    //       setPremiumGifts(res.data.data);
    //     }
    //   })
    //   .catch((AxiosError) => {
    //     console.log(AxiosError);
    //   });
  }, []);

  const options = occasion?.map((res) => ({
    value: res?.slug,
    label: res?.name,
    disabled: res?.disabled || false,
  }));

  const handleOccasion = (slug) => {
    const filteredOptions = occasion
      ?.filter((res) => res?.slug === slug)
      .flatMap(
        (res) =>
          res?.giftType?.map((gift) => ({
            value: gift?.slug,
            label: gift?.name,
            disabled: res?.disabled || false,
          })) || []
      );
    setOptions2(filteredOptions);
  };
  // console.log("occasion", options2);
  // const handleGiftType = (slug) => {
  //   setFindGift(slug);
  // };
  const handleGiftType = (slug) => {
    console.log("Selected slug:", slug);
    setFindGift(slug);
  };

  // console.log(eventsList, "list");

  // const videos = [
  //   "https://www.youtube.com/embed/Z8WNGehYikk?controls=0&si=9Whiv6oqqzBAN48L",
  //   "https://www.youtube.com/embed/i_yLpCLMaKk?controls=0&si=WxL2OnKmlhDI6P7y",
  //   "https://www.youtube.com/embed/9Kvf3QYCOmA?controls=0&si=tQuBk_At6nt0eUc8",
  //   "https://www.youtube.com/embed/i_yLpCLMaKk?controls=0&si=WxL2OnKmlhDI6P7y",
  //   "https://www.youtube.com/embed/9Kvf3QYCOmA?controls=0&si=tQuBk_At6nt0eUc8",
  //   "https://www.youtube.com/embed/ApXoWvfEYVU?controls=0&si=aSgLn14yvBzZaEdn",
  //   "https://www.youtube.com/embed/Vh-VUwcnUpc?si=ISxQB0GDpFXlqcvt&amp;controls=0",
  // ];
  // setVideos(
  //   "https://www.youtube.com/embed/Z8WNGehYikk?controls=0&si=9Whiv6oqqzBAN48L",
  //   "https://www.youtube.com/embed/i_yLpCLMaKk?controls=0&si=WxL2OnKmlhDI6P7y",
  //   "https://www.youtube.com/embed/9Kvf3QYCOmA?controls=0&si=tQuBk_At6nt0eUc8",
  //   "https://www.youtube.com/embed/i_yLpCLMaKk?controls=0&si=WxL2OnKmlhDI6P7y",
  //   "https://www.youtube.com/embed/9Kvf3QYCOmA?controls=0&si=tQuBk_At6nt0eUc8",
  //   "https://www.youtube.com/embed/ApXoWvfEYVU?controls=0&si=aSgLn14yvBzZaEdn",
  //   "https://www.youtube.com/embed/Vh-VUwcnUpc?si=ISxQB0GDpFXlqcvt&amp;controls=0"
  // );

  const [isPlaying, setIsPlaying] = useState(false);

  // const handleThumbnailClick = () => {
  //   setIsPlaying(true);
  // };

  return (
    <>
      <div className="w-full xl:w-4/5 m-auto pb-5 ">
        {/* maskSection */}
        <div className=" p-10 rounded ">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 xl:gap-8 items-center justify-center font-normal text-lime-900">
            {eventsList &&
              eventsList.map((event, index) => {
                return (
                  <Link to={event?.slug ? `/event/${event?.slug}` : `/`}>
                    <div
                      className="h-auto sm:h-[140px] font-[roboto] font-[500] flex flex-col"
                      role="button"
                    >
                      <img
                        className="mask mask-squircle mb-2 h-[6rem] object-cover"
                        src={event.image}
                      />
                      <p className="text-center text-base">{event.name}</p>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
        {/* analyticsSection */}
        <div className="flex justify-center my-5 font-[roboto] font-semibold">
          <div className="stats shadow-2xl bg-white text-lime-900 hidden lg:flex rounded-md">
            <div className="stat ">
              <div className="stat flex items-center justify-between ">
                <div className="w-18 font-bold text-lg text-nowrap ">
                  GIFT FINDER
                </div>
              </div>
            </div>
            <div className="stat">
              <div className="stat-value text-lime-500 text-lg text-center">
                OCCASION
              </div>
              <Select
                defaultValue="Birthday..."
                style={{
                  width: 120,
                }}
                variant="borderless"
                onChange={handleOccasion}
                options={options}
              />
            </div>
            <div className="stat">
              <div className="stat-value text-lime-500 text-lg text-center">
                GIFT TYPE
              </div>
              <Select
                defaultValue="Cakes, flowers"
                variant="borderless"
                style={{
                  width: 150,
                }}
                onChange={handleGiftType}
                options={options2}
              />
            </div>
            <div className="stat flex  items-center justify-between">
              <div
                className="btn w-full px-6 bg-[#d8e7cc] hover:bg-[#c6dfb2] shadow-lg border-0 rounded text-lg font-[roboto] text-lime-900 text-nowrap"
                onClick={() => {
                  if (findGift) {
                    navigate(`/occasion/${findGift}`);
                  }
                }}
              >
                FIND GIFTS
              </div>
            </div>
          </div>
        </div>
        {/* #1TextSection  */}
        <div className="flex items-center justify-center m-5 sm:m-10 ">
          <div className="font-[roboto] font-light">
            <p className="text-xl sm:text-base md:text-lg lg:text-xl xl:text-2xl ">
              Celebrate Occations with India #1 Plants, Flowers and Cakes Store
            </p>
            <p className="text-xs  md:text-sm text-center font-[400] text-gray-500">
              Brightening your celebrations with our exquisite selection of
              flowers, plants, and cakes
            </p>
          </div>
        </div>
        {/* SegmentSection */}
        <div className="bg-white m-3 sm:m-5 p-0 sm:p-5 rounded-lg shadow-md overflow-hidden max-[770px]:h-auto max-[869px]:h-[32rem] max-[1029px]:h-[34rem]">
          <div className="bt-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5">
              <div className=" p-3 rounded shadow-md">
                <div className="bg-gradient-to-tr from-[#D7EAD4] to-[#DCF0F2] h-full relative">
                  <div className="flex justify-between items-center">
                    <img
                      src={partyPoper}
                      alt=""
                      className="absolute top-0 right-0 h-56"
                    />
                    <div className="py-2 px-4">
                      <h2 className="text-4xl font-[600] text-stone-800 w-24 my-2 animate-pulse">
                        Premium Gifts
                      </h2>
                      <p className="font-[500] text-stone-800 my-2 text-[1.1rem]">
                        Beautiful Expressions of joy{" "}
                      </p>
                      <button className="px-5 py-1 rounded-md bg-[#365314] text-white uppercase font-[500] animate-bounce my-2 hover:animate-none">
                        Gift Now
                      </button>
                    </div>
                    <img
                      className=" w-40 px-2"
                      src={HeroBanner1}
                      alt="Hero Banner"
                    />
                  </div>
                </div>
              </div>
              <div className=" p-3 rounded shadow-md">
                <div className="bg-gradient-to-tr from-[#D7EAD4] to-[#DCF0F2] h-full relative">
                  <div className="flex justify-between items-center">
                    <img
                      src={partyPoper}
                      alt=""
                      className="absolute top-0 right-0 h-56"
                    />
                    <div className="py-2 px-4">
                      <h2 className="text-4xl font-[600] text-stone-800 w-24 my-2 animate-pulse">
                        Green Gifts
                      </h2>
                      <p className="font-[500] text-stone-800 my-2 text-[1.1rem]">
                        Plant positivity in their life
                      </p>
                      <button className="px-5 py-1 rounded-md bg-[#365314] text-white uppercase font-[500] animate-bounce my-2 hover:animate-none">
                        Gift Now
                      </button>
                    </div>
                    <img
                      className=" w-60 px-2"
                      src={HeroBanner2}
                      alt="Hero Banner"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 min-[800px]:grid-cols-3 min-[1030px]:grid-cols-4  gap-4 m-4">
            {premiumList?.map((res, index) => {
              return (
                <>
                  <div
                    key={index + 1}
                    className={`card rounded-md card-compact w-32 min-[510px]:w-56  m-auto sm:m-3 bg-gray-100 shadow-md`}
                    onClick={() => navigate(`/premium/${res?.slug}`)}
                    role="button"
                  >
                    <img
                      src={
                        res?.image ||
                        "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                      }
                      alt={res?.name}
                      key={index + 1}
                      className={`zoom-effect object-cover m-auto min-[480px]:h-48 min-[480px]:w-56 h-40 w-40`}
                    />
                    <div className="py-3 text-center">
                      <h2 className=" text-sm font-[500] font-[roboto] capitalize px-3 text-gray-700 truncate">
                        {res?.name.toLowerCase()}
                      </h2>
                    </div>
                  </div>
                </>
              );
            })}

            {/* ------------------------------------------------------- */}
            {/* <div
              className="card card-compact w-auto sm:w-auto h-96 bg-gray-100 shadow-xl m-0 sm:m-3"
              onClick={() => navigate(`/product/6661965950923b702d3808cc`)}
              role="button"
            >
              <figure>
                <img
                  src={ci2}
                  alt="Flower"
                  className="w-fit sm:w-auto object-cover rounded-lg shadow-xl"
                />
              </figure>
              <div className="card-body text-black">
                <h2 className="card-title text-lime-900">Special Bouquet!</h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
                <div className="card-actions justify-end">
                  <button className="btn bg-[#d8e7cc] border-0 text-lime-900">
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
            <div
              className="card card-compact w-auto sm:w-auto h-96 bg-gray-100 shadow-xl m-0 sm:m-3"
              onClick={() => navigate(`/product/6661965950923b702d3808cd`)}
              role="button"
            >
              <figure>
                <img
                  src={ci3}
                  alt="Flower"
                  className="w-fit sm:w-auto object-cover rounded-lg shadow-xl"
                />
              </figure>
              <div className="card-body text-black">
                <h2 className="card-title text-lime-900">
                  Personalised Gifts!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
                <div className="card-actions justify-end">
                  <button className="btn bg-[#d8e7cc] border-0 text-lime-900">
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
            <div
              className="card card-compact w-auto sm:w-auto h-96 bg-gray-100 shadow-xl m-0 sm:m-3"
              onClick={() => navigate(`/product/6661965950923b702d3808ce`)}
              role="button"
            >
              <figure>
                <img
                  src={ci4}
                  alt="Flower"
                  className="w-fit sm:w-auto object-cover rounded-lg shadow-xl"
                />
              </figure>
              <div className="card-body text-black">
                <h2 className="card-title text-lime-900">Special Cakes!</h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
                <div className="card-actions justify-end">
                  <button className="btn bg-[#d8e7cc] border-0 text-lime-900">
                    Shop Now
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="m-3 sm:m-5 p-0  shadow-md bg-white">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            pagination={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper "
          >
            {bannerList.map((res, index) => {
              return (
                <>
                  <SwiperSlide key={index} className="p-2">
                    <img src={res?.image?.url} alt="no " />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>

        {/* ----------------------------------------------------------------Video----------------------------------------------------- */}
        {videos?.length > 0 && (
          <div className="m-3 sm:m-5 p-0 sm:p-5">
            <div className="text-lg md:text-2xl font-[500] font-[roboto] m-5 capitalize">
              Joyful Stories
            </div>
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                1880: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1480: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1180: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                880: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
              }}
              className="mySwiper align-left"
            >
              {videos?.map((res, index) => (
                <SwiperSlide key={index}>
                  <div className="video-container w-[300px]">
                    <iframe
                      width="225px"
                      height="400"
                      src={res?.videoUrl?.[0]}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="rounded-xl"
                    ></iframe>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {/* -----------------------------------------------------------------Video------------------------------------------------------ */}

        {segment?.map((res, index1) => {
          return (
            <>
              <div className="bg-white m-3 sm:m-5 p-0 sm:p-5 shadow-md min-[640px]:h-[23rem] overflow-hidden">
                <div className="flex justify-between">
                  <div className="text-lg md:text-2xl font-[500] font-[roboto]  m-5 capitalize">
                    {res?.name}
                  </div>
                  <div
                    className="btn m-3 bg-[#d8e7cc] hover:bg-[#c6dfb2] border-0 text-lime-900 rounded  text-sm font-bold font-[roboto]"
                    onClick={() => navigate(`/product/${res?.slug}`)}
                  >
                    VIEW ALL
                  </div>
                </div>
                <div className="grid grid-cols-2 min-[770px]:grid-cols-3 min-[1050px]:grid-cols-4 min-[1250px]:grid-cols-5 min-[1280px]:grid-cols-4 min-[1550px]:grid-cols-5 gap-4 p-2 overflow-hidden">
                  {productsList
                    ?.filter((item) => item.segment.slug === res.slug)
                    .slice(0, isSmallScreen ? 4 : 5)
                    ?.map((item, index) => {
                      return (
                        <>
                          <div
                            key={index + 1}
                            className={`card rounded-md card-compact w-36 min-[480px]:w-56  m-auto  ${
                              (index1 + 1) % 2 === 0 ? "" : "shadow-md"
                            } m-0 sm:m-3`}
                            onClick={() =>
                              navigate(`/products/product/${item.slug}`)
                            }
                            role="button"
                          >
                            {/* {console.log(
                              item?.cakeVariant?.[0].image?.[0],
                              " item?.cakeVariant?.[0].image?.[0]"
                            )} */}
                            <img
                              src={
                                item?.isVariant
                                  ? item?.cakeVariant?.[0].image?.[0]
                                  : item.image?.[0] ||
                                    "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                              }
                              alt={item?.name}
                              key={index + 1}
                              className={`zoom-effect object-cover m-auto ${
                                (index1 + 1) % 2 === 0
                                  ? "rounded-full shadow-lg border-t h-40 w-40 min-[480px]:h-52 min-[480px]:w-52"
                                  : "min-[480px]:h-48 min-[480px]:w-56 h-40 w-40"
                              }`}
                            />
                            <div className="py-3 text-center">
                              <h2 className=" text-sm font-[500] font-[roboto] capitalize px-3 text-gray-700 truncate">
                                {item?.name.toLowerCase()}
                              </h2>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              {index1 === 2 && (
                <div className="m-3 sm:m-5 p-0  shadow-md bg-white">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    autoplay={{
                      delay: 3500,
                      disableOnInteraction: true,
                    }}
                    pagination={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="mySwiper "
                  >
                    {bannerList.map((res, index) => {
                      return (
                        <>
                          <SwiperSlide key={index} className="p-2">
                            <img src={res?.image?.url} alt="no " />
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                </div>
              )}
            </>
          );
        })}
        {/* cardSection */}
        {/* <div className="bg-white m-3 sm:m-5 p-0 sm:p-5 shadow-md">
          <div className="flex justify-between">
            <div className="text-lg md:text-2xl font-[500] font-[roboto]  m-5">
              Cakes And Flowers
            </div>
            <div
              className="btn m-3 bg-[#d8e7cc] border-0 text-lime-900 rounded  text-sm font-bold font-[roboto]"
              onClick={() => navigate(`/allproduct`)}
            >
              VIEW ALL
            </div>
          </div>
          <div className="grid grid-cols-1 min-[380px]:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {productsList
              ?.filter((item) => item.segment.slug === "cakes_&_flowers")
              .map((item, index) => {
                return (
                  <>
                    <SwiperSlide key={index}>
                      <div
                        key={index + 1}
                        className="card rounded-none card-compact w-auto sm:w-54  shadow-md m-0 sm:m-3 "
                        onClick={() =>
                          navigate(`/products/product/${item._id}`)
                        }
                        role="button"
                      >
                        <figure>
                          <img
                            src={
                              item.image[0] ||
                              "https://coastaire.com.au/wp-content/uploads/2017/02/dummy-product.jpg"
                            }
                            alt="Flower"
                            className="sm:w-auto object-contain  shadow-xl zoom-effect h-44"
                          />
                        </figure>
                        <div className="py-3 text-center">
                          <h2 className=" text-md font-[500] font-[roboto]  px-3 text-gray-700">
                            {item?.name}
                          </h2>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
          </div>
        </div> */}
        {/* ------------------------------------------------------ play Ground ------------------------------------------ */}
        {/* <div className="bg-white p-5">
          <h2>PlayGround</h2>
          <div className="p-5">
            <div>
              {active
                .filter((item) => item.value)
                .map((item) => (
                  <div key={item.label}>
                    <input
                      type="checkbox"
                      className="toggle"
                      defaultChecked={item.value}
                      onChange={() => handleCheckboxChange(item.label)}
                    />
                    <label>{`Label ${item.label}`}</label>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {console.log(active, "arr")} */}
        {/* ------------------------------------------------------ play Ground ------------------------------------------ */}
      </div>
    </>
  );
};
