import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { Footer } from "./Footer";
import { noTokenGetRequest, postRequest } from "../helpers";
import toast from "react-hot-toast";
import useCookie from "../hooks/cookie";
import signUpUnDraw from "../assets/images/signUp-unDraw.svg";
import InterviewUnDraw from "../assets/images/interview-unraw.svg";

const PartnerPage = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    category: "",
    mobile: "",
  });
  const [category, setCategory] = useState([]);
  const [setCookie, getCookie] = useCookie();

  const authToken = getCookie("gamlawala-auth");
  const token = authToken && authToken !== "undefined" && authToken !== "";
  // Debugging output to see the actual value of authToken
  // console.log("authToken:", authToken);
  // console.log("token:", token);

  const submit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    postRequest({
      url: "vendor/become-a-partner",
      cred: data,
      token: authToken,
    })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.msg);
          setData({
            name: "",
            email: "",
            category: "",
            mobile: "",
          });
        } else {
          // Handle the case where status is false
          toast.error("An error occurred. Please try again.");
        }
      })
      .catch((err) => {
        // Improved error handling
        if (err.response) {
          toast.error(err.response.data.msg);
          console.log(err.response.data);
        } else {
          toast.error("An error occurred. Please try again.");
          console.error(err); // Log the actual error
        }
      });
  };

  useEffect(() => {
    noTokenGetRequest({ url: "category" }).then((res) => {
      if (res.data.status) {
        setCategory(res.data.data);
      }
    });
  }, []);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <div className="w-full sm:w-4/5 m-auto font-[roboto]">
        <div className="">
          <div className="my-hero">
            <div className=" font-bold text-center my-[3rem] text-[#042100] p-5 bg-[#D8E7CC] rounded hover:shadow-xl transition-all duration-300">
              <p className="tracking-[5px] lg:tracking-[2.5rem] xl:ms-5 uppercase text-xl lg:text-3xl text-nowrap">
                Become Partner
              </p>
              <p className="hidden md:block tracking-[.5rem] lg:tracking-[1.5rem] uppercase text-xs">
                Become a partner of Gamlewala
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 my-[2rem] gap-4 items-center">
              <div className="md:col-span-3 card shrink-0 w-full max-w-xl shadow-2xl  bg-white m-auto">
                <form className="card-body max-w-full" onSubmit={submit}>
                  <div className="text-4xl text-center my-4 font-bold text-[#042100]">
                    Become Partner
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Name</span>
                      </label>
                      <input
                        type="text"
                        placeholder="name"
                        onChange={handleChange}
                        name="name"
                        value={data?.name}
                        className="input input-bordered bg-white"
                        required
                      />
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Email</span>
                      </label>
                      <input
                        type="email"
                        placeholder="email"
                        onChange={handleChange}
                        name="email"
                        value={data?.email}
                        className="input input-bordered bg-white"
                        required
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Phone</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Phone No."
                        onChange={handleChange}
                        name="mobile"
                        value={data?.mobile}
                        className="input input-bordered bg-white"
                        required
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Category</span>
                      </label>
                      <select
                        name="category"
                        value={data?.category}
                        onChange={handleChange}
                        className="input input-bordered bg-white capitalize text-gray-600"
                        required
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {category.map((item) => (
                          <option key={item.id} value={item._id}>
                            {item.name.toLowerCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-control mt-6">
                    <button
                      className="btn bg-[#D8E7CC] text-[#042100] hover:bg-[#BCCBB1] border-0 hover:shadow-xl"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="md:col-span-2 p-5">
                <img src={InterviewUnDraw} alt="" />
              </div>
            </div>
          </div>
          {/* <div className="hero min-h-screen bg-white">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div className="w-[40vw] m-auto object-contain"></div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PartnerPage;
