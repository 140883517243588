import React, { useState } from "react";
import Header from "../components/header";
import { Footer } from "../pages/Footer.jsx";
import { TbHeart, TbShoppingCart, TbUserCircle } from "react-icons/tb";
import contactSvg from "../assets/images/undraw_contact_us.svg";
import { noTokenPostRequest, postRequest } from "../helpers/index.js";
import toast from "react-hot-toast";
import { Card, Typography, Space, Divider } from "antd";
import { MailOutlined, PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import useCookie from "../hooks/cookie.js";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLocalPhone, MdLocationOn } from "react-icons/md";

const ContactUs = () => {
  const { Title, Paragraph } = Typography;

  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    query: "",
    subject: "",
  });
  //   window.scroll(0, 0);
  const [getCookie, setCookie] = useCookie();
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken != undefined || authToken != "undefined" ? true : false;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      // Ensure the value contains only digits and is at most 10 characters long
      if (/^\d{0,10}$/.test(value)) {
        setData({ ...data, [name]: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await postRequest({
        url: "query/create",
        cred: data,
      });
      if (res?.status === 200) {
        toast.success(res?.data?.msg);
        setData({
          name: "",
          email: "",
          mobile: "",
          query: "",
        });
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
    console.log(data);
  };

  return (
    <>
      <Header />
      <div className="">
        <div className="bg-[#edf5e788] py-5">
          <div className="m-auto bg-white min-[940px]:w-[80%] min-[1340px]:w-[70%] rounded-xl p-3 my-5 shadow-xl max-[939px]:mx-4">
            <div className="grid grid-cols-1 min-[800px]:grid-cols-5 gap-2">
              <div className="min-[800px]:col-span-3">
                <div className="p-1 ">
                  {" "}
                  <form className="card-body" onSubmit={handleSubmit}>
                    <div className="m-2">
                      <h1 className=" text-3xl font-bold text-[#042100]">
                        Have Any Question?
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 min-[540px]:grid-cols-2 justify-between items-center gap-4">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-[600] text-gray-500">
                            Your Name<span className="text-red-600">*</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="name"
                          value={data?.name}
                          className="input input-ghost bg-white focus:outline-none focus:border-0"
                          onChange={handleChange}
                          name="name"
                          required
                        />
                        <hr />
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-[600] text-gray-500">
                            Your Email<span className="text-red-600">*</span>
                          </span>
                        </label>
                        <input
                          type="email"
                          placeholder="email"
                          value={data?.email}
                          onChange={handleChange}
                          name="email"
                          className="input input-ghost bg-white focus:outline-none focus:border-0"
                          required
                        />
                        <hr />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 min-[540px]:grid-cols-2 justify-between items-center gap-4">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-[600] text-gray-500">
                            Phone Number<span className="text-red-600">*</span>
                          </span>
                        </label>
                        <input
                          type="tel"
                          name="mobile"
                          placeholder="Enter your phone number"
                          value={data?.mobile}
                          onChange={handleChange}
                          pattern="\d{10}"
                          title="Phone number must be exactly 10 digits"
                          className="input input-ghost bg-white focus:outline-none focus:border-0"
                          required
                        />
                        <hr />
                        {data.phone && data.phone.length !== 10 && (
                          <p className="text-red-500 text-xs mt-1">
                            Phone number must be exactly 10 digits.
                          </p>
                        )}
                      </div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-[600] text-gray-500">
                            Your Subject
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="I wanted to know something "
                          value={data?.subject}
                          onChange={handleChange}
                          name="subject"
                          className="input input-ghost bg-white focus:outline-none focus:border-0"
                          required
                        />
                        <hr />
                      </div>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text font-[600] text-gray-500">
                          Your Question or Concern
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your question or concern"
                        value={data?.query}
                        onChange={handleChange}
                        name="query"
                        className="input input-ghost bg-white focus:outline-none focus:border-0"
                        // required
                      />
                      <hr />
                    </div>
                    <div className="form-control mt-6 ">
                      <button
                        className="btn bg-[#042100] text-white hover:text-black max-w-40"
                        type="submit"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="min-[800px]:col-span-2">
                <div className="bg-[#0A2705] rounded-xl text-white p-3 overflow-hidden  min-h-[65vh]">
                  <div className=" p-2  h-full ">
                    <div className=" p-2">
                      {/* {/ get in touch here  /} */}

                      <h2 className="mb-3 my-2 text-3xl text-center font-[600]">
                        Get in Touch
                      </h2>
                      <p className="my-5">
                        We'd love to hear from you! Whether you have a question
                        about our services, need assistance, or just want to
                        provide feedback, our team is here to help.
                      </p>
                      <hr className="border border-white w-full" />
                    </div>
                  </div>
                  <div className=" py-4 space-y-10 mx-3 p-1">
                    <div className="flex items-center gap-3">
                      <MdLocalPhone />
                      <span>
                        {" "}
                        <a href="tel:+919695832010">+919695832010</a>
                      </span>
                    </div>
                    <div className="flex items-center gap-3">
                      <MdEmail />
                      <span>
                        {" "}
                        <a href="mailto:support@gamlewala.in">
                          {" "}
                          support@gamlewala.in
                        </a>
                      </span>
                    </div>
                    <div className="flex items-center gap-3">
                      <MdLocationOn size={25} />
                      <span>
                        {" "}
                        Regd. Office Address: Plot No. 75P, Sector-44, lucknow
                        Uttar Pradesh, India.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
