import React, { useContext, useEffect, useState } from "react";
import "./headerStyle.css";
import "../Product/productStyle.css";
import Logo from "../../assets/images/logo-gw.png";
import Logod from "../../assets/images/logo-detail.png";
import fullLogo from "../../assets/images/full-img.png";
import { CiLocationOn } from "react-icons/ci";
import {
  getRequest,
  noTokenGetRequest,
  noTokenPostRequest,
  postRequest,
  request,
} from "../../helpers";
import useCookie from "../../hooks/cookie";
import { deleteCookie } from "../../hooks/cookie";
import { Link, useNavigate } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import { IoMenu, IoMenuOutline } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import { TbHeart, TbShoppingCart, TbUserCircle } from "react-icons/tb";
import { BsSuitHeart } from "react-icons/bs";
import { PiShoppingCart } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { CartContext } from "../../contexts/CartContext";
import { WishlistContext } from "../../contexts/WishListContext";
import toast, { Toaster } from "react-hot-toast";

const Header = () => {
  const navigate = useNavigate();

  // ACCESSING CONTEXT API FOR CART
  const [data, setData] = useContext(CartContext);

  const [cartData, setCartData] = useState([]);

  // ACCESSING CONTEXT API FOR CART
  const [wishListData, setWishListData] = useContext(WishlistContext);
  const [wishlistData, setWishlistData] = useState([]);
  const [cartDataStatus, setCartDataStatus] = useState(false);
  const [wishListDataStatus, setWishListDataStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchContainer, setSearchContainer] = useState("");
  const [searchData, setSearchData] = useState([]);

  const contentStyle = {
    position: "-webkit-sticky",
    position: "sticky",
    top: "0",
  };

  // USING CUSTOM COOKIE HOOK
  const [setCookie, getCookie] = useCookie();

  const [profileDetails, setProfileDetails] = useState();

  const [segment, setSegment] = useState([]);
  const [productList, setProductList] = useState([]);

  // const [size, setSize] = useState("");

  // Retrieve existing wishlist from localStorage
  let wishlist = localStorage.getItem("wishlist");
  wishlist = wishlist ? JSON.parse(wishlist) : [];

  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  const handleSearch = (event) => {
    const { value } = event.target;
    // console.log(value, "search value");
    setSearchContainer(value);
    setIsDropdownVisible(value.length > 0);
  };
  // console.log(searchContainer, "search value");

  const [details, setDetails] = useState({
    segment: [],
    category: [],
    count: 1000,
    filter: [],
  });

  // global search useEffect
  useEffect(() => {
    if (searchContainer.trim() === "") {
      setSearchData([]);
      return;
    }

    postRequest({ url: "global-search", cred: { search: searchContainer } })
      .then((res) => {
        if (res.data.status) {
          setSearchData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchContainer]);

  // ------------------------------------------navigating to the search result ------------------------------------------
  const handleSearchResult = (res) => {
    const currentPath = window.location.pathname;
    let targetPath;

    if (res.type === "segment") {
      targetPath = `/product/${res?._id}&type=segment`;
    } else if (res.type === "category") {
      targetPath = `/segment/${res?._id}&type=category`;
    } else {
      targetPath = `/products/product/${res?.slug}`;
    }

    if (currentPath.substring(0, 9) === targetPath.substring(0, 9)) {
      window.location.href = targetPath;
    } else {
      navigate(targetPath);
    }

    setSearchData([]);
  };

  useEffect(() => {
    {
      if (token) {
        getRequest({ url: "profile", token: authToken })
          .then((res) => {
            if (res.data.status) {
              setProfileDetails(res.data.data);
            }
          })
          .catch((AxiosError) => {
            console.log(AxiosError);
          });
      }
    }
    getRequest({ url: "segment/with-category" })
      .then((res) => {
        if (res.data.status) {
          setSegment(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, []);

  useEffect(() => {
    const credentials = { ...details };
    noTokenPostRequest({ url: "products", cred: credentials })
      .then((res) => {
        if (res.data.status) {
          // Retrieve wishlist from local storage
          const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

          // Get the product list from the response
          let products = res.data.data;

          // Update products to set inWishlist field
          products = products.map((product) => {
            return {
              ...product,
              inWishlist: wishlist.includes(product._id),
            };
          });

          // Set the updated product list
          setProductList(products);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [updateStatus, wishlist.length]);

  useEffect(() => {
    if (postalCode) {
      getRequest({ url: `location/pin-code/${postalCode}` }).then((res) => {
        if (res.data.status) {
          setAddress(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      });
    } else {
      setAddress([]);
    }
  }, [postalCode]);

  const [search, setSearch] = useState({
    search: "",
  });
  const convertDate = (dt) => new Date(dt).toDateString();
  useEffect(() => {
    if (token) {
      postRequest({ url: "wishlist", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setWishlistData(res?.data?.data);
            setWishListData(res?.data?.count);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
      getRequest({ url: "cart", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setCartData(res?.data?.data);
            setData(res?.data?.count);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [wishListDataStatus, cartDataStatus, data, wishListData]);
  const MyAccount = () => {
    if (token) {
      navigate("/account/my-profile");
    } else {
      navigate("/signin");
    }
  };

  const profileVerify = () => {
    getRequest({ url: `verify-email`, token: authToken }).then((res) => {
      if (res.data.status) {
        toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
      }
    });
  };

  const MyOrders = () => {
    if (token) {
      navigate("/account/my-orders");
    } else {
      navigate("/signin");
    }
  };

  const MyAddress = () => {
    if (token) {
      navigate("/account/my-address");
    } else {
      navigate("/signin");
    }
  };

  const ChangePassword = () => {
    if (token) {
      navigate("/account/change-password");
    } else {
      navigate("/signin");
    }
  };
  const deleteToWishList = (id) => {
    if (authToken) {
      postRequest({
        url: "wishlist/remove",
        cred: { product: id },
        token: authToken,
      })
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setUpdateStatus(!updateStatus);
            setWishListData(res?.data?.count);
            toast.success(res?.data?.msg);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    } else {
      setWishListData(wishListData + 1);
      let wishlist = localStorage.getItem("wishlist");
      wishlist = wishlist ? JSON.parse(wishlist) : [];

      // Remove the product ID from the wishlist
      wishlist = wishlist.filter((productId) => productId !== id);

      // Save the updated wishlist back to localStorage
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
      toast.success("Item Removed");
      setWishListData(wishListData - 1);
      setUpdateStatus(!updateStatus);
    }
  };
  const deleteProductFromCart = (id, quantity) => {
    const credentials = { product: id, quantity: -quantity };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        toast.success(res.data.msg);
        setCartDataStatus(!cartDataStatus);
      }
    });
  };

  return (
    <div className="">
      <Toaster position="top-right" reverseOrder={true} />
      <div className="z-[100] bg-white font-[roboto]" style={contentStyle}>
        {/* ------------------------------------------------ Top Header Section ------------------------------------ */}
        <div className="bg-[#bccbb1] flex justify-end px-5 py-1 gap-3  items-center lg:flex text-lime-900 text-xs">
          {/* <span>
            Currency <strong>INR </strong> |
          </span> */}
          <Link to={`/becomePartner`}>
            <span role="button">
              <strong>Become a Partner</strong>{" "}
            </span>
          </Link>
        </div>
        {/* ------------------------------------------------ Meddle Header Section ------------------------------------ */}
        <div className="navbar bg-[#d8e7cc] py-1">
          <div className="navbar-start w-2/3 sm:1/2">
            <div className="w-[90rem]  md:w-4/12 2xl:w-4/12 me-5 md:object-contain">
              <img
                src={fullLogo}
                alt="Name Logo"
                onClick={() => navigate("/")}
                role="button"
              />
            </div>
            {/* search  */}
            <div className="relative z-[39] w-full">
              <div className="form-control mx-3 w-full hidden  md:block lg:block xl:block 2xl:block bg-white rounded-md">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                  className="input input-bordered border-lime-200 w-full focus:outline-none bg-white"
                />
              </div>

              {isDropdownVisible && (
                <ul className=" absolute top-10 left-0 bg-white mx-3 scrollbar w-full p-1 rounded-b-lg list-none transition-all z-[40] text-black max-h-[20vh] overflow-y-scroll">
                  {searchData?.map((res) => {
                    return (
                      <li
                        className="p-2 hover:bg-gray-100 rounded-lg"
                        role="button"
                        onClick={() => handleSearchResult(res)}
                      >
                        {res?.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          {/* <div className="navbar-center px-3">
            <div className="btn btn-ghost min-h-8 w-40 bg-white border-lime-200 hover:bg-white hover:border-lime-200 hidden md:block">
              <button
                className="flex  mt-4"
                onClick={() =>
                  document.getElementById("locationModal").showModal()
                }
              >
                <CiLocationOn className="mr-2" /> Select Location
              </button>
            </div>
          </div> */}
          {/* navEnd */}
          <div className="navbar-end w-[33vw]">
            {/* location  */}
            {/* ---------------------------------------- WishList-------------------------------------- */}
            <div className="flex items-center gap-2 md:gap-4 mt-2 ">
              {/* WishList  */}

              <div className="block dropdown dropdown-end dropdown-hover m-auto">
                <div tabIndex={0} role="button" className="w-12 ms-[1rem]">
                  <div className="indicator">
                    <TbHeart size={30} />
                    {token ? (
                      <span className="badge badge-sm indicator-item bg-red-500 border-none text-white p-1">
                        {wishListData}
                      </span>
                    ) : (
                      <span className="badge badge-sm indicator-item bg-red-500 border-none text-white p-1">
                        {wishlist?.length}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  tabIndex={0}
                  className="z-[70] card card-compact dropdown-content w-72 md:w-96 bg-white shadow rounded-sm "
                  id="wishlist"
                >
                  <div className="card-head p-5 ">
                    {token ? (
                      <span className="font-bold text-lg">
                        My Wishlist ({wishListData})
                      </span>
                    ) : (
                      <span className="font-bold text-lg">
                        My Wishlist ({wishlist.length})
                      </span>
                    )}
                  </div>
                  <hr />
                  {token ? (
                    <div className="card-body m-3 rounded-md h-80 overflow-scroll scroll  ">
                      {/* {console.log("wishListData aya kya ", wishListData)} */}
                      {wishListData ? (
                        wishlistData?.map((product, index) => {
                          return (
                            <div className="inner-card flex justify-between mb-5">
                              <a
                                href={`/products/product/${product?.product?.slug}`}
                              >
                                <span
                                  className="w-24 h-20 overflow-hidden block p-1"
                                  role="button"
                                >
                                  <img
                                    src={
                                      product?.product?.isVariant
                                        ? product?.product?.cakeVariant?.[0]
                                            ?.image?.[0]
                                        : product?.product?.image?.[0]
                                    }
                                    alt="product"
                                  />
                                </span>
                              </a>
                              <span className="">
                                <span className="capitalize text-lime-700 font-bold text-xl">
                                  {product?.product?.name}
                                </span>
                                <div className="mt-2">
                                  <strong className="text-lg text-black font-medium">
                                    ₹
                                    {product?.product?.isVariant
                                      ? product?.product?.cakeVariant?.[0]
                                          ?.price
                                      : product?.product?.price}
                                  </strong>
                                  <strong className="text-xs font-xs line-through mx-4">
                                    ₹
                                    {product?.product?.isVariant > 0
                                      ? product?.product?.cakeVariant?.[0]?.mrp
                                      : product?.product?.mrp}
                                  </strong>
                                </div>
                              </span>
                              <span
                                className="text-2xl "
                                role="button"
                                onClick={() =>
                                  deleteToWishList(product?.product?._id)
                                }
                              >
                                <MdDelete
                                  size={35}
                                  className="hover:rounded-full hover:bg-gray-200 hover:p-1 transition-all"
                                />
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <p className="text-2xl font-bold text-center mt-[17vh] w-9/11">
                            Your Wishlist is Empty
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="card-body m-3 rounded-md h-80 overflow-scroll scroll  ">
                      {wishlist ? (
                        productList.map((product, index) => {
                          return (
                            <>
                              {product?.inWishlist && (
                                <div className="inner-card flex justify-between mb-5">
                                  <a
                                    href={`/products/product/${product?.slug}`}
                                  >
                                    <span
                                      className="w-24  overflow-hidden block p-1"
                                      role="button"
                                    >
                                      <img
                                        src={
                                          product?.isVariant > 0
                                            ? product?.cakeVariant?.[0]
                                                ?.image?.[0]
                                            : product?.image?.[0]
                                        }
                                        alt="product"
                                        className="max-h-24"
                                      />
                                    </span>
                                  </a>
                                  <span className="">
                                    <span className="capitalize text-lime-700 font-bold text-xl">
                                      {product?.name}
                                    </span>
                                    <div className="mt-2">
                                      <strong className="text-lg text-black font-medium">
                                        ₹
                                        {product?.isVariant > 0
                                          ? product?.cakeVariant?.[0]?.price
                                          : product?.price}
                                      </strong>
                                      <strong className="text-xs font-xs line-through mx-4">
                                        ₹
                                        {product?.isVariant > 0
                                          ? product?.cakeVariant?.[0]?.mrp
                                          : product?.mrp}
                                      </strong>
                                    </div>
                                  </span>
                                  <span
                                    className="text-2xl "
                                    role="button"
                                    onClick={() =>
                                      deleteToWishList(product?._id)
                                    }
                                  >
                                    <MdDelete
                                      size={35}
                                      className="hover:rounded-full hover:bg-gray-200 hover:p-1 transition-all"
                                    />
                                  </span>
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <>
                          <p className="text-2xl font-bold text-center mt-[17vh] w-9/11">
                            Your Wishlist is Empty
                          </p>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <span className="hidden md:block">Wish List</span>
              </div>

              {/* -------------------------------------------------------------------- cart  ---------------------------------------- */}

              <div className="dropdown dropdown-end dropdown-hover mr-4">
                <div tabIndex={0} role="button" className="w-5">
                  <div className="indicator">
                    <TbShoppingCart size={30} />
                    <span className="badge badge-sm indicator-item bg-red-500 border-none text-white p-1">
                      {data}
                    </span>
                  </div>
                </div>
                <div
                  tabIndex={0}
                  className="z-[70] card card-compact dropdown-content w-72 md:w-96 bg-white shadow rounded-sm "
                  id="cart"
                >
                  <div className="card-head p-5">
                    <span className="font-bold text-lg">My Cart ({data})</span>
                  </div>
                  <hr />
                  <div className="card-body m-3 rounded-md  h-60 overflow-scroll scroll">
                    {data ? (
                      cartData.map((product, index) => {
                        return (
                          <div
                            className={`inner-card  mb-5 bg-gray-50 p-3 shadow ${
                              (product?.variantId?.length > 0
                                ? product?.variantId?.[0]?.stock
                                : product?.product?.stock) < 1
                                ? "opacity-40 "
                                : ""
                            }`}
                          >
                            <div className="flex justify-between">
                              <span className="w-24 overflow-hidden block p-1 h-20 ">
                                <img
                                  src={
                                    product?.variantId?.length > 0
                                      ? product?.variantId?.[0]?.image?.[0]
                                      : product?.product?.image?.[0]
                                  }
                                  alt={product?.product?.name}
                                />
                              </span>
                              <span className="">
                                <span className="capitalize text-lime-700 font-bold text-[18px]">
                                  {product?.product?.name}
                                </span>
                                <div className="">
                                  <strong className="text-sm text-black font-medium">
                                    Price: ₹
                                    {product?.variantId?.length > 0
                                      ? product?.variantId?.[0]?.price
                                      : product?.product?.price}
                                  </strong>
                                  <br />
                                  <strong className="text-xs font-xs line-through">
                                    MRP: ₹
                                    {product?.variantId?.length > 0
                                      ? product?.variantId?.[0]?.mrp
                                      : product?.product?.mrp}
                                  </strong>
                                </div>
                              </span>
                              <span
                                role="button"
                                onClick={() =>
                                  deleteProductFromCart(
                                    product?.product?._id,
                                    product?.quantity
                                  )
                                }
                              >
                                <MdDelete
                                  size={35}
                                  className="hover:rounded-full hover:bg-gray-200 hover:p-1 transition-all hover:text-red-600"
                                />
                              </span>
                            </div>
                            <div className="pt-2 font-[roboto]">
                              <div className="text-sm font-semibold">
                                Delivering On :
                              </div>
                              <div className="text-3xl font-[200]">
                                <div className="">
                                  {convertDate(product?.deliveryDate).slice(
                                    0,
                                    15
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div className=" mt-[2rem]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[8vh] w-[8vw] m-auto  text-gray-400"
                            fill="none"
                            viewBox="0 0 22 22"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </div>
                        <p className="text-2xl font-bold text-center mt-3 w-9/11">
                          Your Cart is Empty
                        </p>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className=" p-3 ">
                    <button
                      className={
                        token
                          ? data
                            ? " btn bg-[#D8E7CC] hover:bg-[#d3e4c6] w-full text-md md:text-lg rounded-md shadow-xl font-bold mt-2 border-0 text-[#042100]"
                            : "w-full btn btn-disabled"
                          : "w-full btn btn-disabled"
                      }
                      onClick={() => navigate("/checkout")}
                    >
                      {token ? "PROCEED TO CHECKOUT" : "LOGIN TO PROCEED"}
                    </button>
                  </div>
                </div>
                <span className="hidden md:block">Cart</span>
              </div>

              {/* -------------------------------------------------------profileIcon--------------------------------------------------- */}
              {/* profileIcon */}
              <div className="dropdown dropdown-end sm:pe-5">
                <div tabIndex={0} role="button" className="px-3 ps-1 ms-2 mb-2">
                  <TbUserCircle size={30} />
                </div>
                <ul
                  tabIndex={0}
                  className="menu menu-sm dropdown-content mt-3 z-[70] p-2 shadow text-black rounded-sm w-52 bg-white"
                >
                  {token && (
                    <div class="px-4 py-3 text-sm text-black  truncate">
                      <div>{profileDetails?.name}</div>

                      <div className="font-medium truncate">
                        {profileDetails?.email?.length >= 18
                          ? profileDetails?.email.slice(0, 18) + "..."
                          : profileDetails?.email}
                      </div>
                    </div>
                  )}
                  {!token && (
                    <div class="py-1">
                      <a
                        onClick={() => navigate("/signin")}
                        class="block hover:text-black px-4 py-2 text-sm text-black hover:bg-gray-100 focus:bg-[#BCCBB1]  cursor-pointer rounded-lg"
                      >
                        Sign In
                      </a>
                    </div>
                  )}
                  <hr className="mb-2" />
                  <li>
                    <a
                      onClick={() => MyAccount()}
                      class="block px-4 py-2 hover:bg-gray-100 focus:bg-[#BCCBB1]"
                    >
                      My Account
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => MyOrders()}
                      class="block px-4 py-2 hover:bg-gray-100 focus:bg-[#BCCBB1]"
                    >
                      My Orders
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => MyAddress()}
                      class="block px-4 py-2 hover:bg-gray-100 focus:bg-[#BCCBB1]"
                    >
                      My Address
                    </a>
                  </li>
                  {token && !profileDetails?.isVerified && (
                    <li>
                      <a
                        onClick={() => profileVerify()}
                        class="block px-4 py-2 hover:bg-gray-100 focus:bg-[#BCCBB1]"
                      >
                        Verify My Profile
                      </a>
                    </li>
                  )}
                  {token && (
                    <>
                      <li>
                        <a
                          onClick={() => ChangePassword()}
                          class="block px-4 py-2 hover:bg-gray-100 focus:bg-[#BCCBB1]"
                        >
                          Change Password
                        </a>
                      </li>
                      <hr className="my-2" />
                      <li>
                        <a
                          onClick={() => {
                            deleteCookie();
                            window.location.reload();
                            navigate("/");
                          }}
                          class="block px-4 py-2 text-sm  hover:bg-gray-100 focus:bg-[#BCCBB1]  cursor-pointer rounded-lg"
                        >
                          Log out
                        </a>
                      </li>
                    </>
                  )}
                </ul>
                {token ? (
                  <span className="text-center hidden md:block">
                    Hi{" "}
                    {profileDetails?.name
                      ? profileDetails.name.split(" ")[0]
                      : ""}
                  </span>
                ) : (
                  <span className="hidden md:block">Hi Guest</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------ Bottom Header Section ------------------------------------ */}
        <div className="bg-[#042100] border-t-2 hidden lg:flex justify-evenly w-auto text-white drop-shadow-lg relative z-1">
          {segment?.slice(0, 5)?.map((item, index) => {
            return (
              <div key={index} className="segment capitalize" role="button">
                <a href={`/product/${item?.slug}`}>
                  <span className="flex py-2">
                    {item?.name}
                    <RiArrowDropDownLine className="text-2xl" />
                  </span>
                </a>
                <div className="subModal rounded-md left-[50%] translate-x-[-50%] top-[2.5rem] w-[90vw]">
                  <div className="grid grid-cols-5 grid-flow-row bg-white h-[30vh] smooth overflow-y-scroll">
                    {item?.category.slice(0, 5)?.map((cat, catIdx) => {
                      return (
                        <div
                          className="p-3 w-full text-start capitalize odd:bg-gray-100"
                          role="button"
                          key={catIdx + 1}
                        >
                          <a href={`/segment/${cat?.slug}`}>
                            <p className="font-semibold text-base text-black">
                              {cat?.name}
                            </p>
                          </a>
                          <ul>
                            {cat?.subCategory?.map((name, subIdx) => {
                              return (
                                <li
                                  key={subIdx + 1}
                                  className="text-black text-[12px] p-2 hover:bg-gray-100"
                                >
                                  <a href={`/segment/${cat?.slug}`}>
                                    {name?.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                    {/* {item?.category.length < 5 &&
                      dummyCategories
                        .slice(item.category.length, 5)
                        .map((dummy, dummyIdx) => (
                          <div
                            className="p-3 w-full text-start capitalize odd:bg-gray-100"
                            role="button"
                            key={`dummy-${dummyIdx}`}
                          >
                            <img src={fullLogo} alt="Img" />
                          </div>
                        ))} */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* search mob  */}
        <div className="form-control m-2 md:hidden bg-white">
          <span className="flex items-center gap-1">
            <div className="drawer w-16 z-40 ">
              <input id="my-drawer" type="checkbox" className="drawer-toggle" />
              <div className="drawer-content border-0">
                <label
                  htmlFor="my-drawer"
                  className="btn drawer-button px-2 bg-stone-50 border-0 text-black"
                >
                  <AiOutlineMenu className="text-2xl" />
                </label>
              </div>
              <div className="drawer-side ">
                <label
                  htmlFor="my-drawer"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>
                <ul className="p-4 w-64 min-h-full bg-white overflow-hidden">
                  <li className="mb-2">
                    <div className="flex">
                      <div className="w-10 object-contain">
                        <img alt="Gamlewala Logo" src={Logo} />
                      </div>
                      <div className="w-36 me-5 ">
                        <img src={Logod} alt="Logo" />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="menu w-64 mt-[4rem] bg-white overflow-y-scroll mb-[5rem]">
                  {segment?.slice(0, 5)?.map((item, index) => {
                    return (
                      <li className="capitalize" role="button">
                        <details>
                          <summary className="active:bg-gray-200 text-black">
                            {item?.name}
                          </summary>
                          <ul>
                            {item?.category?.map((cat) => {
                              return (
                                <li>
                                  <a href={`/segment/${cat?._id}`}>
                                    {cat?.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </details>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="relative z-[39] w-full">
              <input
                type="text"
                placeholder="Search"
                onChange={handleSearch}
                className="input input-bordered w-full bg-stone-50 focus:outline-none"
              />
              {isDropdownVisible && (
                <ul className=" absolute top-10 left-0 bg-stone-50 scrollbar w-full p-1 rounded-b-lg list-none transition-all z-[40] text-black max-h-[20vh] overflow-y-scroll">
                  {searchData?.map((res) => {
                    return (
                      <li
                        className="p-2 hover:bg-gray-100 rounded-lg"
                        role="button"
                        onClick={() => handleSearchResult(res)}
                      >
                        {res?.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </span>
        </div>
      </div>

      {/* location Modal content  */}
      <dialog id="locationModal" className="modal font-[roboto]">
        <div className="modal-box overflow-y-hidden">
          <div className="px-7 grid grid-rows-3">
            <h3 className="font-bold text-xl text-center">
              Let's Personalize Your Experience!
            </h3>
            <p className="py-2 text-sm text-center">
              Find the perfect gifts for you or your loved ones – it's like
              magic!
            </p>
            <label className="input input-bordered flex items-center gap-2 relative">
              <CiLocationOn className="" />
              <input
                type="text"
                placeholder="Enter Pin Code"
                className="input w-full focus:outline-none focus:border-0 "
                value={deliveryAddress}
                onChange={(e) => {
                  setPostalCode(e?.target?.value);
                }}
                onClick={() => {
                  setDeliveryAddress();
                }}
              />
              {address?.length > 0 && (
                <ul className="h-36 overflow-y-scroll divide-y ps-2 divide-x z-50 bg-white w-[100%] absolute left-0 right-0 top-10">
                  {address?.map((res, index) => (
                    <li
                      key={index}
                      className="p-2  hover:text-white hover:bg-blue-400"
                      role="button"
                      onClick={() => {
                        setDeliveryAddress(res?.office); // Set the entire address object as the delivery address
                        setAddress([]); // Clear the address list
                      }}
                    >
                      <span>{res?.pincode},</span>
                      <span>{res?.office},</span>
                      <span>{res?.district}</span>
                    </li>
                  ))}
                </ul>
              )}
            </label>
            <div className="btn bg-[#d8e7cc] text-lime-900 text-lg my-3 ">
              Continue Shopping
            </div>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default Header;
