import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import { Footer } from "../../pages/Footer";
import useCookie from "../../hooks/cookie";
import { Collapse, Divider, Select } from "antd";
import {
  deleteRequest,
  getRequest,
  noTokenGetRequest,
  noTokenPostRequest,
  postRequest,
  request,
} from "../../helpers";
import toast, { Toaster } from "react-hot-toast";
import { CartContext } from "../../contexts/CartContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MdDelete,
  MdDeleteForever,
  MdOutlineMailOutline,
} from "react-icons/md";
import { CiMobile4 } from "react-icons/ci";
import RenderRazorpay from "./Razorpay";

const CheckOutIndex = () => {
  const [cartDataStatus, setCartDataStatus] = useState(false);

  const [data, setData] = useContext(CartContext);
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const [isCityActive, setIsCityActive] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [billingDetails, setBillingDetails] = useState();
  const [showcoupon, setShowcoupon] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [couponData, setcouponData] = useState("");
  const [apply, setApply] = useState(true);
  const [profileDetails, setProfileDetails] = useState();
  const [orderStatus, setOrderStatus] = useState(false);
  const [ProductDetails, setProductDetails] = useState([]);
  const [hit, setHit] = useState(false);
  const [errors, setErrors] = useState({});

  // USING CUSTOM COOKIE HOOK

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("slug");
  const variant = queryParams.get("variantId");
  const type = queryParams.get("type");
  const date = queryParams.get("date");
  const delivery = queryParams.get("delivery");
  const [setCookie, getCookie] = useCookie();
  const [billData, setBillData] = useState({
    type: type || "cart",
    paymentMethod: "cod",
    shipping: "standard",
    product: "",
    variantId: variant,
    coupon: "",
    deliveryType: delivery?.toLowerCase(),
    quantity: 1,
    ...(date && { deliveryDate: date }), // Conditionally add deliveryDate
  });
  const navigate = useNavigate();
  // GETTING TOKEN VALUE FROM COOKIE
  const authToken = getCookie("gamlawala-auth");
  const token =
    authToken !== undefined && authToken !== "undefined" ? true : false;

  if (!token) {
    navigate("/signin");
  }

  useEffect(() => {
    if (token) {
      getRequest({ url: "profile", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setProfileDetails(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
      getRequest({ url: "cart", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setData(res.data.count);
            setCartData(res?.data?.data);
            setLoading(false);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    }
  }, [cartDataStatus, data, orderStatus]);

  useEffect(() => {
    // Function to handle errors
    const handleError = (error) => {
      console.log(error);
    };

    // Function to fetch product details and update billing data
    const fetchProductAndBillingDetails = async (slug, token, credentials) => {
      try {
        const productResponse = await getRequest({
          url: `products/${slug}`,
          token,
        });
        if (productResponse.data.status) {
          const productData = productResponse.data.data;
          setProductDetails(productData);

          const updatedCredentials = {
            ...credentials,
            product: productData._id,
          };
          setBillData({
            ...billData,
            product: productData._id,
          });
          // console.log("authToken", authToken);
          const billingResponse = await postRequest({
            url: "billing/get",
            token: authToken,
            cred: {
              ...updatedCredentials,
              type,
              paymentMethod: "online",
              shipping: "standard",
              quantity: 1,
              variantId: variant,
              coupon: "",
              deliveryDate: date,
            },
          });

          if (billingResponse.data.status) {
            setBillingDetails(billingResponse.data.data);
          }
        }
      } catch (error) {
        handleError(error);
      }
    };

    // Function to update billing data when no slug is provided
    const updateBillingData = async (token, credentials) => {
      try {
        const billingResponse = await postRequest({
          url: "billing/get",
          token: authToken,
          cred: credentials,
        });

        if (billingResponse.data.status) {
          setBillingDetails(billingResponse.data.data);
        }
      } catch (error) {
        handleError(error);
      }
    };

    // Main effect logic
    if (token) {
      const credentials = { ...billData };

      if (slug) {
        fetchProductAndBillingDetails(slug, token, credentials);
      } else {
        updateBillingData(token, credentials);
      }
    }
  }, [cartDataStatus]);

  const handlecoupon = (e) => {
    setcouponData(e.target.value);
  };

  const applyCoupon = () => {
    if (couponData && apply) {
      getRequest({ url: `coupon/${couponData}`, token: authToken })
        .then((res) => {
          if (res?.data?.data) {
            toast.success(res?.data?.msg, {
              duration: 2000,
            });
            setBillData({
              ...billData,
              coupon: couponData,
            });
          }
          if (res.data.status) {
            // console.log(" res?.data ", apply);
            setApply(false);
          }
        })
        .catch((AxiosError) => {
          toast.error(AxiosError?.response?.data?.msg, {
            duration: 2000,
          });
        });
    }
  };
  const SavedMoney =
    (isNaN(billingDetails?.extraDiscount) ? 0 : billingDetails?.extraDiscount) +
    (isNaN(billingDetails?.couponDiscount)
      ? 0
      : billingDetails?.couponDiscount) +
    (isNaN(billingDetails?.discount) ? 0 : billingDetails?.discount);

  const textCut = {
    textDecoration: "line-through",
  };
  const deleteProduct = (id, quantity) => {
    const credentials = { product: id, quantity: -quantity };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        toast(res.data.msg);
        setCartDataStatus(!cartDataStatus);
      }
    });
  };
  const incQuantity = (id) => {
    const credentials = { product: id, quantity: 1 };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        toast.success(res.data.msg, {
          duration: 2000,
        });
        setCartDataStatus(!cartDataStatus);
      }
    });
  };
  const decQuantity = (id) => {
    const credentials = { product: id, quantity: -1 };
    request({
      url: "cart/update",
      method: "put",
      cred: credentials,
      token: authToken,
    }).then((res) => {
      if (res.data.status) {
        toast.success(res.data.msg, {
          duration: 2000,
        });
        setCartDataStatus(!cartDataStatus);
      }
    });
  };

  // ---------------------------------Address-----------------------------------------

  const [addressList, setAddressList] = useState([]);
  const [addUpdateStatus, setAddUpdateStatus] = useState(false);
  const [addAddressStatus, setAddAddressStatus] = useState(false);
  const [deleteAddressStatus, setDeleteAddressStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reqEdit, setReqEdit] = useState(false);
  const [editId, setEditId] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    name: "",
    type: "",
    mobile: "",
    alternateMobile: "",
    street: "",
    city: "",
    state: "",
    country: "INDIA",
    postalCode: "",
    landmark: "",
    isDefault: "true",
  });

  const [city, setCity] = useState();
  const [state, setState] = useState();

  const handleEdit = (id) => {
    setReqEdit(true);
    setEditId(id);
    getRequest({ url: `address/${id}`, token: authToken })
      .then((res) => {
        if (res.data.status) {
          setAddressDetails(res?.data?.data);
        } else {
          toast.error(res?.data?.msg, {
            duration: 2000,
          });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg, {
          duration: 2000,
        });
      });
  };
  const handleRadio = (id) => {
    getRequest({ url: `address/set-default-address/${id}`, token: authToken })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg, {
            duration: 2000,
          });
          setAddAddressStatus(!addAddressStatus);
          // setTimeout(() => { }, [2000]);
        } else {
          toast.error(res?.data?.msg, {
            duration: 2000,
          });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg, {
          duration: 2000,
        });
      });
  };
  const UpdateAddress = (id) => {
    const credentials = { ...addressDetails, addressId: id, defalut: "false" };
    request({
      url: "address/update",
      method: "put",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg, {
            duration: 2000,
          });
          setTimeout(() => {}, [2000]);
          setAddAddressStatus(!addAddressStatus);
          setAddressDetails({
            name: "",
            type: "",
            mobile: "",
            alternateMobile: "",
            street: "",
            city: "",
            state: "",
            country: "INDIA",
            postalCode: "",
            landmark: "",
            isDefault: "true",
          });
        } else {
          toast.error(res?.data?.msg, {
            duration: 2000,
          });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError.response.data.msg, {
          duration: 2000,
        });
      });
    setReqEdit(false);
    setEditId("");
    setAddUpdateStatus(!addUpdateStatus);
  };
  const deleteAddress = (id) => {
    deleteRequest({ url: `address/delete/${id}`, token: authToken })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg, {
            duration: 2000,
          });
          setDeleteAddressStatus(!deleteAddressStatus);
        } else {
          toast.error(res?.data?.msg, {
            duration: 2000,
          });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg, {
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    token &&
      getRequest({ url: "address", token: authToken })
        .then((res) => {
          if (res.data.status) {
            setAddressList(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
  }, [addAddressStatus]);

  useEffect(() => {
    token &&
      noTokenPostRequest({ url: "location/state" })
        .then((res) => {
          if (res.data.status) {
            setState(res.data.data);
          }
        })
        .catch((AxiosError) => {
          console.log(AxiosError);
        });
    const credentials = { state: addressDetails?.state };
    noTokenPostRequest({ url: "location/city", cred: credentials })
      .then((res) => {
        if (res.data.status) {
          setCity(res.data.data);
        }
      })
      .catch((AxiosError) => {
        console.log(AxiosError);
      });
  }, [addressDetails?.state, addUpdateStatus]);

  const cityList = city?.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  const stateList = state?.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile" || name === "alternateMobile") {
      // Ensure that only digits are allowed and limit to 10 digits
      const digitOnlyValue = value.replace(/\D/g, ""); // Remove non-digit characters

      if (digitOnlyValue?.length != 0 && digitOnlyValue.length < 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be exactly 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }

      setAddressDetails({
        ...addressDetails,
        [name]: digitOnlyValue.slice(0, 10), // Limit to 10 digits
      });
    } else if (name === "postalCode") {
      // Ensure that only digits are allowed and limit to 6 digits
      const digitOnlyValue = value.replace(/\D/g, ""); // Remove non-digit characters
      setAddressDetails({
        ...addressDetails,
        [name]: digitOnlyValue.slice(0, 6), // Limit to 6 digits
      });
    } else {
      setAddressDetails({
        ...addressDetails,
        [name]: value,
      });
    }
  };

  const handleType = (value) => {
    // console.log(value);
    setAddressDetails({
      ...addressDetails,
      type: value,
    });
  };
  const handleState = (value) => {
    // console.log(value);
    setAddressDetails({
      ...addressDetails,
      state: value,
      city: "",
    });
    setIsCityActive(true);
  };
  const handleCity = (value) => {
    // console.log(value);
    setAddressDetails({
      ...addressDetails,
      city: value,
    });
    setIsButtonActive(true);
  };

  const handleChangeShiipingMethod = (value) => {
    setBillData({
      ...billData,
      shipping: value,
    });
  };
  const handleChangePaymentMethod = (value) => {
    setBillData({
      ...billData,
      paymentMethod: value,
      product: ProductDetails?._id, // product id
    });
  };

  const defaultAddress = addressList?.filter(
    (elem) => elem?.isDefault === true
  );

  const payment = async () => {
    const credentials = { ...billData, address: defaultAddress[0]?._id };

    await request({
      url: "order/place",
      method: "post",
      cred: credentials,
      token: authToken,
    })
      .then((res) => {
        if (billData?.paymentMethod === "online") {
          if (res.data.status) {
            const data = res.data.data;
            // if (data && data.orderId) {
            setOrderDetails({
              orderId: data?.transactionId,
              currency: "INR",
              amount: data?.amountToPay,
            });
            setDisplayRazorpay(true);
            // }
          }
        } else if (res?.data?.status) {
          toast.success(res?.data?.msg, {
            duration: 2000,
          });
          setOrderStatus(!orderStatus);
          setTimeout(() => {
            navigate("/account/my-orders");
          }, 3000);
        } else {
          toast.error(res?.data?.msg, {
            duration: 2000,
          });
        }
      })
      .catch((AxiosError) => {
        toast.error(AxiosError?.response?.data?.msg);
      });
  };

  const AddAddress = (e) => {
    e.preventDefault();
    const credentials = { ...addressDetails, defalut: "true" };
    if (addressDetails?.mobile?.length < 10) {
      toast.error("Please enter valid mobile number 2");
    } else {
      request({
        url: "address/add",
        method: "post",
        cred: credentials,
        token: authToken,
      })
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.msg, {
              duration: 2000,
            });
            setAddAddressStatus(!addAddressStatus);
            setAddressDetails({
              name: "",
              type: "",
              mobile: "",
              alternateMobile: "",
              street: "",
              city: "",
              state: "",
              country: "INDIA",
              postalCode: "",
              landmark: "",
              isDefault: "true",
            });
            // setTimeout(() => {
            //   navigate("/account/my-address");
            // }, [2000]);
          } else {
            toast.error(res?.data?.msg, {
              duration: 2000,
            });
          }
        })
        .catch((AxiosError) => {
          toast.error(AxiosError.response.data.msg, {
            duration: 2000,
          });
        });
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <Header />
      <div className="bg-[#F2F2F2] md:p-5 font-[roboto]">
        {token && (
          <>
            <div className=" lg:w-4/5  m-auto mb-5 pt-5">
              <div className="bg-[#D8E7CC] p-2 rounded-lg shadow-md mx-5 overflow-hidden">
                <div className="px-7 sm:flex justify-between items-center  text-gray-800 ">
                  <div className="text-md md:text-xl font-bold ">
                    {profileDetails?.name}
                  </div>
                  <div className="flex items-center">
                    {profileDetails?.mobile && <CiMobile4 />}
                    {profileDetails?.mobile && (
                      <div className="ms-2 me-5 text-md">
                        {profileDetails?.mobile}
                      </div>
                    )}

                    <MdOutlineMailOutline />
                    <div className="ms-2 text-md"> {profileDetails?.email}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {billingDetails ? (
          <>
            {loading ? (
              <span className="loading loading-ring loading-lg"></span>
            ) : (
              <div className="grid grid-cols-1 xl:grid-cols-9 lg:w-4/5 m-auto">
                <div className="left xl:col-span-6">
                  <div className="">
                    <div className=" mx-5 px-5 py-2 bg-[#D8E7CC] rounded-t-md">
                      {/* main Heading  */}
                      <div className="">
                        <div className="text-md md:text-xl font-bold text-gray-800 uppercase ">
                          Order & Delivery details
                        </div>
                      </div>
                    </div>
                    <div className=" mx-5 mb-5 p-5 rounded-b-md  shadow-md bg-white">
                      {/* details section  */}
                      <div className="lg:mx-[5rem]">
                        <div className=" my-5">
                          <div className="my-3">
                            <div className="grid grid-cols-4 my-5">
                              {console.log("cartData ", cartData)}
                              {!slug &&
                                cartData?.map((item) => {
                                  return (
                                    <>
                                      <div className="col-span-3 border-b-2 mb-2">
                                        <div className="flex">
                                          <div className="my-1 me-3 w-28 overflow-hidden h-20 rounded-md">
                                            <img
                                              src={
                                                item?.variantId?.length > 0
                                                  ? item?.variantId?.[0]
                                                      ?.image?.[0]
                                                  : item?.product?.image?.[0]
                                              }
                                              alt="product"
                                              className={`h-auto ${
                                                (item?.variantId?.length > 0
                                                  ? item?.variantId?.[0]?.stock
                                                  : item?.product?.stock) < 1
                                                  ? "opacity-25"
                                                  : ""
                                              }  ${
                                                item?.product
                                                  ?.isValidDeliveryDate
                                                  ? "opacity-25"
                                                  : ""
                                              }`}
                                            />
                                          </div>
                                          {(item?.variantId?.length > 0
                                            ? item?.variantId?.[0]?.stock
                                            : item?.product?.stock) > 0 ? (
                                            <div className="col-span-3">
                                              <div className="my-1 text-lg font-semibold">
                                                {item?.product?.name}
                                              </div>
                                              <div className="my-1 mb-2 text-gray-500">
                                                Vendor:{" "}
                                                {item?.product?.vendor?.name}
                                              </div>
                                              <div className="my-1">
                                                <span
                                                  className="text-sm text-error "
                                                  style={textCut}
                                                >
                                                  ₹
                                                  {item?.variantId?.length > 0
                                                    ? item?.variantId?.[0]?.mrp
                                                    : item?.product?.mrp *
                                                      item?.quantity}
                                                  .00
                                                </span>{" "}
                                                <span className="text-success font-semibold">
                                                  ₹
                                                  {item?.variantId?.length > 0
                                                    ? item?.variantId?.[0]
                                                        ?.price
                                                    : item?.product?.price *
                                                      item?.quantity}
                                                </span>{" "}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <div className="my-1 text-lg font-semibold">
                                                Item Out Of Stock
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {item?.product?.stock > 1 && (
                                          <div className="my-3 flex w-28">
                                            <button
                                              className=" border rounded-md font-semibold px-3 py-1"
                                              disabled={item?.quantity < 2}
                                              onClick={() =>
                                                decQuantity(item?.product?._id)
                                              }
                                            >
                                              -
                                            </button>
                                            <span className=" border rounded-md font-semibold px-3 py-1 mx-1">
                                              {item?.quantity}
                                            </span>
                                            {
                                              <button
                                                className=" border rounded-md font-semibold px-3 py-1"
                                                // disabled={
                                                //   item?.product?.stock <=
                                                //   item?.quantity
                                                // }
                                                onClick={() => {
                                                  if (
                                                    item?.product?.stock <=
                                                    item?.quantity
                                                  ) {
                                                    // Show toast message
                                                    toast.error(
                                                      "Product out of stock",
                                                      {
                                                        duration: 2000,
                                                      }
                                                    );
                                                  } else {
                                                    // Call incQuantity function
                                                    incQuantity(
                                                      item?.product?._id
                                                    );
                                                  }
                                                }}
                                              >
                                                +
                                              </button>
                                            }
                                          </div>
                                        )}
                                      </div>
                                      {!item?.product?.isValidDeliveryDate ? (
                                        <div className="relative text-md font-semibold border-b-2 mb-2">
                                          Delivery Date : <br />{" "}
                                          {item?.deliveryDate &&
                                            item?.deliveryDate.slice(
                                              0,
                                              10
                                            )}{" "}
                                          <MdDelete
                                            className=" absolute bottom-[15px] right-[1px] text-3xl"
                                            onClick={() =>
                                              deleteProduct(
                                                item?.product?._id,
                                                item?.quantity
                                              )
                                            }
                                            role="button"
                                          />
                                        </div>
                                      ) : (
                                        <div className="relative text-md font-semibold border-b-2 mb-2">
                                          The delivery date is not valid. Please
                                          select again.
                                          <MdDelete
                                            className=" absolute bottom-[15px] right-[1px] text-3xl"
                                            onClick={() =>
                                              deleteProduct(
                                                item?.product?._id,
                                                item?.quantity
                                              )
                                            }
                                            role="button"
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              {slug && (
                                <>
                                  <div className="col-span-3 border-b-2 mb-2">
                                    <div className="flex">
                                      <div className="my-1 me-3 w-28 overflow-hidden h-20 rounded-md">
                                        <img
                                          src={
                                            ProductDetails?.isVariant
                                              ? ProductDetails?.cakeVariant?.[0]
                                                  ?.image?.[0]
                                              : ProductDetails?.image?.[0]
                                          }
                                          alt="product"
                                          className={`h-auto ${
                                            (ProductDetails?.isVariant
                                              ? ProductDetails?.cakeVariant?.[0]
                                                  ?.stock
                                              : ProductDetails?.stock) < 1
                                              ? "opacity-50"
                                              : ""
                                          }`}
                                        />
                                      </div>
                                      <div className="col-span-3">
                                        <div className="my-1 text-lg font-semibold">
                                          {(ProductDetails?.isVariant
                                            ? ProductDetails?.cakeVariant?.[0]
                                                ?.stock
                                            : ProductDetails?.stock) < 1
                                            ? "Item Out of Stock"
                                            : ProductDetails?.isVariant
                                            ? ProductDetails?.cakeVariant?.[0]
                                                ?.name
                                            : ProductDetails?.name}{" "}
                                        </div>
                                        <div className="my-1 mb-2 text-gray-500">
                                          Vendor:{" "}
                                          {ProductDetails?.isVariant
                                            ? ProductDetails?.cakeVariant?.[0]
                                                ?.vendor?.name
                                            : ProductDetails?.vendor?.name}
                                        </div>
                                        <div className="my-1">
                                          {(ProductDetails?.isVariant
                                            ? ProductDetails?.cakeVariant?.[0]
                                                ?.price
                                            : ProductDetails?.price) <
                                            (ProductDetails?.isVariant
                                              ? ProductDetails?.cakeVariant?.[0]
                                                  ?.mrp
                                              : ProductDetails?.mrp) && (
                                            <span
                                              className="text-sm text-error"
                                              style={textCut}
                                            >
                                              ₹{" "}
                                              {ProductDetails?.isVariant
                                                ? ProductDetails
                                                    ?.cakeVariant?.[0]?.mrp
                                                : ProductDetails?.mrp}
                                              .00
                                            </span>
                                          )}{" "}
                                          <span className="text-success font-semibold">
                                            ₹
                                            {ProductDetails?.isVariant
                                              ? ProductDetails?.cakeVariant?.[0]
                                                  ?.price
                                              : ProductDetails?.price}
                                          </span>{" "}
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="my-3 flex w-28">
                                      <button
                                        className=" border rounded-md font-semibold px-3 py-1"
                                        disabled={billingDetails?.quantity < 2}
                                        onClick={() =>
                                          decQuantity(billingDetails?._id)
                                        }
                                      >
                                        -
                                      </button>
                                      <span className=" border rounded-md font-semibold px-3 py-1 mx-1">
                                        {billingDetails?.quantity}
                                      </span>
                                      <button
                                        className=" border rounded-md font-semibold px-3 py-1"
                                        onClick={() =>
                                          incQuantity(billingDetails?._id)
                                        }
                                      >
                                        +
                                      </button>
                                    </div> */}
                                  </div>

                                  {billingDetails?.deliveryDate ? (
                                    <div className="relative text-md font-semibold border-b-2 mb-2">
                                      Delivery Date :{" "}
                                      {billingDetails?.deliveryDate &&
                                        billingDetails?.deliveryDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                      <MdDelete
                                        className=" absolute bottom-[15px] right-[1px] text-3xl"
                                        onClick={() =>
                                          deleteProduct(
                                            billingDetails?._id,
                                            billingDetails?.quantity
                                          )
                                        }
                                        role="button"
                                      />
                                    </div>
                                  ) : (
                                    <div className="text-md font-semibold">
                                      Delivery Date : {date}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className=" mx-5 px-5 py-2 bg-[#D8E7CC] rounded-t-md">
                      {/* main Heading  */}
                      <div className="">
                        <div className="text-md md:text-xl font-bold text-gray-800 uppercase ">
                          Delivery Address
                        </div>
                      </div>
                    </div>

                    <div className=" mx-5 mb-5 py-5 rounded-b-md  shadow-md bg-white">
                      {/* address map  */}
                      <div className="my-3">
                        {addressList?.map((data, index) => {
                          return (
                            <>
                              <div
                                className={
                                  data?.isDefault
                                    ? "p-5 bg-gray-50"
                                    : "p-5 my-3"
                                }
                              >
                                <div
                                  className={
                                    reqEdit
                                      ? editId === data?._id
                                        ? "hidden"
                                        : "grid grid-cols-6"
                                      : "grid grid-cols-6"
                                  }
                                >
                                  <div className="col-span-4 gap-4">
                                    <div className="md:flex items-center">
                                      <input
                                        type="radio"
                                        checked={data?.isDefault}
                                        role="button"
                                        onChange={() => handleRadio(data?._id)}
                                      />
                                      <span className="mx-3 font-semibold capitalize">
                                        {data?.name}
                                      </span>
                                      <span className=" btn btn-sm bg-[#ecf5e4] text-[#042100] hover:bg-[#ccd6c4] border-0">
                                        {data?.type}
                                      </span>
                                      <span className="mx-3 font-semibold">
                                        {data?.mobile}
                                      </span>
                                    </div>
                                    <div className="md:flex items-center my-2">
                                      <span className="text-md text-gray-600 font-medium">
                                        {data?.street},
                                      </span>
                                      <span className="text-md text-gray-600 font-medium">
                                        {data?.city?.name},
                                      </span>
                                      <span className="text-md text-gray-600 font-medium">
                                        {data?.state?.name}-
                                      </span>

                                      <span className="text-md font-semibold">
                                        {data?.postalCode}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-span-2">
                                    <div
                                      className="text-blue-500 font-semibold float-end"
                                      role="button"
                                      onClick={() => handleEdit(data?._id)}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    reqEdit
                                      ? editId === data?._id
                                        ? " grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5"
                                        : "hidden"
                                      : "hidden"
                                  }
                                >
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="name"
                                        value={addressDetails?.name}
                                        id="name"
                                        onChange={handleChange}
                                        className=" w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Address Type
                                    </label>
                                    <div className="mt-2">
                                      <Select
                                        defaultValue={"Select"}
                                        onChange={handleType}
                                        value={addressDetails?.type}
                                        required
                                        className="block w-full rounded-md"
                                        options={[
                                          {
                                            value: "Home",
                                            label: "Home",
                                          },
                                          {
                                            value: "Office",
                                            label: "Office",
                                          },
                                          {
                                            value: "Other",
                                            label: "Other",
                                          },
                                        ]}
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Phone Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="number"
                                        name="mobile"
                                        value={addressDetails?.mobile}
                                        pattern="\d{10}" // Regular expression for 10 digits
                                        maxLength="10" // Limits the input to 10 characters
                                        id="mobile"
                                        autoComplete="mobile-number"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Alternate Phone Number
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="number"
                                        name="alternateMobile"
                                        value={addressDetails?.alternateMobile}
                                        id="alternatemobile"
                                        pattern="\d{10}" // Regular expression for 10 digits
                                        maxLength="10" // Limits the input to 10 characters
                                        autoComplete="mobile-number"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-white placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Street
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="street"
                                        value={addressDetails?.street}
                                        id="street"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Landmark
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="landmark"
                                        value={addressDetails?.landmark}
                                        id="landmark"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Pincode
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="number"
                                        name="postalCode"
                                        value={addressDetails?.postalCode}
                                        id="postalCode"
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="countries"
                                      className="block mb-2 text-sm font-medium "
                                    >
                                      State
                                    </label>

                                    <Select
                                      defaultValue={"Select State"}
                                      style={{
                                        width: 150,
                                      }}
                                      onChange={handleState}
                                      value={addressDetails?.state}
                                      options={stateList}
                                    />
                                  </div>

                                  <div className=" sm:col-span-2">
                                    <label
                                      htmlFor="countries"
                                      className="block mb-2 text-sm font-medium "
                                    >
                                      City
                                    </label>
                                    <Select
                                      defaultValue={"Select City"}
                                      // style={{
                                      //   width: 150,
                                      // }}
                                      value={addressDetails?.city}
                                      onChange={handleCity}
                                      options={cityList}
                                      className="w-full"
                                    />
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="last-name"
                                      className="block mb-2 text-sm font-medium "
                                    >
                                      Country
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="country"
                                        value={addressDetails?.country.toUpperCase()}
                                        id="country"
                                        className="block w-full bg-white rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-6 text-right ">
                                    <button
                                      onClick={() => {
                                        setReqEdit(false);
                                        setEditId("");
                                        setAddressDetails({
                                          name: "",
                                          type: "",
                                          mobile: "",
                                          alternateMobile: "",
                                          street: "",
                                          city: "",
                                          state: "",
                                          country: "INDIA",
                                          postalCode: "",
                                          landmark: "",
                                          isDefault: "true",
                                        });
                                      }}
                                      className="mx-3 btn"
                                    >
                                      Cancle
                                    </button>
                                    <button
                                      onClick={() => UpdateAddress(data?._id)}
                                      className="btn bg-[#D8E7CC] border-0 text-[#042100] hover:text-gray-400"
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {/* Add Address  */}
                      {!reqEdit && (
                        <div className=" px-3">
                          <Collapse
                            items={[
                              {
                                key: "1",
                                label: "Add Address",
                                children: (
                                  <form
                                    onSubmit={AddAddress}
                                    className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-5"
                                  >
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Name
                                      </label>
                                      <div className="mt-2 bg-white">
                                        <input
                                          type="text"
                                          name="name"
                                          value={addressDetails?.name}
                                          id="name"
                                          onChange={handleChange}
                                          className="h-12 w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset bg-white ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Address Type
                                      </label>
                                      <div className="mt-2">
                                        <Select
                                          defaultValue={
                                            addressDetails?.type
                                              ? addressDetails?.type
                                              : "Select"
                                          }
                                          onChange={handleType}
                                          required
                                          className="block w-full rounded-md h-12"
                                          options={[
                                            { value: "Home", label: "Home" },
                                            {
                                              value: "Office",
                                              label: "Office",
                                            },
                                            { value: "Other", label: "Other" },
                                          ]}
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Phone Number
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="mobile"
                                          value={addressDetails?.mobile}
                                          id="mobile"
                                          pattern="\d{10}"
                                          maxLength="10"
                                          autoComplete="mobile-number"
                                          onChange={handleChange}
                                          className="h-12 block w-full bg-white rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                          required
                                        />
                                        {errors.mobile && (
                                          <p className="text-red-500 text-xs mt-1">
                                            {errors.mobile}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Alternate Phone Number
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="alternateMobile"
                                          value={
                                            addressDetails?.alternateMobile
                                          }
                                          id="alternatemobile"
                                          pattern="\d{10}"
                                          maxLength="10"
                                          autoComplete="mobile-number"
                                          onChange={handleChange}
                                          className="h-12 block w-full bg-white rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                        />
                                        {/* {errors.mobile && (
                                          <p className="text-red-500 text-xs mt-1">
                                            {errors.mobile}
                                          </p>
                                        )} */}
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Street
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="street"
                                          value={addressDetails?.street}
                                          id="street"
                                          onChange={handleChange}
                                          className="h-12 block w-full bg-white rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Landmark
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="landmark"
                                          value={addressDetails?.landmark}
                                          id="landmark"
                                          onChange={handleChange}
                                          className="h-12 block w-full bg-white rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="postalCode"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Pincode
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="postalCode"
                                          value={addressDetails?.postalCode}
                                          id="postalCode"
                                          onChange={handleChange}
                                          pattern="\d{6}" // Regex pattern for exactly 6 digits
                                          maxLength="6" // Limits input to 6 characters
                                          className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6 bg-white h-[3rem]"
                                          required
                                          title="Please enter exactly 6 digits."
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="countries"
                                        className="block mb-2 text-sm font-medium"
                                      >
                                        State
                                      </label>
                                      <Select
                                        defaultValue="Select State"
                                        style={{ width: 150 }}
                                        onChange={handleState}
                                        options={stateList}
                                        className="h-12"
                                      />
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="countries"
                                        className="block mb-2 text-sm font-medium"
                                      >
                                        City
                                      </label>
                                      <Select
                                        defaultValue="Select City"
                                        style={{ width: 150 }}
                                        onChange={handleCity}
                                        value={addressDetails?.city}
                                        options={cityList}
                                        disabled={!isCityActive}
                                        className="h-12"
                                      />
                                    </div>

                                    <div className="sm:col-span-2">
                                      <label
                                        htmlFor="last-name"
                                        className="block mb-2 text-sm font-medium"
                                      >
                                        Country
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="country"
                                          value={addressDetails?.country.toUpperCase()}
                                          id="country"
                                          className="h-12 block w-full bg-white rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-lime-500 focus:outline sm:text-sm sm:leading-6"
                                          readOnly
                                        />
                                      </div>
                                    </div>

                                    <div className="sm:col-span-6 text-right">
                                      <button
                                        type="submit"
                                        disabled={!isButtonActive}
                                        className="btn bg-[#D8E7CC] px-8 border-0 text-[#042100] hover:text-white font-[700]"
                                      >
                                        Add
                                      </button>
                                      <p className="text-xs text-gray-600 mt-2">
                                        <strong>note: </strong> Please fill in
                                        all the fields.
                                      </p>
                                    </div>
                                  </form>
                                ),
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right xl:col-span-3">
                  <div className="mx-5 px-5 py-2 bg-[#D8E7CC] rounded-t-md  ">
                    {/* main Heading  */}
                    <div className="">
                      <div className="text-md md:text-xl font-bold text-gray-800 uppercase">
                        price & method details
                      </div>
                    </div>
                  </div>
                  <div className="mx-5 mb-5 p-5 rounded-b-md  shadow-md bg-white ">
                    {/* details section  */}
                    <div className="">
                      {/* payment methods  */}

                      {/* <div className="my-3">
                        <div className="text-sm md:text-md font-bold text-gray-700 uppercase">
                          Shipping method
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className="">
                            <input
                              type="radio"
                              className="mx-3"
                              checked={
                                billData?.shipping === "free" ? true : false
                              }
                              onClick={() => handleChangeShiipingMethod("free")}
                            />
                            <span className=" text-sm">
                              Free Shipping (3-4 days)
                            </span>
                          </div>
                          <div className=" text-sm">Free</div>
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className="">
                            <input
                              type="radio"
                              className="mx-3"
                              onClick={() =>
                                handleChangeShiipingMethod("standard")
                              }
                              checked={
                                billData?.shipping === "standard" ? true : false
                              }
                            />
                            <span className=" text-sm">Standard</span>
                          </div>
                          <div className=" text-sm">
                            ₹ {billingDetails?.shipping}
                          </div>
                        </div>
                      </div>
                      <hr /> */}
                      <div className="my-3">
                        <div className="text-sm md:text-md font-bold text-gray-700 uppercase">
                          Payment method
                        </div>
                        <div className=" text-gray-500 my-2">
                          <div className="">
                            <input
                              type="radio"
                              className="mx-3"
                              checked={
                                billData?.paymentMethod === "cod" ? true : false
                              }
                              onClick={() => handleChangePaymentMethod("cod")}
                            />
                            <span className=" text-sm">
                              Cash On Delivery (COD)
                            </span>
                          </div>
                        </div>
                        <div className=" text-gray-500 my-2">
                          <div className="">
                            <input
                              type="radio"
                              className="mx-3"
                              checked={
                                billData?.paymentMethod === "online"
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                handleChangePaymentMethod("online")
                              }
                            />
                            <span className=" text-sm">
                              RazorPay Secure (UPI, Cards, Wallets etc)
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="grid grid-cols-1 my-3">
                        <div className="text-sm md:text-md font-bold text-gray-700 uppercase">
                          Order Summary
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">MRP</div>
                          <div className=" text-sm">
                            ₹{billingDetails?.mrp}.00
                          </div>
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">Price</div>
                          <div className=" text-sm">
                            ₹{billingDetails?.price}.00
                          </div>
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">Discount</div>
                          <div className=" text-sm">
                            ₹
                            {billingDetails?.discount > 1
                              ? billingDetails?.discount
                              : 0}
                            .00
                          </div>
                        </div>
                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">Delivery Charge</div>
                          <div className=" text-sm">
                            ₹{billingDetails?.shipping}.00
                          </div>
                        </div>

                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">Coupon Discount</div>
                          <div className=" text-sm">
                            ₹
                            {billingDetails?.couponDiscount > 1
                              ? billingDetails?.couponDiscount
                              : 0}
                            .00
                          </div>
                        </div>

                        <div className="flex justify-between text-gray-500 my-2">
                          <div className=" text-sm">Extra Discount</div>
                          <div className=" text-sm">
                            ₹
                            {billingDetails?.extraDiscount > 1
                              ? billingDetails?.extraDiscount
                              : 0}
                            .00
                          </div>
                        </div>

                        <div className="flex justify-between text-gray-900 mt-2 font-bold border-y-2 py-2 border-dashed ">
                          <div className="text-sm md:text-md">TOTAL</div>
                          <div className="text-sm md:text-md">
                            ₹{billingDetails?.total}.00
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          SavedMoney > 0
                            ? " text-sm text-end  text-green-600 font-semibold mt-2"
                            : "hidden"
                        }
                      >
                        You will save ₹{SavedMoney} on this order.
                      </div>
                      {/* <hr /> */}
                      <div
                        className={
                          showcoupon
                            ? "hidden"
                            : "text-center capitalize text-blue-600 font-semibold my-5"
                        }
                        role="button"
                        onClick={() => {
                          setShowcoupon(true);
                          setApply(true);
                        }}
                      >
                        Have A Discount coupon?
                      </div>

                      <div className={showcoupon ? "flex my-3" : "hidden"}>
                        <input
                          type="text"
                          placeholder="Coupon Code"
                          name="coupon"
                          className="input bg-white input-bordered w-full max-w-xs focus:outline-none"
                          onChange={handlecoupon}
                        />
                        &nbsp;
                        <div
                          className={`btn bg-[#D8E7CC] border-0 text-[#042100] hover:text-gray-400${
                            !apply ? "btn-disabled" : ""
                          }`}
                          onClick={applyCoupon}
                        >
                          Apply
                        </div>
                      </div>
                      {/* <br /> */}
                      <div
                        className={`text-xs font-semibold text-blue-500 mb-2 ${
                          !apply ? "block" : "hidden"
                        }`}
                        role="button"
                        onClick={() => {
                          setApply(true);
                        }}
                      >
                        Re-Apply?
                      </div>

                      <hr />
                      <div className="my-4 text-sm font-semibold text-center text-gray-500">
                        By continuing you are agree to our{" "}
                        <span className="text-blue-500" role="button">
                          T&C/Disclaimer
                        </span>
                      </div>
                      <button
                        onClick={payment}
                        className=" btn bg-[#D8E7CC] w-full text-md md:text-lg rounded-md shadow-xl font-bold hover:bg-[#c9ddb8] border-0 text-[#042100] hover:shadow-xl"
                      >
                        {billData?.paymentMethod === "online"
                          ? "PROCEED TO PAY"
                          : "PROCEED"}
                      </button>
                    </div>
                  </div>
                  <div className="mx-5 px-6">
                    <div className="text-gray-700 font-bold uppercase">
                      gw Secure
                    </div>
                    <div className="text-gray-600 text-sm font-bold my-2 ">
                      100% Safe and Secure Payments
                    </div>
                    <div className="text-gray-600 text-sm font-bold my-2 ">
                      Millions of People Trusted Us
                    </div>
                    <hr className="border border-gray-300" />
                  </div>
                </div>
                {/* <div className="left xl:col-span-6">
                
              </div> */}
              </div>
            )}
          </>
        ) : (
          // empty cart

          <>
            <div className=" px-5 m-auto lg:w-4/5 pb-5">
              <div className="bg-white  rounded-lg shadow-lg p-5">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[10vh] w-[10vw] m-auto my-[2rem] lg:my-[5rem] text-gray-400"
                    fill="none"
                    viewBox="0 0 22 22"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <div className="text-xl lg:text-3xl text-center font-semibold">
                  Your Cart is Empty, filled with treasures of your choice
                </div>
                <div className="flex justify-evenly my-7">
                  <div
                    className="text-bold btn lg:btn-lg bg-[#D8E7CC] hover:bg-[#c9ddb8] border-0 text-[#042100] hover:shadow-xl "
                    onClick={(e) => navigate("/")}
                    role="button"
                  >
                    Continue Shopping
                  </div>
                  {/* <div
                  className="text-bold text-blue-600 btn "
                  onClick={(e) => navigate("/")}
                  role="button"
                >
                  Go Back to Home Page
                </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* --------------------------------------------------------razorpay------------------------------------------------ */}
      {/* {console.log("orderdetails", orderDetails, displayRazorpay)} */}
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
          keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET}
        />
      )}

      <Footer />
    </>
  );
};

export default CheckOutIndex;
