import React from "react";
import Header from "../../components/header";
import { Footer } from "../../pages/Footer";

export const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 ">
        <p className="text-center text-4xl pt-24 pb-8 font-bold underline underline-offset-8 decoration-lime-500">
          About Us
        </p>
        <div className="flex items-center justify-center"></div>
      </div>
      <Footer />
    </>
  );
};
