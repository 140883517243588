import React from 'react'
import Header from '../../pages/Header'
import { Footer } from '../../pages/Footer'

const Terms = () => {
    return (
        <>
            <Header className="" />
            <div className='bg-gray-100 '>
                <p className='text-center text-4xl pt-32 pb-8 font-bold underline underline-offset-8 decoration-lime-500'>Terms and Policy</p>
                <div className='flex items-center justify-center'>
                    <div className='w-10/12 '>
                        <p className='text-2xl font-bold'>General Terms</p>

                        <div className='my-5'>Welcome to Gamlawala Business Solutions, a suite of optional merchant services including Selling on Gamlawala, Fulfilment by Gamlawala.</div>

                        <div className='my-5'>THIS AGREEMENT CONTAINS THE TERMS AND CONDITIONS THAT GOVERN YOUR ACCESS TO AND USE OF THE SERVICES THROUGH A PARTICULAR ACCOUNT OR ACCOUNTS AND IS AN AGREEMENT BETWEEN YOU OR THE BUSINESS YOU REPRESENT ("YOU") AND AMAZON SELLER SERVICES PRIVATE LIMITED. BY REGISTERING FOR OR USING THE SERVICES, YOU (ON BEHALF OF YOURSELF OR THE BUSINESS YOU REPRESENT) AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, INCLUDING THE SERVICE TERMS AND PROGRAM POLICIES FOR EACH SERVICE YOU REGISTER FOR OR USE IN CONNECTION WITH THE AMAZON SITE.</div>

                        <div className='my-5'>As used in this Agreement, "we," "us," and "Gamlawala" means the Gamlawala company named in the applicable Service Terms. Capitalized terms have the meanings listed in the Definitions below. If there is any conflict between these General Terms and the applicable Service Terms and Program Policies, the General Terms will govern and the applicable Service Terms will prevail over the Program Policies.</div>

                        <ol>
                            <li className='font-bold'>1. Enrollment</li>
                            <div className='my-5'>To begin the enrolment process, you must complete the registration process for one or more of the Services. Use of the Services is limited to parties that can lawfully enter into and form contracts under applicable Law. As part of the application, you must provide us with your (or your business') legal name, address, phone number, e-mail address, applicable tax registration details as well as any other information we may request. </div>

                            <li className='font-bold'>2. Service Fee Payments</li>
                            <div className='my-5'>Fee details are described fully in the applicable Service Terms and Program Policies. You are responsible for all of your expenses in connection with this Agreement, unless this Agreement or the applicable Service Terms provide otherwise. For the Amazon Site that you register for or use a Service in connection with, we may require you to submit valid credit card information from a credit card acceptable by Amazon (with respect to such Amazon Site, "Your Credit Card") as well as valid bank account information for a bank account in your name that is with a bank located within India and enabled for Your Account (which functionality may be modified or discontinued by us at any time without notice) (with respect to the Amazon Site, "Your Bank Account"). You will use only a name you are authorized to use in connection with the Service and will update such information as necessary to ensure that it at all times remains accurate and complete. You authorize us to verify your information (including any updated information), to obtain credit reports about you from time to time, to obtain credit authorizations from the issuer of Your Credit Card, and to charge Your Credit Card or debit Your Bank Account for any sums payable by you to us (in reimbursement or otherwise). At Amazon's option, all payments to you will be made to Your Bank Account, via cheque or electronic transfers or other means as specified by us. You agree that Amazon shall not be liable for any failure to make payments to you on account of incomplete or inaccurate information provided by you with respect to Your Bank Account.

                            <div className='my-5'>In addition to charging payable sums to Your Credit Card, we may instead choose to either (a) offset any amounts that are payable by you to us (in reimbursement or otherwise) against any payments we may make to you, or (b) invoice you for amounts due to us, in which case you will pay the invoiced amounts upon receipt. Except as provided otherwise, all amounts contemplated in this Agreement will be expressed and displayed in the Local Currency, and all payments contemplated by this Agreement will be made in the Local Currency. If we discover erroneous or duplicate transactions, then we reserve the right to seek reimbursement from you by deducting from future payments owed to you, charging Your Credit Card, or seeking such reimbursement from you by any other lawful means; provided that the foregoing will not limit your rights to pursue any good faith dispute with Amazon concerning whether any amounts are payable or due.</div>

                            <div className='my-5'>If we reasonably conclude based on information available to us that your actions and/or performance in connection with the Agreement may result in a significant number of customer disputes, chargebacks or other claims in connection with the Amazon Site, then we may, in our sole discretion and subject to applicable Law, delay initiating any payments to be made or that are otherwise due to you under this Agreement for the shorter of: (a) a period of ninety (90) calendar days following the initial date of suspension; or (b) completion of any investigation(s) regarding your actions and/or performance in connection with the Agreement. You agree that we are entitled to the interest, if any, paid on balances maintained as deposits in our bank accounts.</div></div>

                            <li className='font-bold'>3. Term and Termination</li>
                            <div className='my-5'>The term of this Agreement will start on the date of your completed registration for use of one or more of the Services and continue until terminated by us or you as provided below. You may at any time terminate your use of any Service immediately on notice to us via Seller Central, email, the Contact Us Form, or similar means. We may terminate your use of any Services or terminate this Agreement for convenience with 30 days’ advance notice. We may suspend or terminate your use of any Services immediately if we determine that (a) you have materially breached the Agreement and failed to cure within 7 days of a cure notice unless your breach exposes us to liability towards a third party, in which case we are entitled to reduce, or waive, the aforementioned cure period at our reasonable discretion; (b) your account has been, or our controls identify that it may be used for deceptive or fraudulent or illegal activity; or (c) your use of the Services has harmed or our controls identify that it might harm other sellers, customers, or Amazon’s legitimate interest. We will promptly notify you of any such termination or suspension via email or similar means including Seller Central, indicating the reason and any options to appeal, except where we have reason to believe that providing this information will hinder the investigation or prevention of deceptive, fraudulent, or illegal activity, or will enable you to circumvent our safeguards. On termination of this Agreement, all related rights and obligations under this Agreement immediately terminate, except that (d) you will remain responsible for performing all of your obligations in connection with transactions entered into before termination and for any liabilities that accrued before or as a result of termination, and (e) Sections 2, 3, 4, 5, 6, 7, 8, 9, 11, 14, 15 and 17 of these General Terms survive.</div>

                            <li className='font-bold'>4. Licence</li>
                            <div className='my-5'>You grant us a royalty-free, non-exclusive, worldwide right and licence for the duration of your original and derivative intellectual property rights during the Term and for as long thereafter as you are permitted to grant the said licence under applicable Law to use any and all of Your Materials for the Services or other Amazon product or service, and to sublicense the foregoing rights to our Affiliates and operators of Amazon Associated Properties; provided, however, that we will not alter any of Your Trademarks from the form provided by you (except to re-size trademarks to the extent necessary for presentation, so long as the relative proportions of such trademarks remain the same) and will comply with your removal requests as to specific uses of Your Materials (provided you are unable to do so using the standard functionality made available to you via the applicable Amazon Site or Services); provided further, however, that nothing in this Agreement will prevent or impair our right to use Your Materials without your consent to the extent that such use is allowable without a licence from you or your Affiliates under applicable Law (e.g., fair use under copyright law, referential use under trademark law, or valid licence from a third party).</div>

                            <li className='font-bold'>5. Representations </li>
                            <div className='my-5'>Each Party represents and warrantsthat: (a) if it is a business, it is duly organized, validly existing and in good standing under the Laws of the territory in which your business is registered and are a resident of India for income tax purposes every financial year; (b) it has all requisite right, power and authority to enter into this Agreement and perform its obligations and grant the rights, licences and authorizations it grants hereunder; (c) it will comply with all applicable Laws (including but not limited to procuring and maintaining applicable tax registrations) in its performance of its obligations and exercise of its rights under this Agreement; and (d) each party is not subject to sanctions or otherwise designated on any list of prohibited or restricted parties or owned or controlled by such a party, including but not limited to the lists maintained by the United Nations Security Council, the US Government (e.g., the US Department of Treasury's Specially Designated Nationals list and Foreign Sanctions Evaders list and the US Department of Commerce’s Entity List), the European Union or its member states, or other applicable government authority.</div>

                            <li className='font-bold'>6. Indemnification</li>
                            <div className='my-5'>6.1 You release us from, and agree to indemnify, defend and hold harmless us (and our officers, directors, employees, agents and Affiliates) against, any third party claim, loss, damage, settlement, cost, taxes, expense or other liability (including, without limitation, attorneys' fees) (each, a "Claim") arising from or related to: (a) your actual or alleged breach of any representations you have made; (b) any sales channels owned or operated by you, Your Products including the offer, sale, fulfilment (except to the extent attributable to the Fulfilment by Amazon Service, if any), refund, cancellation, adjustments, or return thereof), Your Materials, any actual or alleged infringement of any Intellectual Property Rights by any of the foregoing, and any personal injury, death (to the extent the injury or death is not caused by Amazon) or property damage related thereto; or (c) Your Taxes and duties or the collection, payment, or failure to collect or pay Your Taxes or duties, or the failure to meet tax registration obligations or duties; or (d) your non-compliance with applicable laws.</div>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Terms
